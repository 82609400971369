import RFQEmail from "../RFQ/RFQEmail";
import MaterialDetails from "../Materials/MaterialDetails";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import industryData from "../DataContent/industryData";
import CustomerDetailsQueue from "../Customer/CustomerDetailsQueue";


const DelegateFirst: React.FC = () => {
    const navigate = useNavigate();
    const [emailData, setEmailData] = useState<any[]>([]);

    // Use useParams to get the id and rfq from the URL
    const {id, rfq} = useParams<{ id: string; rfq: string }>();

    useEffect(() => {
        // Find the industry data matching the specific id
        const industry: any = industryData.find((item: any) => item.id === id);

        // Use the `any` type to filter emails based on the RFQ
        const filteredEmails = industry ? industry.emails.filter((email: any) => email.rfq === rfq) : [];

        // Set the filtered emails in the state
        setEmailData(filteredEmails);
    }, [id, rfq]);

    // Extract the tableData from the filteredEmails for the MaterialDetails
    const tableData = emailData.map((email: any) => email.tableData).flat(); // Flatten if there are multiple emails


    const handleBackClick = () => {
        navigate(`/industry/${id}/delegate`);
    };

    const handleDelegate = () => {
        // Assuming 'selectedEmail' is available and contains the 'rfq' value
        if (rfq) {
            // Navigate to the delegate page with the RFQ value
            navigate(`/industry/${id}/delegate/${rfq}/decisions`);
        } else {
            console.error('RFQ not found');
        }
    };


    return (
        <div className={`container mx-auto px-4 `}>
            <div className={`lg:col-span-3 w-full h-full flex flex-col items-center gap-4 overflow-y-auto px-1 `}>
                <div className="flex flex-row items-center gap-2 w-full">
                    {emailData.length > 0 && (
                        <div className="mt-1 mb-1 w-full pl-2 font-bold flex-[9]">
                            1. Is All Information correct?
                        </div>
                    )}
                    <button
                        className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                        onClick={handleBackClick}
                    >
                        Back
                    </button>
                    <div className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>

                        <button
                            className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                            onClick={handleDelegate}
                        >
                            Delegate
                        </button>
                    </div>
                </div>

                <div className="flex-full flex flex-col justify-start items-center w-full">
                    <div className={`w-full flex flex-col justify-center items-center`}>
                        <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 w-full`}>
                            <div className="bg-white p-4 shadow-md rounded-lg flex flex-col space-y-4">
                                <div className="flex flex-col h-auto w-full ">
                                    <div className="flex flex-col h-[30vh] gap-2 overflow-y-auto border border-gray-200 rounded-md w-full"> {/* Set height and enable overflow */}
                                        <RFQEmail emails={emailData} />
                                    </div>
                                </div>
                                <div className={`w-full flex flex-col justify-start items-center`}>
                                    <CustomerDetailsQueue customerData={emailData}/>
                                </div>
                            </div>
                            <div className={`bg-white p-4 shadow-md rounded-lg`}>
                                <div className="flex h-full flex-col w-full gap-2">
                                    <MaterialDetails materials={tableData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}
export default DelegateFirst;
