import React, { useState, ChangeEvent } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Box,
    IconButton,
    Paper, Switch,
} from '@mui/material';
import EditableTableCell from "../Tables/EditableTableCell";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import '../Stylesheets/EditableTable.css';

interface DecisionsTableProps {
    material: any;
}

type TabStatus = 'InStock' | 'PartialStock' | 'OutOfStock' | 'InStockAlternativeLocations';

const tabColors: Record<TabStatus, string> = {
    InStock: 'bg-[#A3E59E]',
    PartialStock: 'bg-[#FAD467]',
    OutOfStock: 'bg-[#F18C83]',
    InStockAlternativeLocations: 'bg-[#E59ED1]',
};


const DecisionsTable: React.FC<DecisionsTableProps> = ({ material }) => {
    const [isEditing, setIsEditing] = useState(false);
    console.log(material);
    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => setIsEditing(false);
    const handleSave = () => setIsEditing(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        // Handle input changes
    };

    const handleCuttingStatusChange = (event: { target: { checked: any; }; }) => {
        const newStatus = event.target.checked; // Get the new checked status from the switch
        // Update the statusCutting in the material state (assuming material is in state)

    };



    // Determine background color based on material status and selection
    const backgroundColor = tabColors[material.stockStatus as TabStatus]; // Use the stockStatus from the material

    return (
        <div
            className={`w-full p-2 h-full flex-col justify-start inline-flex overflow-y-auto rounded-md ${backgroundColor}`}
        >
            {/* Header Row Above the Table */}
            <div className="flex justify-between items-center w-[90%] mx-auto"> {/* Center the entire header section */}
                <p className="font-francois text-sm min-w-5">
                    {material.data.find((item: { label: string; }) => item.label === 'Material Type')?.value ||
                        material.data.find((item: { label: string; }) => item.label === 'Component Type')?.value}
                </p>
                <div className="flex items-center">
                    <button onClick={isEditing ? handleCancel : handleEdit} className="text-[#264B6A]">
                        {isEditing ? (
                            <CloseIcon />
                        ) : (
                            <EditIcon />
                        )}
                    </button>
                    {isEditing && (
                        <button onClick={handleSave} className="text-blue-500 ml-2">
                            <CheckIcon />
                        </button>
                    )}
                </div>
            </div>
            {/* Conditional Rendering of Cutting Status Table */}
            {material.cutting && material.cutting[0]?.statusCutting && (
                <>

                    {/* Table for Overallyield, Total Cutting Fees, and Falcon Optimization Details */}
                    <div className="flex justify-center">
                        <table className="w-[95%] divide-y divide-gray-200 bg-white">
                            <tbody>
                            {/* Row for Overallyield */}
                            <tr>
                                <td className="px-1 py-2 w-[50%] border border-gray-300">
                                    <p className="text-sm font-francois">Cut On/Off</p>
                                </td>
                                <td className="px-1 py-2 border border-gray-300">
                                    <Switch
                                        checked={material.cutting[0]?.statusCutting}
                                        onChange={handleCuttingStatusChange} // Call the handler
                                        color="primary"
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td className="px-1 py-2 w-[50%] border border-gray-300">
                                    <p className="text-sm font-francois">Overallyield</p>
                                </td>
                                <td className="px-1 py-2 border border-gray-300">
                                    {material.cutting[0].overallyield}
                                </td>
                            </tr>
                            {/* Row for Total Cutting Fees */}
                            <tr>
                                <td className="px-1 py-2 w-[50%] border border-gray-300">
                                    <p className="text-sm font-francois">Total Cutting Fees</p>
                                </td>
                                <td className="px-1 py-2 border border-gray-300">
                                    {material.cutting[0].totalCuttingCost}
                                </td>
                            </tr>
                            {/* Row for Falcon Optimization Details */}
                            <tr>
                                <td colSpan={2} className="px-1 py-2 border border-gray-300 text-center">
                                    <p className="text-sm font-francois">Falcon Optimization Details</p>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </>
            )}

            <div className="flex justify-center mt-2">
                <table className="w-[95%] divide-y h-full divide-gray-200 bg-white">
                    {material.decisionsTable.map((item: { label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined; value: string; }, index: React.Key | null | undefined) => (
                        <tr key={index} className="h-auto">
                            <td className="px-1 py-2 w-[50%] bg-white-50 border border-gray-300">
                                <p className="text-xs text-gray-700 text-left font-normal font-francois tracking-wider break-all">
                                    {item.label}
                                </p>
                            </td>
                            <td className="px-1 py-1 whitespace-normal border rounded-br-md w-[50%]">
                                <div className="overflow-hidden flex flex-row">
                                    {item.label === 'Margin' ? (
                                        <EditableTableCell
                                            name="margin"
                                            value={item.value}
                                            onChange={handleChange}
                                            isEditing={isEditing}
                                        />
                                    ) : (
                                        <EditableTableCell
                                            value={item.value}
                                            onChange={handleChange}
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default DecisionsTable;
