import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

interface CustomerEmailProps {
    emails: any; // Adjust the type according to your data structure
    onQuoteSelect: (isSelected: boolean) => void; // Callback to notify about quote selection
}

const VendorReceivedQuotes: React.FC<CustomerEmailProps> = ({ emails, onQuoteSelect }) => {
    const handleCheckboxChange = (isSelected: boolean) => {
        onQuoteSelect(isSelected);
    };


    return (
        <div className="w-full flex flex-col justify-start items-center bg-white mt-2">
            {emails.map((email: any, index: number) => (
                <div
                    key={index}
                    className="flex flex-col justify-start items-center bg-white w-full mb-2"
                >
                    <p className={`text-sm font-medium text-gray-700 bg-gray-100 rounded-t-md w-full py-2 px-3 font-francois`}>
                        Received
                    </p>

                    <div className="w-full p-2 overflow-y-auto h-auto">
                        {email.receivedQuotes.map((quote: { vendorName: React.ReactNode; response: any; }, index: React.Key | null | undefined) => (

                            <div
                                key={index}
                                className="relative w-full h-full border border-gray-200 rounded-lg mb-4 flex flex-col" // Added flex-col for layout
                            >
                                {/* Vendor Name */}
                                <p className="text-sm font-medium text-gray-700 bg-gray-100 rounded-t-md w-full py-2 px-3 font-francois">
                                    {quote.vendorName}
                                </p>

                                {/* Response Body */}
                                <div className="w-full p-2 overflow-y-auto flex-1">
                                    <p
                                        className="text-sm mt-2"
                                        dangerouslySetInnerHTML={{ __html: quote.response }}
                                    />
                                </div>

                                {/* Select Quote Checkbox (Fixed at bottom) */}
                                <div className="w-full p-2 bg-white border-t border-gray-200">
                                    <label className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox"
                                            onChange={(e) => handleCheckboxChange(e.target.checked)}
                                        />
                                        <span className="text-sm">Select Quote</span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>



                </div>
            ))}
        </div>
    );
};

export default VendorReceivedQuotes;
