import React, {useState} from 'react';
import '../Stylesheets/EditableTable.css'
import EditableTableCell from "../Tables/EditableTableCell";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

interface CustomerDetailsQueueProps{
    customerData:any;
    shipmentData?:any;
}
const CustomerDetailsQueue: React.FC<CustomerDetailsQueueProps> = ({ customerData, shipmentData }) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => {
        setIsEditing(false);
    };
    const handleSave = () => {
        setIsEditing(false);
        // Save logic here
    };

    return (
        <div className="w-full flex flex-col justify-start items-center">
            <div className="w-full overflow-hidden border rounded-md bg-white shadow-sm">
                <div className="w-full h-10 bg-white px-4 py-2">
                    <div className="flex justify-between items-center">
                        <p className="text-[#3A3541DE] text-sm font-francois font-medium tracking-wide mr-4"> {/* Added margin-right */}
                            Customer Details
                        </p>
                        <div>
                            <IconButton onClick={isEditing ? handleCancel : handleEdit} className="text-[#264B6A]">
                                {isEditing ? <CloseIcon /> : <EditIcon />}
                            </IconButton>
                            {isEditing && (
                                <IconButton onClick={handleSave} color="primary">
                                    <CheckIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>

                </div>
                {customerData.map((email: any, index: number) => (
                    <div key={index} className={`w-full h-auto px-2 py-2 pt-0`}>

                        <table className="w-full h-auto">
                            {/* Name */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">Name</td>
                                <td className="px-4 py-2 h-full border text-gray-900 text-sm font-normal break-words">
                                    <EditableTableCell
                                        name={email.sender}
                                        value={email.sender || "N/A"}
                                        isEditing={isEditing}
                                    />
                                </td>
                            </tr>
                            {/* Email */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">
                                    Email
                                </td>
                                <td className="px-4 py-2 h-full border text-gray-900 text-sm font-normal break-words">
                                    <EditableTableCell
                                        name={email.senderEmail}
                                        value={email.senderEmail || "N/A"}
                                        isEditing={isEditing}
                                    />

                                </td>
                            </tr>

                            {/* CC */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">CC</td>
                                <td className="px-4 py-2 h-full border text-gray-900 text-sm font-normal break-words">
                                    {email.cc || "N/A"}
                                </td>
                            </tr>

                            {/* Company */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">
                                    Company
                                </td>
                                <td className="px-4 py-2 h-full text-gray-900 text-sm font-normal capitalize break-words">
                                    {email.company || "N/A"}
                                </td>
                            </tr>
                            {/* Shipment */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">
                                    Shipping Address
                                </td>
                                <td className="px-4 py-2 h-full text-gray-900 text-sm font-normal capitalize break-words">
                                    {email.destination || "N/A"}
                                </td>
                            </tr>
                        </table>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomerDetailsQueue;
