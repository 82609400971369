import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    FormControlLabel,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import CustomerEmail from '../Customer/CustomerEmail';
import RegeneratePopup from './RegeneratePopup';
import RFQEmail from '../RFQ/RFQEmail';
import CloseIcon from "@mui/icons-material/Close";


interface RFQPopupProps {
    open: boolean;
    onClose: () => void;
    customerData: any; // Replace `any` with the appropriate type for your emails if known
}

const RFQPopup: React.FC<RFQPopupProps> = ({ open, onClose, customerData }) => {

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <div className="bg-white rounded-lg w-full relative p-2"> {/* Dialog box styling */}
                {/* First Row: Close Icon in the top-right corner */}
                <div className="flex justify-end">
                    <IconButton onClick={onClose} className="text-gray-500">
                        <CloseIcon />
                    </IconButton>
                </div>
                {/* Second Row: RFQEmail component */}
                <div className="mt-4">
                    <RFQEmail emails={customerData} />
                </div>
            </div>
        </Dialog>
    );
};

export default RFQPopup;
