import React, {useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import success from "../animation/success.gif"
interface SuccessPopupProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ForwardedPopup: React.FC<SuccessPopupProps> = ({ open, onClose, onConfirm }) => {
    const [mailSentPopupOpen, setMailSentPopupOpen] = useState(false);

    const handleConfirmSend = () => {
        onConfirm();  // Trigger the onConfirm action if needed elsewhere
        setMailSentPopupOpen(true);  // Open the success popup
    };

    const handleCloseMailSentPopup = () => {
        setMailSentPopupOpen(false);  // Close the success dialog
        onClose();  // Optionally close the main dialog
    };

    // Automatically close the dialog after 5 seconds when it's opened
    useEffect(() => {
        if (mailSentPopupOpen) {
            const timer = setTimeout(() => {
                handleCloseMailSentPopup();
            }, 2000); // Close after 5 seconds

            // Clear the timeout if the component unmounts or dialog closes before 5 seconds
            return () => clearTimeout(timer);
        }
    }, [mailSentPopupOpen, handleCloseMailSentPopup]);

    return (
        <>
            {/* Confirmation Dialog */}
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    '& .MuiPaper-root': {  // Target the dialog paper to customize the width and border radius
                        width: '400px',
                        borderRadius: '0.75rem',
                        padding: '1.25rem',
                    },
                }}
            >
                <h2 className="font-francois text-xl mb-2.5">Forward Email</h2>
                <div className="flex gap-1 mt-5 m-3">
                    <table className="min-w-full divide-y divide-gray-200 h-[80%]">
                        <tr className="h-auto">
                            <td className="w-[50%] py-2 bg-white-50 border border-gray-300">
                                <p className="text-lg pl-2 text-gray-700 text-left font-normal">Email</p>
                            </td>
                            <td className="w-[50%] border border-gray-300">
                                <div className="h-full overflow-auto w-full flex-row justify-start items-start flex border p-2">
                                    <div className="flex flex-wrap w-full">
                                        <input type="text" placeholder="Add Email" className="text-xs text-gray-900 bg-transparent outline-none ml-1 w-full" value=""/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        <tr className="h-auto">
                            <td className="w-[50%] py-2 bg-white-50 border border-gray-300">
                                <p className="text-lg pl-2 text-gray-700 text-left font-normal">CC</p>
                            </td>
                            <td className="w-[50%] border border-gray-300">
                                <div className="h-full overflow-auto w-full flex-row justify-start items-start flex border p-2">
                                    <div className="flex flex-wrap w-full">
                                        <input type="text" placeholder="Add CC" className="text-xs text-gray-900 bg-transparent outline-none ml-1 w-full" value=""/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                </div>

                <div className="flex gap-1 mt-5 m-3">
                    <div className="w-full rounded-md flex justify-center items-center my-1 ">
                        <button
                            className="text-sm px-4 py-2 text-[#205B89] font-semibold rounded-md font-francois border border-[#205B89] bg-transparent"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="w-full rounded-md flex justify-center items-center my-1 ">
                        <button
                            className="text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]"
                            onClick={handleConfirmSend} // Open success popup on "Yes"
                        >
                            Forward
                        </button>
                    </div>
                </div>
            </Dialog>

            {/* Mail Sent Success Popup */}
            <Dialog
                open={mailSentPopupOpen}
                onClose={handleCloseMailSentPopup}
                aria-labelledby="mail-sent-title"
                aria-describedby="mail-sent-description"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    '& .MuiPaper-root': {
                        width: '400px',
                        borderRadius: '0.75rem',
                        padding: '1.25rem',
                    },
                }}
            >
                <img src={success} alt="" className="w-1/2 mx-auto mb-4" />

                <h2 className="text-xl mb-2.5 font-francois">
                    Mail was Forwarded successfully!
                </h2>
            </Dialog>
        </>
    );
};

export default ForwardedPopup;
