import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

interface CustomerEmailProps {
    emails: any; // Adjust the type according to your data structure
}

const VendorPendingDetails: React.FC<CustomerEmailProps> = ({ emails }) => {


    return (
        <div className="w-full flex flex-col justify-start items-center bg-white mt-2">
            {emails.map((email: any, index: number) => (
                <div key={index} className="flex flex-col justify-start items-center bg-white w-full mb-2">
                    <p className={`text-sm font-medium text-gray-700 bg-gray-100 rounded-t-md w-full py-2 px-3 font-francois`}>
                        Pending
                    </p>

                    <div className="w-full p-2 overflow-y-auto h-auto">
                        {email.missingQuotes.map((quote: { Material: React.ReactNode; Quantity: any; vendorName: string; }, index: React.Key | null | undefined) => (
                            <div key={index} className="mb-4">
                                {/* Vendor Name Heading */}
                                <h3 className="text-sm font-semibold mb-2">
                                    Pending from {quote.vendorName}
                                </h3>

                                {/* Table for Each Vendor */}
                                <table className="w-full border border-gray-200 rounded-lg mb-4">
                                    <thead className="bg-gray-100">
                                    <tr>
                                        <th className="text-sm font-medium text-gray-700 py-2 px-3 border border-gray-300">Material</th>
                                        <th className="text-sm font-medium text-gray-700 py-2 px-3 border border-gray-300">Quantity</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="hover:bg-gray-50">
                                        {/* Display Material */}
                                        <td className="text-sm py-2 px-3 border border-gray-300">
                                            {quote.Material}
                                        </td>
                                        {/* Display Quantity */}
                                        <td className="text-sm py-2 px-3 border border-gray-300">
                                            {quote.Quantity}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>

    );
};

export default VendorPendingDetails;
