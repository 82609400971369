
import React, { useState } from 'react';
import { Avatar, Box, Typography, Menu, MenuItem } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

interface AppBarAvatarProps {
    setActiveTab: (tab: string) => void; // Define the type for the setActiveTab prop
}

const AppBarAvatar: React.FC<AppBarAvatarProps> = ({ setActiveTab }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {id} = useParams<{ id: string }>();

    const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleContactUsClick = () => {
        setActiveTab('Contact Us'); // Set the active tab to "Contact Us"
        navigate(`/industry/${id}/contactUs`); // Navigate to the contact us page
        handleMenuClose(); // Close the menu
    };

    const handleFeedbackClick = () => {
        setActiveTab('Feedback'); // Set the active tab to "Feedback"
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSeOHVpRAndtOlQ9uPp1nfkRyB0ZSGDkoeNb5PvQIP1cHXTNAQ/viewform', '_blank'); // Open the feedback form in a new tab
        handleMenuClose(); // Close the menu
    };

    return (
        <div className="ml-2">
            <Avatar onMouseOver={handleMenuOpen} className="cursor-pointer bg-blue-500">
                M
            </Avatar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: 'white',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >


                {/* MenuItem for Contact Us */}
                <div onClick={handleContactUsClick} className="flex items-center py-4 px-3 cursor-pointer">
                    <LocalPhoneOutlinedIcon className="mr-2 text-black text-sm" fontSize="small" />
                    <p className="text-black text-sm">Contact Us</p>
                </div>

                {/* MenuItem for Feedback */}
                <div onClick={handleFeedbackClick} className="flex items-center py-4 px-3 cursor-pointer">
                    <MessageOutlinedIcon className="mr-2 text-black text-sm" fontSize="small" />
                    <p className="text-black text-sm">Feedback</p>
                </div>


            </Menu>
        </div>
    );
};

export default AppBarAvatar;
