import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
    Autocomplete,
    TextField,
    Checkbox,
    Grid,
    Chip,
    IconButton,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the CloseIcon


interface AddVendorPopupProps {
    open: boolean;
    onClose: () => void;
    materials: any;
}

const AddVendorPopup: React.FC<AddVendorPopupProps> = ({ open, onClose, materials }) => {
    const [formValues, setFormValues] = useState({
        company: '',
        email: '',
        name: '',
        materials: {} as Record<string, boolean>
    });

    const filteredMaterials = (materials || [])
        .filter((material: { stockStatus: string; }) =>
            material.stockStatus === 'PartialStock' || material.stockStatus === 'OutOfStock'
        );



    // Handle input change for Autocomplete
    const handleAutocompleteChange = (name: string, value: string | null) => {
        setFormValues(prevState => ({
            ...prevState,
            [name]: value || ''
        }));
    };

    // Handle checkbox change
    const handleCheckboxChange = (key: string, checked: boolean) => {
        setFormValues(prevState => ({
            ...prevState,
            materials: {
                ...prevState.materials,
                [key]: checked
            }
        }));
    };

    // Handle clear form values
    const handleClear = () => {
        setFormValues({
            company: '',
            email: '',
            name: '',
            materials: {}
        });
    };

    // Define options for Autocomplete (In a real scenario, options should be dynamic)
    const companyOptions = ['Company A', 'Company B', 'Company C'];
    const emailOptions = ['email1@example.com', 'email2@example.com', 'email3@example.com'];
    const nameOptions = ['Name A', 'Name B', 'Name C'];

    return (
        <Dialog open={open} onClose={onClose}>
            <div className="bg-white rounded-lg shadow-lg w-[400px] border-gray-400">
                <div className="self-stretch justify-start items-start inline-flex">
                    <div className={`flex-[1_1_0] justify-start items-center flex px-2.5 py-1 bg-white-110`}>
                        <div className={`flex-[1_1_0] justify-start items-center gap-1 flex`}>
                            <div className={`flex-col justify-start items-start inline-flex`}>
                                <p className="text-gray-500 text-xs font-normal tracking-tight font-orbitron break-words">Add a Vendor</p>
                            </div>
                            <IconButton onClick={onClose} className="text-white">
                                <CloseIcon />
                            </IconButton>
                        </div>

                    </div>
                </div>
                <div className="px-2 pt-2 w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                        <tr className={`h-auto`}>
                            <td className="px-1 py-2 bg-white-50 border border-gray-300 text-sm text-gray-900 text-left font-semibold font-orbitron tracking-wider break-words whitespace-nowrap">Company:</td>
                            <td className="px-1 py-1 whitespace-normal border rounded-br-md">
                                <Autocomplete
                                    size="small"
                                    options={companyOptions}
                                    value={formValues.company}
                                    onChange={(event, newValue) => handleAutocompleteChange('company', newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" />
                                    )}
                                />
                            </td>
                        </tr>
                        <tr className={`h-auto`}>
                            <td className="px-1 py-2 bg-white-50 border border-gray-300 text-sm text-gray-900 text-left font-semibold font-orbitron tracking-wider break-words whitespace-nowrap">Email:</td>
                            <td className="px-1 py-1 whitespace-normal border rounded-br-md">
                                <Autocomplete
                                    size="small"
                                    options={emailOptions}
                                    value={formValues.email}
                                    onChange={(event, newValue) => handleAutocompleteChange('email', newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" />
                                    )}
                                />
                            </td>
                        </tr>
                        <tr className={`h-auto`}>
                            <td className="px-1 py-2 bg-white-50 border border-gray-300 text-sm text-gray-900 text-left font-semibold font-orbitron tracking-wider break-words whitespace-nowrap">Name:</td>
                            <td className="px-1 py-1 whitespace-normal border rounded-br-md">
                                <Autocomplete
                                    size="small"
                                    options={nameOptions}
                                    value={formValues.name}
                                    onChange={(event, newValue) => handleAutocompleteChange('name', newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" />
                                    )}
                                />
                            </td>
                        </tr>
                        <tr className={`h-auto`}>
                            <td className="px-1 py-2 bg-white-50 border border-gray-300 text-sm text-gray-900 text-left font-semibold font-orbitron tracking-wider break-words whitespace-nowrap">Materials:</td>
                            <td className="px-1 py-1 whitespace-normal border rounded-br-md">
                                <div className="h-[25vh] overflow-auto">
                                    {filteredMaterials.map((material: { id: string; data: { value: string }[] }) => (
                                        <Chip
                                            key={material.id}
                                            label={material.data[0].value} // Accessing material properties directly
                                            onDelete={() => handleCheckboxChange(material.id, false)} // Use material.id here
                                            deleteIcon={
                                                <Checkbox
                                                    size="small"
                                                    checked={formValues.materials[material.id] || false} // Check based on material.id
                                                    onChange={(e) => handleCheckboxChange(material.id, e.target.checked)} // Use material.id here
                                                />
                                            }
                                            className="m-1"
                                        />
                                    ))}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="w-[400px] inline-flex justify-center items-center gap-3.5 p-3">
                    <div className={`w-full rounded-md flex justify-center items-center my-1 `}>
                        <button
                            className="flex-[1] w-full bg-[#205B89] text-white px-4 py-2 rounded-md font-francois"
                            onClick={onClose}

                        >
                            Cancel
                        </button>
                    </div>


                    <div className={`w-full rounded-md flex justify-center items-center my-1 `}>
                        <button
                            className="flex-[1] w-full bg-[#205B89] text-white px-4 py-2 rounded-md font-francois"
                            onClick={onClose}

                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AddVendorPopup;
