import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TextField,
    Autocomplete,
    Slider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const materials = ['Material1', 'Material2', 'Material3']; // Add your materials here
const vendors = ['Vendor1', 'Vendor2', 'Vendor3']; // Add your vendors here
const status = ['Clarification Pending', 'Sent', 'Clarification Received', 'Customer Response Pending', 'Received Quotes', 'Vendor Quote Pending', 'Forwarded']; // Add your vendors here


const QueueFilters: React.FC = () => {
    const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [priceRange, setPriceRange] = useState<number[]>([0, 1000]);

    const hasActiveFilters = () => {
        return (
            selectedMaterials.length > 0 ||
            selectedVendors.length > 0 ||
            startDate !== '' ||
            endDate !== '' ||
            priceRange[0] !== 0 ||
            priceRange[1] !== 1000
        );
    };
    const handleStatusChange = (_: any, value: string[]) => {
        setSelectedStatus(value);
    };

    const handleMaterialChange = (_: any, value: string[]) => {
        setSelectedMaterials(value);
    };

    const handleVendorChange = (_: any, value: string[]) => {
        setSelectedVendors(value);
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const handlePriceChange = (event: Event, newValue: number | number[]) => {
        setPriceRange(newValue as number[]);
    };

    const handleMaterialDelete = (material: string) => {
        setSelectedMaterials((prev) => prev.filter((item) => item !== material));
    };

    const handleVendorDelete = (vendor: string) => {
        setSelectedVendors((prev) => prev.filter((item) => item !== vendor));
    };


    return (
        <div>
            <h6 className="text-xl font-francois mb-4 bg-white p-2">Filters</h6>
            <div className="mt-2">
                <Accordion sx={{marginBottom:'1rem',  boxShadow:'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}

                    >
                        <h6 className={'font-biryani'}>RFQ Number</h6>

                    </AccordionSummary>
                    <AccordionDetails >
                        <div className="w-full">
                            <Autocomplete
                                multiple
                                options={materials}
                                value={selectedMaterials}
                                onChange={handleMaterialChange}
                                renderInput={(params) => <TextField {...params} label="Material" fullWidth/>}
                                className="mb-2"
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{marginBottom:'1rem',  boxShadow:'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}

                    >
                        <h6 className={'font-biryani'}>Materials</h6>

                    </AccordionSummary>
                    <AccordionDetails >
                        <div className="w-full">
                            <Autocomplete
                                multiple
                                options={materials}
                                value={selectedMaterials}
                                onChange={handleMaterialChange}
                                renderInput={(params) => <TextField {...params} label="Material" fullWidth/>}
                                className="mb-2"
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{marginBottom:'1rem',  boxShadow:'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <h6 className={'font-biryani'}>Date</h6>

                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex gap-2">
                            <TextField
                                type="date"
                                label="Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                InputLabelProps={{shrink: true}}
                                className="flex-grow"
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{marginBottom:'1rem',  boxShadow:'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <h6 className={'font-biryani'}>Status</h6>
                    </AccordionSummary>
                    <AccordionDetails >
                        <div className="w-full">
                            <Autocomplete
                                multiple
                                options={status}
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                renderInput={(params) => <TextField {...params} label="Status" fullWidth/>}
                                className="mb-2"
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{marginBottom:'1rem',  boxShadow:'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <h6 className={'font-biryani'}>Vendors</h6>

                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-full">
                            <Autocomplete
                                multiple
                                options={vendors}
                                value={selectedVendors}
                                onChange={handleVendorChange}
                                renderInput={(params) => <TextField {...params} label="Vendor" fullWidth/>}
                                className="mb-2"
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{marginBottom:'1rem',  boxShadow:'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{position: 'relative'}}
                    >
                        <h6 className={'font-biryani'}> Quote Price</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-full">
                            <p className="mb-2">Quote Price</p>
                            <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={1000}
                                max={100000}
                                step={1000}
                                className="mb-2"
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>

    );
};


export default QueueFilters;
