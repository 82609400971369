import MaterialDetails from "../Materials/MaterialDetails";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import queueData from "../DataContent/QueueData";
import CustomerDetails from "../Customer/CustomerDetails";
import SuccessPopup from "../Popups/SuccessPopup";
import MaterialsReceived from "../Materials/MaterialsReceived";
import CustomerReceivedReview from "../Customer/CustomerReceivedReview";


const ReceivedQuotesReview: React.FC = () => {
    const navigate = useNavigate();
    const [emailData, setEmailData] = useState<any[]>([]);
    const [successPopupOpen, successSetPopupOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);

    const handleSend = () => {
        successSetPopupOpen(true); // Open the popup when "Send" is clicked
    };


    const handleClosePopup = () => {
        successSetPopupOpen(false);
    };

    // Use useParams to get the id and rfq from the URL
    const {id, rfq} = useParams<{ id: string; rfq: string }>();

    useEffect(() => {
        // Find the industry data matching the specific id
        const industry: any = queueData.find((item: any) => item.id === id);

        // Use the `any` type to filter emails based on the RFQ
        const filteredEmails = industry ? industry.emails.filter((email: any) => email.rfq === rfq) : [];

        // Set the filtered emails in the state
        setEmailData(filteredEmails);
    }, [id, rfq]);

    // Extract the tableData from the filteredEmails for the MaterialDetails
    const tableData = emailData.map((email: any) => email.tableData).flat(); // Flatten if there are multiple emails
    const tableDataReceived = emailData.map((email: any) => email.receivedQuotesTable).flat(); // Flatten if there are multiple emails

    const handleConfirmSend = () => {
        // Keep the popup open for 3 seconds before navigating
        setTimeout(() => {
            successSetPopupOpen(false);
            navigate(`/industry/${id}/queue`); // Navigate to the queue
        }, 2000); // 3 seconds delay
    };

    const handleBackClick = () => {
        navigate(`/industry/${id}/queue/${rfq}/receivedQuotes`);
    };


    return (
        <div className={` col-span-3 w-full h-full flex flex-col items-center gap-1 overflow-y-auto  px-1 `}>
            <div className="flex flex-row items-center gap-2 py-2 w-full">
                {emailData.length > 0 && (
                    <div className="mt-1 mb-1 w-full pl-2 font-bold flex-[9] text-green-600">
                        {/* Display the automationStatus from emailData */}
                        {emailData.map((email: any, index: number) => (
                            <div key={index}>
                                {email.automationStatus ? (
                                    <span>{email.automationStatus}: Check the quote generate to the customer and send it to them</span>
                                ) : (
                                    <span>No Queue Status</span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <button
                    className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                    onClick={handleBackClick}
                >
                    Back
                </button>
                <button
                    className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                    onClick={handleSend}
                >
                    Send
                </button>
                {/* SuccessPopup dialog */}
                <SuccessPopup
                    open={successPopupOpen}
                    onClose={handleClosePopup}
                    onConfirm={handleConfirmSend}
                />
            </div>

                <div className="flex-full flex flex-col justify-start items-center w-full">
                    <div className="w-full flex flex-col justify-center items-center">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full"> {/* Change to grid-cols-3 for three columns */}
                            {/* First Column */}
                            <div className="flex flex-col h-auto w-full">
                                {/* Customer Details */}
                                <div className="flex flex-col h-auto overflow-y-auto border border-gray-200 rounded-md w-full mb-2"> {/* Added mb-2 for spacing */}
                                    <CustomerDetails customerData={emailData} />
                                </div>

                                {/* Material Details */}
                                <div className="flex flex-col h-auto overflow-y-auto border border-gray-200 rounded-md w-full">
                                    <MaterialDetails materials={tableData} />
                                </div>
                            </div>

                            {/* Second Column */}
                            <div className="flex flex-col h-full w-full ">
                                <div className="flex flex-col gap-2 overflow-y-auto border border-gray-200 rounded-md w-full">
                                    <MaterialsReceived materials={tableDataReceived} />
                                    <CustomerReceivedReview emails={emailData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

    );
}
export default ReceivedQuotesReview;
