import React, { useState } from 'react';
import IndustryCard from '../IndustryCard';
import industryData from '../DataContent/industryData';
import backgroundSignIn from '../Images/backgroundSignIn.png'; // Adjust the path as needed


const WiseSales = () => {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredIndustryData = industryData.filter(industry =>
        industry.title.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div
            className="relative flex flex-col items-center justify-center min-h-screen overflow-auto transition-all duration-500 px-10 sm:px-16"
        >
            {/* Background image */}
            <img
                src={backgroundSignIn}
                alt="Background"
                className="absolute inset-0 w-full min-h-full object-cover z-0"
            />

            {/* Overlay to ensure content is on top of the background */}
            <div className="relative z-10">
                {/* Centered Title */}
                <h1 className="text-4xl text-white font-francois text-center m-4">
                    Welcome to the Wizard Tester
                </h1>
                <p className="text-lg text-white font-biryani text-center mt-2">
                    Please choose your industry
                </p>

                {/* Search and Industry Cards Section */}
                <div className="w-full max-w-lg mx-auto px-4 mt-8">
                    <div className="mb-10">
                        <input
                            type="text"
                            placeholder="Search Industry"
                            value={searchText}
                            onChange={handleSearchChange}
                            className="w-full px-4 py-3 rounded-full border border-gray-300 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    {/* Centered Industry Cards */}
                    <div className="grid gap-6 justify-center">
                        {filteredIndustryData.map((industry, index) => (
                            <div key={index} className=" shadow-md p-6 w-150">
                                <IndustryCard {...industry} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default WiseSales;
