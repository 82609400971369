import React, { useState } from 'react';
import { Box, Typography, Paper, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import RegeneratePopup from "../Popups/RegeneratePopup";
import AddVendorPopup from "../Popups/AddVendorPopup";

interface VendorEmailsProps {
    emails: any; // You can refine the type to match your data structure
}

const VendorEmailPending: React.FC<VendorEmailsProps> = ({ emails }) => {

    const [popupOpen, setPopupOpen] = useState(false);

    const handleOpenClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    // Check if emails is valid and contains at least one item
    const initialVendor = emails && emails.length > 0 && emails[0].vendors.length > 0 ? emails[0].vendors[0] : '';

    // State to track the currently selected vendor tab
    const [activeVendor, setActiveVendor] = useState<string>(initialVendor);

    // Handle vendor tab click
    const handleVendorClick = (vendor: string) => {
        setActiveVendor(vendor);
    };

    return (
        <div className="w-full flex flex-col justify-start items-center bg-white mt-2 rounded-lg">
            {/* Ensure emails and emails[0].vendors exist before rendering the component */}
            {emails && emails.length > 0 ? (
                emails.map((email: any, index: number) => (
                    <div
                        key={index}
                        className="flex flex-col justify-start items-center rounded-t-lg bg-white w-full mb-2"
                    >
                        {/* "Vendors" Text */}
                        <p className="w-full rounded-t-lg bg-white border border-gray-200 py-1 px-1 font-francois">
                            Vendors Follow Up Email
                        </p>

                        {/* Tabs for vendors based on missingQuotes */}
                        {email.missingQuotes && email.missingQuotes.length > 0 ? (
                            <div className="flex justify-start w-full">
                                {email.missingQuotes.map((quote: any) => (
                                    <button
                                        key={quote.vendorName}
                                        onClick={() => handleVendorClick(quote.vendorName)}
                                        className={`py-2 px-4 border-b-2 ${
                                            activeVendor === quote.vendorName ? 'border-black text-black' : 'border-transparent'
                                        } font-francois`}
                                    >
                                        {quote.vendorName}
                                    </button>
                                ))}
                            </div>
                        ) : (
                            <p>No vendors available.</p>
                        )}

                        {/* Body Content */}
                        <div className="w-full p-2 overflow-y-auto">
                            <div className="w-full">
                                <p className="text-sm" dangerouslySetInnerHTML={{ __html: email.vendorFollowUp }} />
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No vendors needed</p>
            )}
        </div>

    );
};

export default VendorEmailPending;
