import RFQEmail from "../../RFQ/RFQEmail";
import MaterialDetails from "../../Materials/MaterialDetails";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import queueData from "../../DataContent/QueueData";
import CustomerDetailsQueue from "../../Customer/CustomerDetailsQueue";
import MaterialsDecisionsAutomated from "../../Materials/MaterialsDecisionsAutomated";
import FreightDetails from "../../Freight/FreightDetails";
import CustomerEmail from "../../Customer/CustomerEmail";
import VendorEmails from "../../Vendors/VendorEmails";
import RegeneratePopup from "../../Popups/RegeneratePopup";
import SuccessPopup from "../../Popups/SuccessPopup";
import ForwardedPopup from "../../Popups/ForwardedPopup";


const QueueThirdStep: React.FC = () => {
    const navigate = useNavigate();
    const [emailData, setEmailData] = useState<any[]>([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [successPopupOpen, successSetPopupOpen] = useState(false);
    const [forwardPopupOpen, forwardSetPopupOpen] = useState(false);

    const handleSend = () => {
        successSetPopupOpen(true); // Open the popup when "Send" is clicked
    };

    const handleOpenClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        successSetPopupOpen(false);
    };
    // Use useParams to get the id and rfq from the URL
    const {id, rfq} = useParams<{ id: string; rfq: string }>();

    useEffect(() => {
        // Find the industry data matching the specific id
        const industry: any = queueData.find((item: any) => item.id === id);

        // Use the `any` type to filter emails based on the RFQ
        const filteredEmails = industry ? industry.emails.filter((email: any) => email.rfq === rfq) : [];

        // Set the filtered emails in the state
        setEmailData(filteredEmails);
    }, [id, rfq]);

    // Extract the tableData from the filteredEmails for the MaterialDetails
    const tableData = emailData.map((email: any) => email.tableData).flat(); // Flatten if there are multiple emails
    const shipmentData = emailData.map((email: any) => email.shipments).flat(); // Flatten if there are multiple emails


    const handleBackClick = () => {
        navigate(`/industry/${id}/queue/${rfq}/clarificationReceived/second`);
    };

    const handleForward = () => {
        forwardSetPopupOpen(true); // Open the popup when "Send" is clicked
    };

    const handleForwardClosePopup = () => {
        forwardSetPopupOpen(false);
        setPopupOpen(false);
    };

    const handleConfirmSend = () => {
        // Keep the popup open for 3 seconds before navigating
        setTimeout(() => {
            successSetPopupOpen(false);
            navigate(`/industry/${id}/queue`); // Navigate to the queue
        }, 2000); // 3 seconds delay
    };


    return (
        <div className={`container mx-auto px-4 `}>
            <div className={`lg:col-span-3 w-full h-full flex flex-col items-center gap-4 overflow-y-auto px-1 `}>
                <div className="flex flex-row items-center gap-2 w-full">
                    {emailData.length > 0 && (
                        <div className="mt-1 mb-1 w-full pl-2 font-bold flex-[9]">
                            3. Check your emails and send them
                        </div>
                    )}
                    <button
                        className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                        onClick={handleBackClick}
                    >
                        Back
                    </button>
                    <div className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>

                        <button
                            className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                            onClick={handleSend}
                        >
                            Send
                        </button>

                        {/* SuccessPopup dialog */}
                        <SuccessPopup
                            open={successPopupOpen}
                            onClose={handleClosePopup}
                            onConfirm={handleConfirmSend}
                        />
                    </div>
                    <div className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>

                        <button
                            className={`text-sm px-4 py-2 text-[#205B89] font-semibold rounded-md font-francois border border-[#205B89] bg-transparent`}
                            onClick={handleForward}
                        >
                            Forward Email
                        </button>

                        <ForwardedPopup
                            open={forwardPopupOpen}
                            onClose={handleForwardClosePopup}
                            onConfirm={handleConfirmSend}
                        />
                    </div>
                </div>

                <div className="flex-full flex flex-col justify-start items-center w-full h-[80vh]"> {/* Set the height to 65vh here */}
                    <div className={`w-full flex flex-col justify-center items-center h-full`}> {/* Use h-full to take the full height */}
                        <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 w-full h-full`}> {/* Ensure the grid also takes full height */}
                            <div className="bg-white p-4 shadow-md rounded-lg flex flex-col space-y-4 h-full"> {/* h-full for full height */}
                                <div className="flex flex-col h-auto w-full ">
                                    <div className="flex flex-col h-[65vh] gap-2 overflow-y-auto border border-gray-200 rounded-md w-full"> {/* Set height and enable overflow */}
                                        <CustomerEmail emails={emailData}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`bg-white p-4 shadow-md rounded-lg h-full`}> {/* h-full for full height */}
                                <div className="flex flex-col h-[65vh] gap-2 overflow-y-auto border border-gray-200 rounded-md w-full"> {/* Set height and enable overflow */}
                                    <VendorEmails emails={emailData}/>
                                </div>
                            </div>
                        </div>
                        <div className={`self-stretch w-[100px] ml-auto rounded bg-blue-110 mt-4`}>
                            <button
                                className="flex-[1] w-full bg-[#205B89] text-white px-4 py-2 rounded-md font-francois"
                                onClick={handleOpenClick}
                            >
                                Regenerate
                            </button>

                            <RegeneratePopup
                                open={popupOpen}
                                onClose={handleClosePopup}
                            />
                        </div>
                    </div>
                </div>


            </div>
        </div>

    );
}
export default QueueThirdStep;
