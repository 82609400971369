import React from 'react';
import AutomationFilters from "./Filters/AutomationFilters";
import AutomationListItems from "./AutomationListItems";

const AutomationList: React.FC = () => {
    return (
        <div className="flex flex-col w-full p-2 md:p-4 h-full"> {/* Added margin-top to account for the AppBar height */}

            {/* Flexbox for the layout */}
            <div className="flex flex-col md:flex-row mt-2 md:mt-5 gap-4 h-full"> {/* Set height to full */}
                {/* Automation Filters taking 3 columns */}
                <div className="w-full md:w-1/4 lg:w-1/5 p-4 border bg-white rounded-md"> {/* Ensure the filters take full height */}
                    <AutomationFilters />
                </div>

                {/* Automation List Items taking 9 columns */}
                <div className="flex-grow-0 flex-shrink-0 w-3/4 overflow-auto h-full"> {/* Ensure the list items also take full height */}
                    <AutomationListItems />
                </div>
            </div>

        </div>
    );
};


export default AutomationList;
