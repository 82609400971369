import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

interface CustomerEmailProps {
    emails: any; // Use 'any' type for emails, can be adjusted to match your data structure
}

const CustomerEmail: React.FC<CustomerEmailProps> = ({ emails }) => {
    return (
        <div className=" w-full flex flex-col justify-start items-center bg-white ">
            {emails.map((email: any, index: number) => (
                <div
                    key={index}
                    className="flex flex-col justify-start items-center bg-white w-full mb-2"
                >
                    <p className="flex justify-between w-full rounded-t-lg bg-white border border-gray-200 py-1 px-1 font-francois">Customer</p>

                    <div className="flex flex-col w-full m-auto h-[5vh] gap-[3px] mt-[10px] border bg-white-50 mb-[10px]">
                        <div className="h-full w-full flex-row justify-start items-start flex p-2">
                            <p className="text-xs text-gray-900 text-left font-francois tracking-wider">CC:</p>
                            <div className="flex flex-wrap">
                                <input className="text-xs text-gray-900 bg-transparent outline-none ml-1" placeholder="Add CC" type="text">
                                </input>
                            </div>
                        </div>
                    </div>

                    {/* Body Content: Only this part will be scrollable */}
                    <div className="w-full p-4 overflow-y-auto border-b border-gray-300 max-h-[47vh]">
                        <div className="w-full">
                            <p
                                className="text-sm"
                                dangerouslySetInnerHTML={{ __html: email.customerResponse }}
                            />
                        </div>
                    </div>

                    <div className="flex items-center mt-2 py-2">
                        <input
                            type="checkbox"
                            id="taxExempt"
                            className="mr-2"
                        />
                        <label htmlFor="taxExempt" className="text-sm">
                            Tax Exempt
                        </label>
                    </div>
                </div>

            ))}
        </div>
    );
};

export default CustomerEmail;

