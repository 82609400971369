import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill styles
import falconData from "../DataContent/FalconData";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useParams} from "react-router-dom";

const Settings: React.FC = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [signature, setSignature] = useState(''); // State to store the signature content
    const [selectedMaterial, setSelectedMaterial] = useState<any>(null);
    const {id} = useParams<{ id: string }>();
    // Determine whether to show the Falcon Settings tab
    const showFalconSettings = id !== '1'; // Hide tab if id is '1'
    const handleRowClick = (item: any) => {
        setSelectedMaterial(item);
    };
    const handleSignatureChange = (value: string) => {
        setSignature(value);
    };
    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    return (
        <div className="w-full min-h-[92vh] p-4 bg-white rounded-lg">
            <h1 className="text-xl font-francois mb-6">Manage your account settings and preferences</h1>

            {/* Tabs for Navigation */}
            <div className="flex border-b">
                <button
                    onClick={() => handleTabChange(0)}
                    className={`py-2 px-4 font-semibold text-sm ${
                        activeTab === 0 ? 'border-b-2 border-blue-800 text-blue-800' : 'text-gray-600'
                    }`}
                >
                    My Location
                </button>
                <button
                    onClick={() => handleTabChange(1)}
                    className={`py-2 px-4 font-semibold text-sm ${
                        activeTab === 1 ? 'border-b-2 border-blue-800 text-blue-800' : 'text-gray-600'
                    }`}
                >
                    My Signature
                </button>
                {showFalconSettings && (
                    <button
                        onClick={() => handleTabChange(2)}
                        className={`py-2 px-4 font-semibold text-sm ${
                            activeTab === 2 ? 'border-b-2 border-blue-800 text-blue-800' : 'text-gray-600'
                        }`}
                    >
                        Falcon Settings
                    </button>
                )}
            </div>

            {/* Tab Panels */}
            <div className="mt-4">
                {activeTab === 0 && (
                    <div className="mt-6">
                        <div>
                            <p className="mb-4 text-sm">
                                If you change your location, your pending Ready to Send Automated Quotes till date will change to Need Manual Engagement, and you will have to delegate each one of them again. All new quotes will process based on the new location selected.
                            </p>
                            <div className="mb-4">
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                    Location
                                </label>
                                <select id="location" className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-200 border-gray-300 focus:outline-none focus:ring-blue-110 focus:border-blue-110 sm:text-sm rounded-md">
                                    <option value="D1">Austin</option>
                                    <option value="H1">Birmingham</option>
                                    <option value="C1">Boise</option>
                                    <option value="L1">Des Moines</option>
                                    <option value="B1">Hayward</option>
                                    <option value="I1">Los Angeles</option>
                                    <option value="E1">Portland</option>
                                    <option value="A1">Sacramento</option>
                                    <option value="G1">Seattle</option>
                                    <option value="Ecom">Ecom</option>
                                </select>
                            </div>
                            <div className="flex justify-self-end">
                                <div className="rounded-md flex justify-center items-center my-1 w-min">
                                    <button
                                        className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89] w-full`}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 1 && (
                    <div className="mt-6">
                        <div className="flex flex-col h-full max-h-[calc(90vh-200px)]">
                            <h2 className="text-xl font-bold mb-4">Signature</h2>

                            {/* Quill Editor */}
                            <div className="flex-grow bg-white">
                                <ReactQuill
                                    value={signature}
                                    onChange={handleSignatureChange}
                                    placeholder="Type your signature here..."
                                    theme="snow" // Use the snow theme for React Quill
                                    className="h-[30vh] mb-8"
                                />
                            </div>

                            <div className="mt-4 flex justify-between items-center">
                                <p className="text-gray-600 text-sm">
                                    You can format your signature using the editor above.
                                </p>
                                <div className="rounded-md flex justify-center items-center my-1">
                                    <button className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}>
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 2 && (
                    <div className={`mt-6`}>
                        <div className={`flex flex-col h-full`}>
                            <h2 className="text-xl font-bold mb-4">Falcon Settings</h2>
                            <div className="flex flex-col md:flex-row flex-1 space-y-4 md:space-y-0 md:space-x-4">
                                <div className="w-full md:w-1/2 flex flex-col h-[calc(90vh-200px)]">
                                    <div className="mb-4 flex items-center p-2 rounded-md border border-gray-300">
                                        <input
                                            type="text"
                                            className="bg-transparent outline-none w-full text-base"
                                            placeholder="Search"
                                        />
                                    </div>
                                    {/* Table Section */}
                                    <div className="flex-1 overflow-auto">
                                        <table className="w-full text-left text-gray-500 table-fixed border">
                                            <thead className="text-base text-gray-700 uppercase bg-gray-50 sticky top-0">
                                            <tr>
                                                <th className="px-3 py-2">Material</th>
                                                <th className="px-3 py-2 text-right">Last Updated</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {falconData.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className="hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleRowClick(item)}
                                                >
                                                    <td className="px-3 py-3 text-base">{item.material}</td>
                                                    <td className="px-3 py-3 text-right text-base">{item.lastUpdated}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="flex justify-between items-center mt-3 text-base">
                                        <span>Rows:10</span>
                                        <div className="flex items-center">
                                            <button>
                                                <KeyboardArrowLeftIcon />
                                            </button>
                                            <span className="mx-2">
                                                Page 1 of 1
                                              </span>
                                            <button>
                                                <KeyboardArrowRightIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 border rounded p-4 flex flex-col h-[calc(90vh-200px)]">
                                    <h3 className="text-xl font-bold mb-4">Falcon Details</h3>
                                    {selectedMaterial ? (
                                        <div className="flex-1 overflow-auto">
                                            <table className="w-full border-collapse">
                                                <tbody>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Grade</td>
                                                    <td className="border px-4 py-2">
                                                        <input
                                                            type="text"
                                                            value={selectedMaterial.details.grade}
                                                            readOnly
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Customer Reference</td>
                                                    <td className="border px-4 py-2">
                                                        <input
                                                            type="text"
                                                            value={selectedMaterial.details.customerReference}
                                                            readOnly
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Units</td>
                                                    <td className="border px-4 py-2">
                                                        <input
                                                            type="text"
                                                            value={selectedMaterial.details.units}
                                                            readOnly
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Saw Blade Kerf</td>
                                                    <td className="border px-4 py-2">
                                                        <input
                                                            type="text"
                                                            value={selectedMaterial.details.sawBladeKerf}
                                                            readOnly
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Stack Height</td>
                                                    <td className="border px-4 py-2">
                                                        <input
                                                            type="text"
                                                            value={selectedMaterial.details.stackHeight}
                                                            readOnly
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Hourly Rate</td>
                                                    <td className="border px-4 py-2">
                                                        <input
                                                            type="text"
                                                            value={selectedMaterial.details.hourlyRate}
                                                            readOnly
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Trim Cut Required</td>
                                                    <td className="border px-4 py-2">
                                                        <select
                                                            value={selectedMaterial.trimCutRequired ? 'true' : 'false'}
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                            onChange={(e) => {
                                                                // Update state logic here
                                                            }}
                                                        >
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Allow Head Cuts</td>
                                                    <td className="border px-4 py-2">
                                                        <select
                                                            value={selectedMaterial.allowHeadCuts ? 'true' : 'false'}
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                            onChange={(e) => {
                                                                // Update state logic here
                                                            }}
                                                        >
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2 font-semibold">Use Difficult Remnants</td>
                                                    <td className="border px-4 py-2">
                                                        <select
                                                            value={selectedMaterial.useDifficultRemnants ? 'true' : 'false'}
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                            onChange={(e) => {
                                                                // Update state logic here
                                                            }}
                                                        >
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <p className="text-gray-500">Select a material to see its details.</p>
                                    )}
                                    <div className={`mt-4 pt-2 border-t`}>
                                        <div className={`w-full rounded-md flex justify-center items-center my-1`}>
                                            <button
                                                className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}>
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Settings;
