import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditableTableCell from './EditableTableCell';

const MaterialsReviewTable: React.FC<{ material: any }> = ({ material }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableMaterial, setEditableMaterial] = useState(material);

    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => {
        setIsEditing(false);
        setEditableMaterial(material);
    };
    const handleSave = () => {
        setIsEditing(false);
        // Save logic here
    };

    const handleChange = (name: string, value: string) => {
        setEditableMaterial({
            ...editableMaterial,
            [name]: value,
        });
    };

    // Find the value for Material Type
    const materialTypeValue = material.data.find((item: { label: string; }) => item.label === 'Material Type')?.value ||
        material.data.find((item: { label: string; }) => item.label === 'Component Type')?.value;

    return (
        <div
            className={`w-full p-2 h-full border flex-col justify-start inline-flex overflow-y-auto rounded-md border-gray-300`}
        >
            <div className="border-b border-black flex justify-between items-center">
                <p className="text-sm min-w-5 font-francois">{materialTypeValue}</p>
                <div>
                    <IconButton onClick={isEditing ? handleCancel : handleEdit} className="text-[#264B6A]">
                        {isEditing ? <CloseIcon /> : <EditIcon />}
                    </IconButton>
                    {isEditing && (
                        <IconButton onClick={handleSave} color="primary">
                            <CheckIcon />
                        </IconButton>
                    )}
                </div>
            </div>

            <table className="min-w-full divide-y h-full divide-gray-200">
                {/* Map through the data array and create table rows */}
                {material.data.map((item: any) => (
                    item.label !== 'Material Type' && item.label !== 'Component Type' && (  // Exclude "Material Type"
                        <tr key={item.label} className="h-auto">
                            <td className="px-1  w-[50%] bg-white-50 border border-gray-300">
                                <p className="text-xs text-gray-700 text-left font-normal font-francois tracking-wider break-all">
                                    {item.label}
                                </p>
                            </td>
                            <td className="px-1 whitespace-normal border rounded-br-md w-[50%]">
                                <div className="overflow-hidden flex flex-row">
                                    <EditableTableCell
                                        name={item.value}
                                        value={item.value || ''}
                                        isEditing={isEditing}
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                ))}
            </table>

        </div>
    );
};

export default MaterialsReviewTable;
