import React, {useEffect, useState} from 'react';
import {Box, Button, Paper, Typography, IconButton, Tooltip, Modal} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import AutomationFilters from "../Filters/AutomationFilters";
import AutomationListItems from "../AutomationListItems";
import CustomerDetails from "../../Customer/CustomerDetails";
import FreightDetails from "../../Freight/FreightDetails";
import MaterialDetails from "../../Materials/MaterialDetails";
import MaterialsDecisionsAutomated from "../../Materials/MaterialsDecisionsAutomated";
import RFQPopup from "../../Popups/RFQPopup";
import industryData from "../../DataContent/industryData";
import CustomerEmail from '../../Customer/CustomerEmail';
import VendorEmails from '../../Vendors/VendorEmails';
import RFQEmail from "../../RFQ/RFQEmail";
import RegeneratePopup from "../../Popups/RegeneratePopup";
import SuccessPopup from "../../Popups/SuccessPopup";
import ForwardedPopup from '../../Popups/ForwardedPopup';

const AutomationDetails: React.FC = () => {

    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const [emailData, setEmailData] = useState<any[]>([]);
    const [is_review_details, set_is_review_details] = useState("1");
    const [popupOpen, setPopupOpen] = useState(false);
    const [successPopupOpen, successSetPopupOpen] = useState(false);
    const [forwardPopupOpen, forwardSetPopupOpen] = useState(false);


    const handleSend = () => {
        successSetPopupOpen(true); // Open the popup when "Send" is clicked
    };

    const handleForward = () => {
        forwardSetPopupOpen(true); // Open the popup when "Send" is clicked
    };
    const handleOpenClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        successSetPopupOpen(false);
        setPopupOpen(false);
    };

    const handleForwardClosePopup = () => {
        forwardSetPopupOpen(false);
        setPopupOpen(false);
    };

    // Use useParams to get the id and rfq from the URL
    const {id, rfq} = useParams<{ id: string; rfq: string }>();
    useEffect(() => {
        // Find the industry data matching the specific id
        const industry: any = industryData.find((item: any) => item.id === id);

        // Use the `any` type to filter emails based on the RFQ
        const filteredEmails = industry ? industry.emails.filter((email: any) => email.rfq === rfq) : [];

        // Set the filtered emails in the state
        setEmailData(filteredEmails);
    }, [id, rfq]);

    // Extract the tableData from the filteredEmails for the MaterialDetails
    const tableData = emailData.map((email: any) => email.tableData).flat(); // Flatten if there are multiple emails
    const shipmentData = emailData.map((email: any) => email.shipments).flat(); // Flatten if there are multiple emails



    const handleBackClick = () => {
        navigate(`/industry/${id}/automation`);
    };

    const handleConfirmSend = () => {
        // Keep the popup open for 3 seconds before navigating
        setTimeout(() => {
            successSetPopupOpen(false);
            forwardSetPopupOpen(false);
            navigate(`/industry/${id}/automation`); // Navigate to the queue
        }, 2000); // 3 seconds delay
    };

    return (
        <div className={` col-span-3 w-full h-full flex flex-col items-center gap-1 overflow-y-auto  px-1 `}>
            <div className="flex flex-row items-center gap-2 w-full">
                {emailData.length > 0 && (
                    <div className="mt-1 mb-1 w-full pl-2 font-bold flex-[9] text-green-600">
                        {/* Display the automationStatus from emailData */}
                        {emailData.map((email: any, index: number) => (
                            <div key={index}>
                                {email.automationStatus ? (
                                    <span>{email.automationStatus}</span>
                                ) : (
                                    <span>No Automation Status</span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <button
                    className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                    onClick={handleBackClick}
                >
                    Back
                </button>
                <div className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>

                    <button
                        className={`text-sm px-4 py-2 text-[#205B89] font-semibold rounded-md font-francois border border-[#205B89] bg-transparent`}>
                        Move To Manual Process
                    </button>
                </div>
            </div>
            <div className="flex flex-col items-center gap-2 w-full">
                <div className="flex flex-row gap-2 w-full ml-2">
                    <select
                        className="flex-[9] p-2 border rounded-md w-full"
                        onChange={(e) => {
                            set_is_review_details(e.target.value);
                        }}
                    >
                        <option value="1" className="text-sm">
                            Review the Emails that will be sent to the Customer or check the details of the quote
                        </option>
                        <option value="2" className="text-sm">
                            Review the details of the RFQ
                        </option>
                    </select>

                            <div
                                className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>

                                <button
                                    className="flex-[1] w-full bg-[#205B89] text-white px-4 py-2 rounded-md font-francois"
                                    onClick={handleSend}
                                >
                                    Send
                                </button>
                                {/* SuccessPopup dialog */}
                                <SuccessPopup
                                    open={successPopupOpen}
                                    onClose={handleClosePopup}
                                    onConfirm={handleConfirmSend}
                                />
                            </div>
                            <div
                                className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>

                                <button
                                    className="flex-[1] w-full bg-[#205B89] text-white px-4 py-2 rounded-md font-francois"
                                    onClick={handleForward}
                                >
                                    Forward Email
                                </button>

                                <ForwardedPopup
                                    open={forwardPopupOpen}
                                    onClose={handleForwardClosePopup}
                                    onConfirm={handleConfirmSend}
                                />
                            </div>
                </div>
            </div>

            {is_review_details === "1" ? (
                <div className="flex-full flex flex-col justify-start items-center w-full">
                    <div className={`w-full flex flex-col justify-center items-center mt-1`}>
                        <div className={`w-full p-2`}>
                            <div className="flex flex-col  lg:flex-row w-full h-[65vh] gap-3">
                                <div className="flex flex-col h-auto w-full ">
                                    <div className="flex flex-col h-[65vh] bg-white gap-2 overflow-y-auto border border-gray-200 rounded-md w-full"> {/* Set height and enable overflow */}
                                        <RFQEmail emails={emailData} />
                                    </div>
                                </div>

                                <div className="flex flex-col h-auto w-full ">
                                    <div className="flex flex-col h-[75vh] gap-2 overflow-y-auto border border-gray-200 rounded-md w-full"> {/* Set height and enable overflow */}
                                        <CustomerEmail emails={emailData}/>
                                    </div>
                                </div>
                                <div className="flex flex-col h-auto w-full ">
                                    <div className="flex flex-col h-[75vh] gap-2 overflow-y-auto border border-gray-200 rounded-md w-full"> {/* Set height and enable overflow */}
                                        <VendorEmails emails={emailData}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`self-stretch w-[100px] ml-auto rounded bg-blue-110 mt-4`}>
                                <button
                                    className="flex-[1] w-full bg-[#205B89] text-white px-4 py-2 rounded-md font-francois"
                                    onClick={handleOpenClick}
                                >
                                    Regenerate
                                </button>

                                <RegeneratePopup
                                    open={popupOpen}
                                    onClose={handleClosePopup}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex-full flex flex-col justify-start items-center w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 w-full">
                        <div className="flex flex-col h-auto w-full ">
                            <div className="flex h-full flex-col w-full gap-2">
                                <CustomerDetails customerData={emailData}/>
                                <FreightDetails shipments={shipmentData}/>
                            </div>
                        </div>

                        <div className="h-auto w-full">
                            <MaterialDetails materials={tableData}/>
                        </div>
                        <div className="h-auto bg-white p-2 border rounded-md w-full">
                            <div className="flex flex-row h-auto justify-start items-center w-[100%] mb-2">
                                <div className="flex flex-row w-[90%] ">
                                    <div className="text-black text-sm font-black tracking-normal font-orbitall outline-none">
                                        <p className="text-[#3A3541DE] text-sm font-francois font-medium tracking-wide break-words">Decisions</p>
                                    </div>
                                </div>
                            </div>
                        <MaterialsDecisionsAutomated materials={tableData}/>
                        </div>
                    </div>

                    {/*<div className="flex flex-col h-full">
                        <div className="flex items-center bg-[#264B6A] p-2">
                            <h5 className="text-white flex-grow text-center">Vendor Details</h5>
                            <button
                                className="ml-auto text-white"
                                onClick={handleOpenAddVendorPopup}
                            >
                                <PersonAddIcon/>
                            </button>
                        </div>
                        <div className="m-4 p-4 bg-white rounded-lg">
                            <VendorDetails vendors={vendors}/>
                        </div>
                        <AddVendorPopup
                            open={isAddVendorPopupOpen}
                            onClose={handleCloseAddVendorPopup}
                            materials={filteredMaterials}
                        />
                    </div>*/}


                </div>
            )}
        </div>
    );
};

export default AutomationDetails;
