import React, {useState} from 'react';
import {Box, Button, Typography, IconButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MaterialTable from '../Tables/MaterialTable';
import DecisionsTable from "../Tables/DecisionsTable"; // Adjust the import path as needed

interface MaterialDetailsProps {
    materials: any;
}

const MaterialDetails: React.FC<MaterialDetailsProps> = ({materials}) => {
    const [currentPage, setCurrentPage] = React.useState(0);
    const itemsPerPage = 1; // Adjust this as needed

    // Get the total number of materials
    const totalMaterials = materials.length; // Get total number of materials
    const totalPages = Math.ceil(totalMaterials / itemsPerPage); // Calculate total pages

    // Get the current material based on currentPage index
    const currentMaterial = materials[currentPage]; // Get current material directly from array

    // Handlers for pagination
    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className={`w-full h-full border flex-col justify-start items-start flex overflow-y-auto rounded-md bg-white-50 `}>
            <div className="self-stretch h-full border flex-col justify-start items-start gap-1 flex cursor-pointer pt-1 pb-2 px-3 rounded-md border-gray-300 bg-white">
                <div className="flex flex-row h-auto justify-start items-center w-full">
                    <div className="flex flex-row w-[90%]">
                        <div className="text-black text-sm font-black tracking-normal font-orbitall outline-none">
                            <p className="text-[#3A3541DE] text-sm font-francois font-medium tracking-wide break-words">Materials</p>
                        </div>
                    </div>
                </div>

                {/* Central  Boxes with Tables */}
                <div className="w-full h-full flex-grow">
                        {currentMaterial ? (
                            <MaterialTable
                                key={currentMaterial.id} // Use material id as the unique key
                                material={currentMaterial}
                            />
                        ) : (
                            <div>No materials available for this status.</div>
                        )}
                </div>

                {/* Footer Section */}
                <div className="flex flex-row items-center w-full pt-2 justify-between">
                    {/* Pagination Controls */}
                    <div className="flex items-center">
                        <button
                            onClick={handlePrevious}
                            className={`mx-2 disabled:opacity-50 disabled:cursor-not-allowed ${currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""}`}
                            disabled={currentPage === 0}
                        >
                            <ArrowBackIcon className="text-[40px]" />
                        </button>
                        <div>
                            <p className="text-base">
                                Page {currentPage + 1} of {totalPages}
                            </p>
                        </div>
                        <button onClick={handleNext} disabled={currentPage === totalPages - 1}>
                            <ArrowForwardIcon className="text-[40px]" />
                        </button>
                    </div>

                    {/* Add Material Button */}
                    <div>
                        <button className="bg-[#264B6A] text-white px-4 py-2 rounded-md">
                            Add Material
                        </button>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default MaterialDetails;
