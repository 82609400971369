// UpdatesPopup.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CategoryIcon from '@mui/icons-material/Category';
import StraightenIcon from '@mui/icons-material/Straighten';
import SpeedIcon from '@mui/icons-material/Speed';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ConstructionIcon from '@mui/icons-material/Construction';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import UpdateIcon from '@mui/icons-material/Update';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
interface UpdatesPopupProps {
    open: boolean;
    onClose: () => void;
}

const UpdatesPopup: React.FC<UpdatesPopupProps> = ({ open, onClose }) => (
    <Dialog
        open={open}
        onClose={onClose}
        sx={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '& .MuiPaper-root': {
            minWidth: '900px',
            borderRadius: '0.75rem',
            padding: '1.25rem',
        },
    }}>
        <div className={`p-6 border-b border-gray-200`}>
            <div className={`flex justify-between items-center`}>
                <div className={`flex items-center space-x-3`}>
                    <InfoIcon sx={{color:"blue"}}/> <h2 className="text-2xl font-bold text-gray-800">User Dashboard Guidelines &amp; Features</h2>
                </div>
                <CloseIcon onClick={onClose}/>
            </div>
        </div>
        <div className="p-6 space-y-8 max-h-[70vh] overflow-y-auto">
            <div>
                <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center gap-2"> <CheckCircleOutlineIcon sx={{color:'green'}}/> Current Capabilities</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors">
                        <div className="flex items-start gap-3">
                            <CategoryIcon sx={{color:"blue"}}/>
                            <div className={`text-left`}>
                                <h4 className="font-semibold text-gray-800 mb-2">Supported Shapes</h4>
                                <ul className="space-y-2">
                                    <li className="text-gray-600 text-sm">Sheets, Rods, and some Tube cases are handled well.</li>
                                    <li className="text-gray-600 text-sm">For Tubes, enter dimensions in the format: Example: 5 inch OD, 4.5 inch ID</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors">
                        <div className="flex items-start gap-3">
                            <StraightenIcon sx={{color:"blue"}}/>
                            <div className={`text-left`}>
                                <h4 className="font-semibold text-gray-800 mb-2">Entering Dimensions</h4>
                                <ul className="space-y-2">
                                    <li className="text-gray-600 text-sm">Always enter numbers followed by units (e.g., inch, feet, mm, cm, yard).</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors">
                        <div className="flex items-start gap-3">
                            <SpeedIcon sx={{color:"blue"}}/>
                            <div className={`text-left`}>
                                <h4 className="font-semibold text-gray-800 mb-2">Processing Large Quotes</h4>
                                <ul className="space-y-2">
                                    <li className="text-gray-600 text-sm">For quotes with more than 10 materials, opt for Manual Processing instead of the Automated Flow.</li>
                                    <li className="text-gray-600 text-sm">If the automated process takes longer, wait a few minutes and refresh the page.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center gap-2">
                   <FileUploadIcon sx={{color:'blue'}}/> Upcoming Features – November Release</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <ConstructionIcon sx={{color:'blue'}}/> <span className="text-gray-800">Support for Coil Materials</span>
                        </div>
                    </div>
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <ConstructionIcon sx={{color:'blue'}}/> <span className="text-gray-800">Handling Angle-Shaped Materials</span>
                        </div>
                    </div>
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <AutoModeIcon sx={{color:'blue'}}/> <span className="text-gray-800">Automated Cutting &amp; Cost Calculations for Rods</span>
                        </div>
                    </div>
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <FileUploadIcon sx={{color:'blue'}}/> <span className="text-gray-800">Adding More Materials to Automated Flows</span>
                        </div>
                    </div>
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <LocalPhoneIcon sx={{color:'blue'}}/> <span className="text-gray-800">Phone Quoting/Enter the RFQ Manually</span>
                        </div>
                    </div>
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <MailIcon sx={{color:'blue'}}/> <span className="text-gray-800">Improved Vendor Email Management</span>
                        </div>
                    </div>
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <MailIcon sx={{color:'blue'}}/><span className="text-gray-800">Enhanced Vendor Email Format</span>
                        </div>
                    </div>
                    <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors">
                        <div className="flex items-center gap-3">
                            <UpdateIcon sx={{color:'blue'}}/> <span className="text-gray-800">Real-time Status Updates in the Queue Pages</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-4 mt-6">
                <div className="flex items-start gap-3">
                    <InfoIcon sx={{color:'blue'}}/><p className="text-gray-600 text-sm">These updates are designed to streamline the quoting process further and expand material support. Stay tuned for new features coming soon!</p>
                </div>
            </div>
        </div>
    </Dialog>
);

export default UpdatesPopup;
