const queueData = [
    {
        image: '/images/metals.jpg',
        title: 'Metals',
        id: '0',

        emails: [
            {
                rfq: 'RFQ0193124564545125',
                industry: 'Metals',
                id: '0',
                sender: 'Santiago C',
                senderEmail: 'santiago.c@wise-sales.com',
                recipients: ['John Doe'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi John<br>
                I hope this email finds you well. I am reaching out on behalf of Kaiser Aluminum to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Aluminum Sheets<br>
                            Grade: 6061-T6<br>
                            Thickness: 4mm<br>
                            Quantity: 500 sheets<br>
                        
                    <br>
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Santiago Campo,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Kaiser Aluminum',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'123',
                        data: [
                            {label: 'Material Type', value: 'Aluminum'},
                            {label: 'Grade or Alloy', value: '6061-T6'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '4mm'},
                            {label: 'Length', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Width', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Natural'},
                            {label: 'Quantity', value: '500 sheets'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.004'},
                            {label: 'Quantity', value: '500'},
                            {label: 'Quantity Unit', value: 'SQFT'},
                            {label: 'ERP Code', value: 'ALU6061T60004'},
                            {label: 'Material Cost', value: '80'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: ' 200'},
                        ]
                    },
                ],
                customerResponse: `
                       Hello Santiago,<br><br>
                        Thank you for your ongoing support.<br><br>
                        Please be assured that I am making arrangements to secure the materials that are currently unavailable:<br>
                            - Stainless Steel (Grade 304, Bar, 25mm diameter, 3 meters length)<br>
                            - Brass (Grade 360, Rod, 20mm diameter, 2 meters length)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 18096 Idalyn Drive, Los Gatos, CA 95033<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,
                materials: ["Aluminum, Stainless Steel, Copper, Brass"],
                quotePrice: "$102394",
                automationStatus: "Sent"
            },
            {
                rfq: 'RFQ019314978563236',
                industry: 'Metals',
                id: '1',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Alice Johnson'],
                cc: ['John Doe'],
                subject: 'Adjustment Request for Metal Sheet Dimensions',
                content: `Hello Santiago,<br><br>

                I hope you’re doing well. I’m reaching out regarding the copper sheet I recently ordered. After reviewing the project specifications, I would like to confirm the order details to ensure everything meets my requirements.<br><br>
            
                Here are the specifics:<br><br>
            
                - Quantity: 1 sheet<br>
                - Material: Copper<br>
                - Alloy: C11000 (Electrolytic Tough Pitch Copper)<br>
                - Thickness: 1/4 inch<br>
                - Dimensions: 48 inches x 96 inches<br><br>
            
                Please let me know if you need any additional information or if there are any adjustments required.<br><br>
            
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                Wise Sales<br>
               `,
                date: '2024-03-16',
                destination: '18096 Idalyn Drive Los Gatos, CA 95033',
                company: 'Esmark Steel Group',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge:null ,
                                GrossCharge: null,
                                Markup: null,
                                margin: null,
                                margin_on_freight_cost: null,
                                lead_time: ""
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin:null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin: null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },
                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '',
                                totalCuttingCost: '',
                            }
                        ],
                        stockStatus: 'InStock',
                        id: '458923',
                        data: [
                            {label: 'Material Type', value: 'Copper'},
                            {label: 'Alloy', value: 'C11000 (Electrolytic Tough Pitch Copper)'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '1/4 inch'},
                            {label: 'Length', value: '96 inches'},
                            {label: 'Width', value: '48 inches'},
                            {label: 'Color/Finish', value: 'N/A'}, // Usually copper color
                            {label: 'Quantity', value: '1 sheet'},
                            {label: 'Unit of Measure', value: 'sheet'}, // Sheet
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.25 x 48 x 96'}, // Adjusted for 1/4 inch thickness
                            {label: 'Quantity', value: '1'},
                            {label: 'Quantity Unit', value: 'EA'},
                            {label: 'ERP Code', value: 'COPPER048x96'}, // Adjusted for copper
                            {label: 'Material Cost', value: '85'}, // Example cost
                            {label: 'Margin', value: '24'}, // Example margin
                            {label: 'Price', value: '167.4'}, // Example price
                        ]
                    },
                ],

                customerResponse: ` Hello Ryan,<br><br>

                I hope you’re doing well. I’m reaching out regarding the copper sheet I recently ordered. Could you please confirm the shipping address for this order?<br><br>

                Thank you!<br><br>

                Best,<br>
                Santiago Campo<br>
                Wise Sales
                `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock.\n\n` +
                    `As a reminder, the following materials are still currently unavailable:\n\n` +
                    `- Bronze\n` +
                    `- Zinc\n\n` +
                    `We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time.\n\n` +
                    `Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.\n\n` +
                    `Best regards,`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Bronze and Zinc materials might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,


                vendors: [''],
                vendorSubject: ['Subject": "Request for Quote on Zinc and Bronze Material,'],
                vendorResponse: ``,

                receivedQuotes: [
                    {
                        vendorName: 'MCAM',
                        response:
                            'Subject: RFQ 04/22/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for considering MCAM for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4600\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2500\n' +
                            '\n' +
                            'Lead Time: Zinc - 2 weeks, Bronze - 1 day\n' +
                            'Minimum prepaid freight policy: $1000\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'John\n' +
                            'MCAM'
                    },
                    {
                        vendorName: 'KING PLASTICS',
                        response:
                            'Subject: RFQ-04/15/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for reaching out to KING PLASTICS. Here is our quotation for Zinc and Bronze materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4550\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2600\n' +
                            '\n' +
                            'Lead Time: Zinc - 3 weeks, Bronze - 2 days\n' +
                            'Minimum prepaid freight policy: $1100\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Warm regards,\n' +
                            'Emma\n' +
                            'KING PLASTICS'
                    },
                    {
                        vendorName: 'ENSINGER',
                        response:
                            'Subject: RFQ-04/08/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for your inquiry. Here is our quote for the requested materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4700\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2550\n' +
                            '\n' +
                            'Lead Time: Zinc - 2-4 weeks, Bronze - 1-2 days\n' +
                            'Minimum prepaid freight policy: $1050\n' +
                            '\n' +
                            'We hope to establish a successful business relationship with you.\n' +
                            '\n' +
                            'Kind regards,\n' +
                            'Sophia\n' +
                            'ENSINGER'
                    }
                ],
                missingQuotes: [
                    {Material: "Bronze", Quantity: "180 Tubes"},
                    {Material: "Zinc", Quantity: "350 Sheets"}
                ],
                receivedQuotesCustomer:
                    `Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n\n` +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["Aluminum"],
                quotePrice: "$16658",
                automationStatus: "Clarification Pending"
            },
            {
                rfq: 'RFQ0193487985241',
                industry: 'Metals',
                id: '2',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Santiago Campo'],
                cc: ['John Doe'],
                subject: 'Copper requirement',
                content: `Hello Santiago,<br><br>

                I hope you’re doing well. I’m reaching out regarding the copper sheet I recently ordered. After reviewing the project specifications, I would like to confirm the order details to ensure everything meets my requirements.<br><br>
            
                Here are the specifics:<br><br>
            
                - Quantity: 1 sheet<br>
                - Material: Copper<br>
                - Alloy: C11000 (Electrolytic Tough Pitch Copper)<br>
                - Thickness: 1/4 inch<br>
                - Dimensions: 48 inches x 96 inches<br><br>
            
                Please let me know if you need any additional information or if there are any adjustments required.<br><br>
            
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                Wise Sales<br>
               `,

                date: '2024-03-16',
                destination: '18096 Idalyn Drive Los Gatos, CA 95033',
                company: 'Esmark Steel Group',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "COPPER",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '',
                                totalCuttingCost: '',
                            }
                        ],
                        stockStatus: 'InStock',
                        id: '458923',
                        data: [
                            {label: 'Material Type', value: 'Copper'},
                            {label: 'Alloy', value: 'C11000 (Electrolytic Tough Pitch Copper)'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '1/4 inch'},
                            {label: 'Length', value: '96 inches'},
                            {label: 'Width', value: '48 inches'},
                            {label: 'Color/Finish', value: 'N/A'}, // Usually copper color
                            {label: 'Quantity', value: '1 sheet'},
                            {label: 'Unit of Measure', value: 'sheet'}, // Sheet
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.25 x 48 x 96'}, // Adjusted for 1/4 inch thickness
                            {label: 'Quantity', value: '1'},
                            {label: 'Quantity Unit', value: 'EA'},
                            {label: 'ERP Code', value: 'COPPER048x96'}, // Adjusted for copper
                            {label: 'Material Cost', value: '85'}, // Example cost
                            {label: 'Margin', value: '24'}, // Example margin
                            {label: 'Price', value: '167.4'}, // Example price
                        ]
                    },
                ],

                customerResponse :`
                            Hello Santiago,<br><br>
                            Thank you for your ongoing support.<br><br>
                        
                            Here's the quote you requested.<br><br>
                            <div id='disable-edit'>
                                <div style='overflow-x:auto;'>
                            <table style='border-collapse: collapse; width: 100%;'>
                                <thead>
                                    <tr>
                                        <th style='border: 1px solid black;'>Material</th>
                                        <th style='border: 1px solid black;'>Dimensions</th>
                                        <th style='border: 1px solid black;'>Quantity</th>
                                        <th style='border: 1px solid black;'>Alloy</th>
                                        <th style='border: 1px solid black;'>Thickness</th>
                                        <th style='border: 1px solid black;'>Availability</th>
                                        <th style='border: 1px solid black;'>Unit Price</th>
                                        <th style='border: 1px solid black;'>Total Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style='border: 1px solid black;'>Copper</td>
                                        <td style='border: 1px solid black;'>48 inches x 96 inches</td>
                                        <td style='border: 1px solid black;'>1 sheet</td>
                                        <td style='border: 1px solid black;'>C11000</td>
                                        <td style='border: 1px solid black;'>1/4 inch</td>
                                        <td style='border: 1px solid black;'>In-Stock</td>
                                        <td style='border: 1px solid black;'>$80</td>
                                        <td style='border: 1px solid black;'>$40,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p style='margin: 20px 0 0 0;'>Freight:</p>
                        <div style='overflow-x:auto;'>
                            <table style='border-collapse: collapse; width: 100%;'>
                                <thead>
                                    <tr>
                                        <th style='border: 1px solid black;'>Materials</th>
                                        <th style='border: 1px solid black;'>Freight Price</th>
                                        <th style='border: 1px solid black;'>Lead Time</th>
                                        <th style='border: 1px solid black;'>Source</th>
                                        <th style='border: 1px solid black;'>Freight Carrier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style='border: 1px solid black;'>Copper Sheet</td>
                                        <td style='border: 1px solid black;'>$500</td>
                                        <td style='border: 1px solid black;'>1 - 2 days</td>
                                        <td style='border: 1px solid black;'>Austin</td>
                                        <td style='border: 1px solid black;'>XPO Logistics</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br>
                    Total Material Price: $40,000<br>
                    Taxes: $3,200<br>
                    Total Freight Price: $500<br>
                    Total Order Price: $43,700<br>
                    Shipping Location: 18096 Idalyn Drive, Los Gatos, CA 95033<br><br>
                    Do not hesitate to contact us if you need more assistance.<br>
                    Best regards,<br>
                    Ryan<br>
                    Wise Sales<br>
                `,
                clarificationReceived: `Hello Santiago,<br><br>

                Sorry about it, the address would be 18096 Idalyn Drive Los Gatos, CA 95033   <br><br>
                            
           
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                Wise Sales<br>
               `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock.\n\n` +
                    `As a reminder, the following materials are still currently unavailable:\n\n` +
                    `- Bronze\n` +
                    `- Zinc\n\n` +
                    `We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time.\n\n` +
                    `Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.\n\n` +
                    `Best regards,`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Bronze and Zinc materials might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,


                vendors: [''],
                vendorSubject: ['Subject": "Request for Quote on Zinc and Bronze Material,'],
                vendorResponse: ``,

                receivedQuotes: [
                    {
                        vendorName: 'MCAM',
                        response:
                            'Subject: RFQ 04/22/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for considering MCAM for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4600\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2500\n' +
                            '\n' +
                            'Lead Time: Zinc - 2 weeks, Bronze - 1 day\n' +
                            'Minimum prepaid freight policy: $1000\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'John\n' +
                            'MCAM'
                    },
                    {
                        vendorName: 'KING PLASTICS',
                        response:
                            'Subject: RFQ-04/15/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for reaching out to KING PLASTICS. Here is our quotation for Zinc and Bronze materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4550\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2600\n' +
                            '\n' +
                            'Lead Time: Zinc - 3 weeks, Bronze - 2 days\n' +
                            'Minimum prepaid freight policy: $1100\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Warm regards,\n' +
                            'Emma\n' +
                            'KING PLASTICS'
                    },
                    {
                        vendorName: 'ENSINGER',
                        response:
                            'Subject: RFQ-04/08/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for your inquiry. Here is our quote for the requested materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4700\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2550\n' +
                            '\n' +
                            'Lead Time: Zinc - 2-4 weeks, Bronze - 1-2 days\n' +
                            'Minimum prepaid freight policy: $1050\n' +
                            '\n' +
                            'We hope to establish a successful business relationship with you.\n' +
                            '\n' +
                            'Kind regards,\n' +
                            'Sophia\n' +
                            'ENSINGER'
                    }
                ],
                missingQuotes: [
                    {Material: "Bronze", Quantity: "180 Tubes"},
                    {Material: "Zinc", Quantity: "350 Sheets"}
                ],
                receivedQuotesCustomer:
                    `Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n\n` +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["Copper"],
                quotePrice: "$1000",
                automationStatus: "Clarification Received"
            },
            {
                rfq: 'RFQ019312457164',
                industry: 'Metals',
                id: '3',
                sender: 'Mateo M',
                senderEmail: 'mateo.m@wise-sales.com',
                recipients: ['Ryan A'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Ryan<br>
                I hope this email finds you well. I am reaching out on behalf of Alro to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Aluminum Sheets<br>
                            Grade: 6061-T6<br>
                            Thickness: 4mm<br>
                            Quantity: 500 sheets<br>
                            <br>
                                Stainless Steel Bars<br>
                                Type: 304<br>
                                Diameter: 25mm<br>
                                Length: 3 meters<br>
                                Quantity: 200 bars<br>
                                <br>
                                    Copper Coils<br>
                                    Grade: C11000<br>
                                    Thickness: 0.5mm<br>
                                    Quantity: 300 coils<br>
                                    <br>
                    Brass Rods<br>
                    Type: 360<br>
                    Diameter: 20mm<br>
                    Length: 2 meters<br>
                    Quantity: 150 rods<br>
                    <br>
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Mateo Maldonado,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Alro',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "2375 Sierra Ct",
                                    Address2: null,
                                    City: " Palo Alto",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "94303",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "ALUMINUM",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'123',
                        data: [
                            {label: 'Material Type', value: 'Aluminum'},
                            {label: 'Grade or Alloy', value: '6061-T6'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '4mm'},
                            {label: 'Length', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Width', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Natural'},
                            {label: 'Quantity', value: '500 sheets'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.004'},
                            {label: 'Quantity', value: '500'},
                            {label: 'Quantity Unit', value: 'SQFT'},
                            {label: 'ERP Code', value: 'ALU6061T60004'},
                            {label: 'Material Cost', value: '80'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: ' 200'},
                        ]
                    },
                    {
                        stockStatus: 'PartialStock',
                        id:'13648',
                        data: [
                            {label: 'Material Type', value: 'Stainless Steel'},
                            {label: 'Grade or Alloy', value: '304'},
                            {label: 'Shape/Form', value: 'Bar'},
                            {label: 'Diameter', value: '25mm'},
                            {label: 'Thickness', value: 'N/A'}, // Not applicable for bars
                            {label: 'Length', value: '3 meters'},
                            {label: 'Width', value: 'N/A'}, // Not applicable for bars
                            {label: 'Color/Finish', value: 'N/A'}, // Usually silver or grey
                            {label: 'Quantity', value: '200 bars'},
                            {label: 'Unit of Measure', value: 'pcs'}, // Pieces

                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.025x3'},
                            {label: 'Quantity', value: '100'},
                            {label: 'Quantity Unit', value: 'pcs'},
                            {label: 'ERP Code', value: 'STASTEEL3040025x3'},
                            {label: 'Material Cost', value: '90'},
                            {label: 'Margin', value: '22'},
                            {label: 'Price', value: '220'},
                        ]
                    },

                    {
                        stockStatus: 'OutOfStock',
                        id:'469741',
                        data: [

                            {label: 'Material Type', value: 'Brass'},
                            {label: 'Grade or Alloy', value: '360'},
                            {label: 'Shape/Form', value: 'Rod'},
                            {label: 'Diameter', value: '20mm'},
                            {label: 'Thickness', value: 'N/A'}, // Not applicable for rods
                            {label: 'Length', value: '2 meters'},
                            {label: 'Width', value: 'N/A'}, // Not applicable for rods
                            {label: 'Color/Finish', value: 'N/A'}, // Usually golden or yellow
                            {label: 'Quantity', value: '150 rods'},
                            {label: 'Unit of Measure', value: 'pcs'}, // Pieces
                            // Add more rows as needed

                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: ''},
                            {label: 'Dimension', value: '0.02x2'},
                            {label: 'Quantity', value: '0'},
                            {label: 'Quantity Unit', value: 'pcs'},
                            {label: 'ERP Code', value: 'BRASS360002x2'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '210'},
                        ]
                    },

                ],

                customerResponse: `
                       Hello Mateo,<br><br>
                        Thank you for your ongoing support.<br><br>
                        Please be assured that I am making arrangements to secure the materials that are currently unavailable:<br>
                            - Stainless Steel (Grade 304, Bar, 25mm diameter, 3 meters length)<br>
                            - Brass (Grade 360, Rod, 20mm diameter, 2 meters length)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock<br><br>
                    As a reminder, the following materials are still currently unavailable:<br><br>
                    - Brass<br><br>
                    -Stainless Steel<br><br>
                    We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time<br><br>
                    Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.<br><br>
                    Best regards`,

                customerFollowupResponse:

                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Brass material might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,

                receivedQuotes: [
                    {
                        vendorName: 'MCAM',
                        response:
                            'Subject: RFQ-04/22/2024' +
                            '\n\n' +
                            'Dear John,\n' +
                            '\n' +
                            'Thank you for reaching out to MCAM for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Material 1: Brass\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $5000\n' +
                            '\n' +
                            'Lead Time: 2 weeks\n' +
                            'Minimum prepaid freight policy: $1000\n' +
                            'Minimum Order Quantity (MOQ): 100 sheets\n' +
                            'Price brackets: $4000 (500 sheets), $4500 (1000 sheets)\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'John\n' +
                            'MCAM'
                    },
                    {
                        vendorName: 'KING PLASTICS',
                        response:
                            'Subject: RFQ-04/20/2024' +
                            '\n\n' +
                            'Dear John,\n' +
                            '\n' +
                            'Thank you for considering KING PLASTICS for your material procurement. Here is our quotation:\n' +
                            '\n' +
                            'Material 1: Brass\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4800\n' +
                            '\n' +
                            'Lead Time: 3 weeks\n' +
                            'Minimum prepaid freight policy: $1200\n' +
                            'Minimum Order Quantity (MOQ): 150 sheets\n' +
                            'Price brackets: $4600 (500 sheets), $4700 (1000 sheets)\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Warm regards,\n' +
                            'Emma\n' +
                            'KING PLASTICS'
                    },
                    {
                        vendorName: 'ENSINGER',
                        response:
                            'Subject: RFQ-04/18/2024' +
                            '\n\n' +
                            'Dear John,\n' +
                            '\n' +
                            'Thank you for your inquiry. Here is our quote:\n' +
                            '\n' +
                            'Material 1: Brass\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4900\n' +
                            '\n' +
                            'Lead Time: 2-4 weeks\n' +
                            'Minimum prepaid freight policy: $1100\n' +
                            'Minimum Order Quantity (MOQ): 200 sheets\n' +
                            'Price brackets: $4700 (500 sheets), $4800 (1000 sheets)\n' +
                            '\n' +
                            'We hope to establish a successful business relationship with you.\n' +
                            '\n' +
                            'Kind regards,\n' +
                            'Sophia\n' +
                            'ENSINGER'
                    }
                ],
                missingQuotes: [
                    {Material: "Brass", Quantity: "350 sheets"},
                ],
                receivedQuotesCustomer:
                    'Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n' +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["Aluminum, Stainless Steel, Brass"],
                quotePrice: "$102394",
                automationStatus: "Customer Response Pending"
            },
            {
                rfq: 'RFQ0193145762',
                industry: 'Metals',
                id: '4',
                sender: 'Juan A',
                senderEmail: 'juan.a@wise-sales.com',
                recipients: ['Santiago C'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Ryan<br>
                I hope this email finds you well. I am reaching out on behalf of Alro to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Aluminum Sheets<br>
                            Grade: 6061-T6<br>
                            Thickness: 4mm<br>
                            Quantity: 500 sheets<br>
                            <br>
                                Stainless Steel Bars<br>
                                Type: 304<br>
                                Diameter: 25mm<br>
                                Length: 3 meters<br>
                                Quantity: 200 bars<br>
                                <br>
                                    Copper Coils<br>
                                    Grade: C11000<br>
                                    Thickness: 0.5mm<br>
                                    Quantity: 300 coils<br>
                                    <br>
                    Brass Rods<br>
                    Type: 360<br>
                    Diameter: 20mm<br>
                    Length: 2 meters<br>
                    Quantity: 150 rods<br>
                    <br>
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Juan Arguelles,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Kaiser Aluminum',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        stockStatus: 'OutOfStock',
                        id:'469741',
                        data: [

                            {label: 'Material Type', value: 'Brass'},
                            {label: 'Grade or Alloy', value: '360'},
                            {label: 'Shape/Form', value: 'Rod'},
                            {label: 'Diameter', value: '20mm'},
                            {label: 'Thickness', value: 'N/A'}, // Not applicable for rods
                            {label: 'Length', value: '2 meters'},
                            {label: 'Width', value: 'N/A'}, // Not applicable for rods
                            {label: 'Color/Finish', value: 'N/A'}, // Usually golden or yellow
                            {label: 'Quantity', value: '150 rods'},
                            {label: 'Unit of Measure', value: 'pcs'}, // Pieces
                            // Add more rows as needed

                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: ''},
                            {label: 'Dimension', value: '0.02x2'},
                            {label: 'Quantity', value: '0'},
                            {label: 'Quantity Unit', value: 'pcs'},
                            {label: 'ERP Code', value: 'BRASS360002x2'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '210'},
                        ]
                    },

                ],

                customerResponse: `
                       Hello Ryan,<br><br>
                        Thank you for your ongoing support.<br><br>
                        Please be assured that I am making arrangements to secure the materials that are currently unavailable:<br>
                            - Stainless Steel (Grade 304, Bar, 25mm diameter, 3 meters length)<br>
                            - Brass (Grade 360, Rod, 20mm diameter, 2 meters length)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock<br><br>
                    As a reminder, the following materials are still currently unavailable:<br><br>
                    - Brass<br><br>
                    -Stainless Steel<br><br>
                    We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time<br><br>
                    Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.<br><br>
                    Best regards`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Brass material might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,

                receivedQuotes: [
                    {
                        vendorName: 'Alro',
                        response: `Subject: RFQ-04/22/2024<br><br>

                        Dear Ryan,<br><br>

                        Thank you for reaching out to Alro for your material needs. Here is our quote based on your requirements:<br><br>

                        Material 1: Brass<br>
                        Grade or Alloy: Zn 99.995%<br>
                        Shape/Form: Sheet<br>
                        Thickness: 2mm<br>
                        Quantity: 350 sheets<br>
                        Price: $5000<br><br>

                        We look forward to your response and are eager to do business with you.<br><br>

                        Best regards,<br><br>

                        John<br>
                        Alro `
                    },

                ],

                receivedQuotesTable: {
                    id:"1024",
                    data:
                        [
                            {label: 'Material Type', value: 'Brass'},
                            {label: 'Dimension', value: '0.02 x 2'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Quantity', value: '0'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '210'},
                            {label: 'Lead Time', value: '2 days'},
                            {label: 'Freight Cost', value: '20'},
                        ]
                },

                customerReviewResponse: `
                       Hello Ryan,<br><br>hope you are doing well.<br><br>
                        We have received details of the material from the vendor. Here are the detailed quotes:<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Specification</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Freight Cost</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Copper</td>
                                            <td style='border: 1px solid black;'>0.02 x 2</td>
                                            <td style='border: 1px solid black;'>150</td>
                                            <td style='border: 1px solid black;'></td>
                                            <td style='border: 1px solid black;'>Rod</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$200</td>
                                            <td style='border: 1px solid black;'>$20</td>
                                            <td style='border: 1px solid black;'>2 days</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                        <br>                   
                        Total Order Price: $220<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,


                missingQuotes: [
                    {Material: "Brass", Quantity: "350 sheets"},
                ],
                receivedQuotesCustomer:
                    'Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n' +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["Brass"],
                quotePrice: "$1124",
                automationStatus: "Received Quotes"
            },
            {
                rfq: 'RFQ045981',
                industry: 'Metals',
                id: '5',
                sender: 'Sabrina F',
                senderEmail: 'Sabrina F@wise-sales.com',
                recipients: ['Santiago C'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Santiago<br>
                I hope this email finds you well. I am reaching out on behalf of Alro to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Aluminum Sheets<br>
                            Grade: 6061-T6<br>
                            Thickness: 4mm<br>
                            Quantity: 500 sheets<br>
                            <br>
                              Stainless Steel Bars<br>
                               Type: 304<br>
                               Diameter: 25mm<br>
                               Length: 3 meters<br>
                               Quantity: 200 bars<br>
                            <br>
                            Copper Coils<br>
                            Grade: C11000<br>
                            Thickness: 0.5mm<br>
                            Quantity: 300 coils<br>
                    <br>
                 
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Sabrina,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Kaiser Aluminum',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        stockStatus: 'PartialStock',
                        id:'13648',
                        data: [
                            {label: 'Material Type', value: 'Stainless Steel'},
                            {label: 'Grade or Alloy', value: '304'},
                            {label: 'Shape/Form', value: 'Bar'},
                            {label: 'Diameter', value: '25mm'},
                            {label: 'Thickness', value: 'N/A'}, // Not applicable for bars
                            {label: 'Length', value: '3 meters'},
                            {label: 'Width', value: 'N/A'}, // Not applicable for bars
                            {label: 'Color/Finish', value: 'N/A'}, // Usually silver or grey
                            {label: 'Quantity', value: '200 bars'},
                            {label: 'Unit of Measure', value: 'pcs'}, // Pieces

                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.025x3'},
                            {label: 'Quantity', value: '100'},
                            {label: 'Quantity Unit', value: 'pcs'},
                            {label: 'ERP Code', value: 'STASTEEL3040025x3'},
                            {label: 'Material Cost', value: '90'},
                            {label: 'Margin', value: '22'},
                            {label: 'Price', value: '220'},
                        ]
                    },

                ],

                customerResponse: `
                       Hello Ryan,<br><br>
                        Thank you for your ongoing support.<br><br>
                        Please be assured that I am making arrangements to secure the materials that are currently unavailable:<br>
                            - Stainless Steel (Grade 304, Bar, 25mm diameter, 3 meters length)<br>
                            - Brass (Grade 360, Rod, 20mm diameter, 2 meters length)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 18096 Idalyn Drive, Los Gatos, CA 95033<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock<br><br>
                    As a reminder, the following materials are still currently unavailable:<br><br>
                    - Brass<br><br>
                    -Stainless Steel<br><br>
                    We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time<br><br>
                    Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.<br><br>
                    Best regards`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Brass material might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,

                receivedQuotes: [
                    {
                        vendorName: 'Alro',
                        response: `Subject: RFQ-04/22/2024<br><br>

                        Dear John,<br><br>

                        Thank you for reaching out to MCAM for your material needs. Here is our quote based on your requirements:<br><br>

                        Material 1: Brass<br>
                        Grade or Alloy: Zn 99.995%<br>
                        Shape/Form: Sheet<br>
                        Thickness: 2mm<br>
                        Quantity: 350 sheets<br>
                        Price: $5000<br><br>

                        We look forward to your response and are eager to do business with you.<br><br>

                        Best regards,<br><br>

                        John<br>
                        Alro `
                    },

                ],

                receivedQuotesTable: {
                    id:"1024",
                    data:
                        [
                            {label: 'Material Type', value: 'Brass'},
                            {label: 'Dimension', value: '0.02 x 2'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Quantity', value: '0'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '210'},
                            {label: 'Lead Time', value: '2 days'},
                            {label: 'Freight Cost', value: '20'},
                        ]
                },

                customerReviewResponse: `
                       Hello Ryan,<br><br>hope you are doing well.<br><br>
                        We have received details of the material from the vendor. Here are the detailed quotes:<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Specification</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Freight Cost</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Copper</td>
                                            <td style='border: 1px solid black;'>0.02 x 2</td>
                                            <td style='border: 1px solid black;'>150</td>
                                            <td style='border: 1px solid black;'></td>
                                            <td style='border: 1px solid black;'>Rod</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$200</td>
                                            <td style='border: 1px solid black;'>$20</td>
                                            <td style='border: 1px solid black;'>2 days</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                        <br>                   
                        Total Order Price: $220<br>
                        Shipping Location: 18096 Idalyn Drive, Los Gatos, CA 95033<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,


                missingQuotes: [
                    {
                        vendorName: "Alro",
                        Material: "Brass",
                        Quantity: "350 sheets",
                    },
                    {
                        vendorName: "Metals Supermarket",
                        Material: "Aluminum",
                        Quantity: "200 sheets",
                    },
                ],

                vendorFollowUp:`Hi, <br><br>

                I hope this email finds you well. <br><br>
                This is a follow-up regarding the quote we provided to you recently. <br><br>
                We wanted to confirm whether you have had a chance to review the quote and if you have any further questions or would like to proceed with the order.<br><br> 
                Your prompt response would be greatly appreciated.`,

                receivedQuotesCustomer:
                    'Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n' +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["Stainless Steel"],
                quotePrice: "$2453",
                automationStatus: "Vendor Quote Pending"
            },
            {
                rfq: 'RFQ01931245125',
                industry: 'Metals',
                id: '6',
                sender: 'Santiago C',
                senderEmail: 'santiago.c@wise-sales.com',
                recipients: ['John Doe'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi John<br>
                I hope this email finds you well. I am reaching out on behalf of Kaiser Aluminum to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Aluminum Sheets<br>
                            Grade: 6061-T6<br>
                            Thickness: 4mm<br>
                            Quantity: 500 sheets<br>
                        
                    <br>
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Santiago Campo,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Kaiser Aluminum',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'123',
                        data: [
                            {label: 'Material Type', value: 'Aluminum'},
                            {label: 'Grade or Alloy', value: '6061-T6'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Diameter', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Thickness', value: '4mm'},
                            {label: 'Length', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Width', value: 'N/A'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Natural'},
                            {label: 'Quantity', value: '500 sheets'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.004'},
                            {label: 'Quantity', value: '500'},
                            {label: 'Quantity Unit', value: 'SQFT'},
                            {label: 'ERP Code', value: 'ALU6061T60004'},
                            {label: 'Material Cost', value: '80'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: ' 200'},
                        ]
                    },
                ],
                customerResponse: ` Hello John,<br><br>

                I hope you’re doing well. I’m reaching out regarding the copper sheet I recently ordered. Could you please confirm the shipping address for this order?<br><br>

                Thank you!<br><br>

                Best,<br>
                Santiago Campo<br>
                Wise Sales
                `,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,
                materials: ["Aluminum"],
                quotePrice: "$10125645",
                automationStatus: "Forwarded"
            },
        ]
    },
    {
        image: '/images/electronic.jpg',
        title: 'Electronic Components',
        id: '1',
        emails: [
            {
                rfq: 'RFQ015684',
                industry: 'Electronic Components',
                id: '0',
                sender: 'Mateo M',
                senderEmail: 'mateo.m@wise-sales.com',
                recipients: ['Ryan Alu'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Electronic Components',
                content: `Hi Ryan<br>
                 I hope this email finds you well. I am reaching out on behalf of WESCO to request a quote for various electronic components. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Components' services.<br><br>
                    Components Required:<br><br>
                    Microcontrollers<br><br>
                    Type: ATmega328P<br>
                    Quantity: 1000 units<br><br>
                    Capacitors<br><br>
                    Type: Ceramic<br>
                    Value: 10uF<br>
                    Quantity: 2000 units<br><br>
                    Resistors<br><br>
                    Type: 1/4W Carbon Film<br>
                    Value: 220 Ohm<br>
                    Quantity: 5000 units<br><br>
                    If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1216 or via email.<br><br>
                    Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                    Warm regards,<br>
                Mateo Maldonado,<br>               
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,

                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-04-23',
                company: 'WESCO',

                tableData: [
                    {
                        stockStatus: 'OutOfStock',
                        data: [
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Value', value: '200 Ohm'},
                            {label: 'Quantity', value: '5000 units'},
                            {label: 'Unit of Measure', value: 'Piece'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: ''},
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Quantity', value: '0'},
                            {label: 'ERP Code', value: 'ResistCarbon200'},
                            {label: 'Component Cost', value: '10'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]

                    },
                ],

                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "MicroATMega",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                    {
                        best_quote: {
                            CarrierName: "Estes Express Lines (LTL Standard Transit)",
                            CustomerPrice: {
                                NetPrice: 163.04,
                                FreightCharge: 118.64,
                                margin: 35,
                                margin_on_freight_cost: 250.83,
                                lead_time: "2 - 4 days"
                            },
                        },
                        all_quotes: [
                            {
                                CarrierName: "Estes Express Lines (LTL Standard Transit)",
                                CustomerPrice: {
                                    NetPrice: 163.04,
                                    FreightCharge: 118.64,
                                    margin: 35,
                                    margin_on_freight_cost: 250.83,
                                    lead_time: "2 - 4 days"
                                },
                            },
                            {
                                CarrierName: "Estes Express Lines (Guaranteed LTL Standard Transit: 5PM)",
                                CustomerPrice: {
                                    NetPrice: 213.04,
                                    FreightCharge: 168.97,
                                    margin: 35,
                                    margin_on_freight_cost: 327.75,
                                    lead_time: "2 - 4 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3562 South TK Ave.",
                                    Address2: "",
                                    City: "Boise",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "ID",
                                    Zipcode: "83705",
                                    LocationName: "Interstate Advanced Materials Boise"
                                },
                                CompanyName: "Interstate Advanced Materials Boise",
                            },
                            Consignee: {
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                                CompanyName: "Wise Sales",
                            },
                            Products: [
                                {
                                    ProductCode: "HDPBE  SH00187x020000x0096000",
                                    warehouse: "C1",
                                    Description: "CapCeramic10",
                                    Length: "96.000",
                                    Width: "20.000",
                                    Height: "0.187",
                                    Quantity: "20",
                                    UnitOfMeasure: "EA",
                                    PackageType: 29,
                                    Class: 3,
                                }
                            ],

                        },
                    },

                ],

                customerResponse: `
                       Hello Mateo Maldonado,<br><br>
                        Thank you for your inquiry regarding the electronic components.<br><br>
                        Please be assured that I am working on securing the components that are currently unavailable:<br>
                            - Capacitors (Type: Ceramic, Quantity: 5000 units)<br>
                            - Resistors (Type: 1/4W Carbon Film, Value: 220 Ohm, Quantity: 5000 units)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component Type</th>
                                            <th style='border: 1px solid black;'>Type</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Microcontrollers</td>
                                            <td style='border: 1px solid black;'>ATmega328P</td>
                                            <td style='border: 1px solid black;'>1000 units</td>
                                            <td style='border: 1px solid black;'>$2</td>
                                            <td style='border: 1px solid black;'>$2,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>
                                        <tr>
                                            <td style='border: 1px solid black;'>Capacitors</td>
                                            <td style='border: 1px solid black;'>Ceramic (10uF)</td>
                                            <td style='border: 1px solid black;'>5000 units</td>
                                            <td style='border: 1px solid black;'>$0.50</td>
                                            <td style='border: 1px solid black;'>$1,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Electronics Components</td>
                                            <td style='border: 1px solid black;'>$300</td>
                                            <td style='border: 1px solid black;'>1 - 3 days</td>
                                            <td style='border: 1px solid black;'>San Francisco</td>
                                            <td style='border: 1px solid black;'>FedEx</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $3,500<br>
                        Taxes: $280<br>
                        Total Freight Price: $300<br>
                        Total Order Price: $4,080<br>
                        Shipping Location: 2375 Sierra Ct, Palo Alto, CA 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,


                vendors: ['Mouser Electronics', 'Arrow', 'DigiKey'],
                vendorSubject: ['Subject": "Request for Quote on Resistors,'],
                vendorResponse:
                    `I hope this email finds you well.<br><br>
                 My name is Ryan, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>

                        Component: Resistors<br>
                        - Component Type:  1/4W Carbon Film<br>
                        - Value:200 Ohm<br>
                        - Quantity:5000 Units<br><br>

                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>

                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>

                        Looking forward to doing business with you.<br><br>

                        Best regards,<br>
                        Ryan
                        `,
                receivedQuotes: [
                    {
                        vendorName: 'Mouser Electronics',
                        response: `Subject: RFQ-04/22/2024<br><br>

                        Dear Ryan,<br><br>

                        Thank you for reaching out to Mouser for your needs. Here is our quote based on your requirements:<br><br>

                        Material 1: Resistors<br>
                        Type: 1/4W Carbon Film<br>
                        Value:200 Ohm<br>
                        Quantity:5000 Units<br>
                        Price: $5000<br><br>

                        We look forward to your response and are eager to do business with you.<br><br>

                        Best regards,<br><br>

                        John<br>
                        Mouser Electronics `
                    },
                ],

                receivedQuotesTable: {
                    id:"1024",
                    data:
                        [
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Quantity', value: '5000'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '5000'},
                            {label: 'Lead Time', value: '2 days'},
                            {label: 'Freight Cost', value: '20'},
                        ]
                },
                customerReviewResponse: `
                       Hello Mateo,<br><br>hope you are doing well.<br><br>
                        We have received details of the material from the vendor. Here are the detailed quotes:<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component</th>
                                            <th style='border: 1px solid black;'>Type</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Freight Cost</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Resistors</td>
                                            <td style='border: 1px solid black;'>1/4W Carbon Film</td>
                                            <td style='border: 1px solid black;'>5000</td>
                                            <td style='border: 1px solid black;'>5000</td>
                                            <td style='border: 1px solid black;'>5020</td>
                                            <td style='border: 1px solid black;'>$20</td>                                          
                                            <td style='border: 1px solid black;'>2 days</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                           
                        </div>
                        <br>                   
                        Total Order Price: $5020<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                       `,

                missingQuotes: [
                    {Material: "Resistors", Quantity: "5000 units"}
                ],
                materials: ["Resistors"],
                quotePrice: "12456",
                automationStatus: "Received Quotes"
            },
            {
                rfq: 'RFQ01667846124',
                industry: 'Electronic Components',
                id: '1',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Santiago Campo'],
                cc: ['John Doe', 'Jane Smith'],
                subject: 'Request for Quote on Electronic Components',
                content: `Hi Santiago,<br><br>
                I hope this email finds you well. I am reaching out on behalf of Suntronic to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br><br>
                Components Required:<br><br>
              
                Diodes<br>
                Type: 1N4148<br>
                Quantity: 1000 units<br><br>
                LED Displays<br>
                Type: 7-Segment<br>
                Quantity: 300 units<br><br>
                If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br><br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                Warm regards,<br>
                Ryan Alu,<br>`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-05-16',
                company: 'Suntronic',
                tableData: [
                    {
                        stockStatus: 'PartialStock',
                        data: [
                            { label: 'Component Type', value: 'Diodes' },
                            { label: 'Type', value: '1N4148' },
                            { label: 'Value', value: '' },
                            { label: 'Quantity', value: '1000' },
                            { label: 'Unit of Measure', value: 'units' }, // New label for Unit of Measure
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Type', value: '1N4148'},
                            {label: 'Quantity', value: '1000'},
                            {label: 'ERP Code', value: 'DIO1N41148'},
                            {label: 'Component Cost', value: '20'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'}
                        ]
                    },
                    {
                        stockStatus: 'OutOfStock',
                        data: [
                            { label: 'Component Type', value: 'LED Displays' },
                            { label: 'Type', value: '7-Segment' },
                            { label: 'Value', value: '' },
                            { label: 'Quantity', value: '300' },
                            { label: 'Unit of Measure', value: 'units' }, // New label for Unit of Measure
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Type', value: '7-Segment'},
                            {label: 'Quantity', value: '300'},
                            {label: 'ERP Code', value: 'LED7seg'},
                            {label: 'Component Cost', value: '2'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                ],
                customerResponse: `
                        Hello Ryan,<br><br>

                        I hope you’re doing well. I’m reaching out regarding the Led Displays and Diodes you recently ordered. Could you please confirm the shipping address for this order?<br><br>

                        Thank you!<br><br>

                        Best,<br>
                        Santiago Campo<br>
                        Wise Sales
                        `,

                customerFollowUp:
                    `We hope our previous message was helpful. As mentioned earlier, the materials listed below are currently out of stock:\n\n` +
                    `- Transistors\n` +
                    `- LED Displays\n\n` +
                    `Would you like us to proceed with the available materials in your order, or would you prefer to wait until all materials are in stock?\n\n` +
                    `Your preferences are important to us, and we want to ensure your order meets your needs. Please let us know how you would like to proceed.\n\n` +
                    `Thank you for your understanding and continued trust in our services.\n\n` +
                    `Best regards,`,

                customerFollowupResponse:
                    `Thank you for the follow-up. We understand the situation with the out-of-stock materials. Please go ahead and ship the available items in our order. We are willing to wait for the Transistors and LED Displays to be delivered once they are back in stock.\n\n` +
                    `If there are any significant delays, kindly keep us informed. We appreciate your continuous support and assistance.\n\n` +
                    `Best regards,`,


                vendors: ['Santiago', 'Ryan', 'Mateo'],
                vendorSubject: ['Subject": "Request for Quote on Transistors and LED Displays,'],

                missingQuotes: [
                    {Material: "LED Displays", Quantity: "300 units"},
                    {Material: "Transistors", Quantity: "800 units"}
                ],

                materials: ["LED Displays, Diodes"],
                quotePrice: "$45126",
                automationStatus: "Clarification Pending"
            },
            {
                rfq: 'RFQ0115486',
                industry: 'Electronic Components',
                id: '2',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Mateo M'],
                cc: ['John Doe', 'Jane Smith'],
                subject: 'Quote Needed for Electronic Components',
                content: `Hi Mateo Maldonado<br><br>
                    I hope this email finds you well. I am reaching out on behalf of WESCO to request a quote for various electronic components. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Components' services.<br><br>
                    Components Required:<br><br>
                    Microcontrollers<br><br>
                    Type: ATmega328P<br>
                    Quantity: 1000 units<br><br>
                   
                    If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1216 or via email.<br><br>
                    Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                    Warm regards,<br>
                    Mateo Maldonado,<br>
                   `,

                date: '2024-03-16',
                destination: '18096 Idalyn Drive Los Gatos, CA 95033',
                company: 'Mouser Electronics',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "MicroATMega",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },


                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        data: [
                            {label: 'Component Type', value: 'Microcontrollers'},
                            {label: 'Type', value: 'ATmega328P'},
                            {label: 'Value', value: ''},
                            {label: 'Quantity', value: '1000 units'},
                            {label: 'Unit of Measure', value: 'Pack'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Component Type', value: 'Microcontrollers'},
                            {label: 'Type', value: 'ATmega328P'},
                            {label: 'Quantity', value: '10000'},
                            {label: 'ERP Code', value: 'MicroATMega'},
                            {label: 'Component Cost', value: '2'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},

                        ]
                    },
                ],

                customerResponse: `
                       Hello Mateo Maldonado,<br><br>
                        Thank you for your inquiry regarding the electronic components.<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component Type</th>
                                            <th style='border: 1px solid black;'>Type</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Microcontrollers</td>
                                            <td style='border: 1px solid black;'>ATmega328P</td>
                                            <td style='border: 1px solid black;'>1000 units</td>
                                            <td style='border: 1px solid black;'>$2</td>
                                            <td style='border: 1px solid black;'>$2,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>                                                                           
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Microcontrollers</td>
                                            <td style='border: 1px solid black;'>$300</td>
                                            <td style='border: 1px solid black;'>1 - 3 days</td>
                                            <td style='border: 1px solid black;'>San Francisco</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $2000<br>
                        Taxes: $280<br>
                        Total Freight Price: $300<br>
                        Total Order Price: $2,300<br>
                        Shipping Location: 18096 Idalyn Drive Los Gatos, CA 95033<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,
                clarificationReceived: `Hello Santiago,<br><br>

                Sorry about it, the address would be 18096 Idalyn Drive Los Gatos, CA 95033   <br><br>
                            
           
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                Wise Sales<br>
               `,

                vendors: [''],
                vendorSubject: ['Subject": "Request for Quote on Zinc and Bronze Material,'],
                vendorResponse: ``,

                missingQuotes: [
                    {Material: "Bronze", Quantity: "180 Tubes"},
                    {Material: "Zinc", Quantity: "350 Sheets"}
                ],
                materials: ["Microcontrollers"],
                quotePrice: "$2450",
                automationStatus: "Clarification Received"
            },
            {
                rfq: 'RFQ0146854',
                industry: 'Electronic Components',
                id: '3',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Mateo M'],
                cc: ['John Doe', 'Jane Smith'],
                subject: 'Quote Needed for Electronic Components',
                content: `Hi Mateo Maldonado<br><br>
                    I hope this email finds you well. I am reaching out on behalf of Mouser Electronics to request a quote for various electronic components. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Components' services.<br><br>
                    Components Required:<br><br>
                   Capacitors<br><br>
                    Type: Ceramic<br>
                    Value: 10uF<br>
                    Quantity: 2000 units<br><br>
                   
                    If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1216 or via email.<br><br>
                    Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                    Warm regards,<br>
                    Mateo Maldonado,<br>
                   `,

                date: '2024-03-16',
                destination: '18096 Idalyn Drive Los Gatos, CA 95033',
                company: 'Mouser Electronics',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CapCeramic10",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },


                ],

                tableData: [
                    {
                        stockStatus: 'InStock',
                        data: [
                            {label: 'Component Type', value: 'Capacitors'},
                            {label: 'Type', value: 'Ceramic'},
                            {label: 'Value', value: '10uF'},
                            {label: 'Quantity', value: '2000 units'},
                            {label: 'Unit of Measure', value: 'Pack'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Component Type', value: 'Capacitors'},
                            {label: 'Type', value: 'Ceramic'},
                            {label: 'Quantity', value: '10000'},
                            {label: 'ERP Code', value: 'CapCeramic10'},
                            {label: 'Component Cost', value: '6'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                ],

                customerResponse: `
                       Hello Mateo Maldonado,<br><br>
                        Thank you for your inquiry regarding the electronic components.<br><br>
                        Please be assured that I am working on securing the components that are currently unavailable:<br>
                            - Capacitors (Type: Ceramic, Quantity: 5000 units)<br>
                            - Resistors (Type: 1/4W Carbon Film, Value: 220 Ohm, Quantity: 5000 units)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component Type</th>
                                            <th style='border: 1px solid black;'>Type</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Capacitors</td>
                                            <td style='border: 1px solid black;'>Ceramic</td>
                                            <td style='border: 1px solid black;'>10000 units</td>
                                            <td style='border: 1px solid black;'>$2,000</td>
                                            <td style='border: 1px solid black;'>$2,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>
                                       
                                      
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Capacitors</td>
                                            <td style='border: 1px solid black;'>$300</td>
                                            <td style='border: 1px solid black;'>1 - 3 days</td>
                                            <td style='border: 1px solid black;'>San Francisco</td>
                                            <td style='border: 1px solid black;'>FedEx</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $2,000<br>
                        Taxes: $280<br>
                        Total Freight Price: $300<br>
                        Total Order Price: $2,300<br>
                        Shipping Location: 18096 Idalyn Drive , Los Gatos, CA 95033, United States<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                customerFollowUp:
                    `I hope this email finds you well.<br><br>

                    This is a follow-up regarding the quote we provided to you recently.<br><br>
                     We wanted to confirm whether you have had a chance to review the quote and if you have any further questions or would like to proceed with the order.<br><br>

                    Your prompt response would be greatly appreciated.`,


                missingQuotes: [
                    {Material: "Bronze", Quantity: "180 Tubes"},
                    {Material: "Zinc", Quantity: "350 Sheets"}
                ],
                materials: ["Microcontrollers"],
                quotePrice: "$4120",
                automationStatus: "Customer Response Pending"
            },
            {
                rfq: 'RFQ0125656102',
                industry: 'Electronic Components',
                id: '4',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Mateo M'],
                cc: ['John Doe', 'Jane Smith'],
                subject: 'Quote Needed for Electronic Components',
                content: `Hi Mateo Maldonado<br><br>
                    I hope this email finds you well. I am reaching out on behalf of Mouser Electronics to request a quote for various electronic components. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Components' services.<br><br>
                    Components Required:<br><br>
                   Capacitors<br><br>
                    Type: Ceramic<br>
                    Value: 10uF<br>
                    Quantity: 2000 units<br><br>
                   
                    If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1216 or via email.<br><br>
                    Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                    Warm regards,<br>
                    Mateo Maldonado,<br>
                    18096 Idalyn Drive Los Gatos, CA 95033 `,

                date: '2024-03-16',
                destination: '18096 Idalyn Drive Los Gatos, CA 95033',
                company: 'Arrow',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CapCeramic10",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },


                ],

                tableData: [
                    {
                        stockStatus: 'OutOfStock',
                        data: [
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Value', value: '200 Ohm'},
                            {label: 'Quantity', value: '5000 units'},
                            {label: 'Unit of Measure', value: 'Piece'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: ''},
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Quantity', value: '0'},
                            {label: 'ERP Code', value: 'ResistCarbon200'},
                            {label: 'Component Cost', value: '10'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]

                    },
                ],


                missingQuotes: [
                    {
                        vendorName: "Mouser",
                        Material: "Resitors",
                        Quantity: "5000 Units",
                    },
                    {
                        vendorName: "Digikey",
                        Material: "Resistors",
                        Quantity: "5000 Units",
                    },
                ],

                vendors: ['Mouser Electronics', 'Arrow', 'DigiKey'],
                vendorSubject: ['Subject": "Request for Quote on Resistors,'],
                vendorFollowUp:`Hi, <br><br>

                I hope this email finds you well. <br><br>
                This is a follow-up regarding the quote we provided to you recently. <br><br>
                We wanted to confirm whether you have had a chance to review the quote and if you have any further questions or would like to proceed with the order.<br><br> 
                Your prompt response would be greatly appreciated.`,

                materials: ["Resistors"],
                quotePrice: "$6521",
                automationStatus: "Vendor Quote Pending"
            },
            {
                rfq: 'RFQ125425',
                industry: 'Electronic Components',
                id: '5',
                sender: 'Mateo M',
                senderEmail: 'mateo.m@wise-sales.com',
                recipients: ['Jane Smith'],
                cc: ['Alice Johnson, Ryan Alu'],
                subject: 'Quote Needed for Electronic Components',
                content: `Hi Jane Smith<br><br>
                    I hope this email finds you well. I am reaching out on behalf of DigiKey to request a quote for various electronic components. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Components' services.<br><br>
                    Components Required:<br><br>
                    Microcontrollers<br><br>
                    Type: ATmega328P<br>
                    Quantity: 1000 units<br><br>
                    Capacitors<br><br>
                    Type: Ceramic<br>
                    Value: 10uF<br>
                    Quantity: 2000 units<br><br>
                    Resistors<br><br>
                    Type: 1/4W Carbon Film<br>
                    Value: 220 Ohm<br>
                    Quantity: 5000 units<br><br>
                    If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1216 or via email.<br><br>
                    Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                    Warm regards,<br>
                    Mateo Maldonado,<br>
                    2375 Sierra Ct<br>
                    Palo Alto, CA, 94303`,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-04-23',
                company: 'DigiKey',
                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        data: [
                            {label: 'Component Type', value: 'Microcontrollers'},
                            {label: 'Type', value: 'ATmega328P'},
                            {label: 'Value', value: ''},
                            {label: 'Quantity', value: '1000 units'},
                            {label: 'Unit of Measure', value: 'Pack'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Component Type', value: 'Microcontrollers'},
                            {label: 'Type', value: 'ATmega328P'},
                            {label: 'Quantity', value: '10000'},
                            {label: 'ERP Code', value: 'MicroATMega'},
                            {label: 'Component Cost', value: '2'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},

                        ]
                    },
                    {
                        stockStatus: 'PartialStock',
                        data: [
                            {label: 'Component Type', value: 'Capacitors'},
                            {label: 'Type', value: 'Ceramic'},
                            {label: 'Value', value: '10uF'},
                            {label: 'Quantity', value: '2000 units'},
                            {label: 'Unit of Measure', value: 'Pack'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Component Type', value: 'Capacitors'},
                            {label: 'Type', value: 'Ceramic'},
                            {label: 'Quantity', value: '10000'},
                            {label: 'ERP Code', value: 'CapCeramic10'},
                            {label: 'Component Cost', value: '6'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                    {
                        stockStatus: 'OutOfStock',
                        data: [
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Value', value: '200 Ohm'},
                            {label: 'Quantity', value: '5000 units'},
                            {label: 'Unit of Measure', value: 'Piece'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: ''},
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Quantity', value: '0'},
                            {label: 'ERP Code', value: 'ResistCarbon200'},
                            {label: 'Component Cost', value: '10'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]

                    },
                    {
                        stockStatus: 'InStockAlternativeLocations',
                        data: [
                            {label: 'Component Type', value: 'Transistor'},
                            {label: 'Type', value: 'NPN'},
                            {label: 'Value', value: '2n222'},
                            {label: 'Quantity', value: '1000 units'},
                            {label: 'Unit of Measure', value: 'Piece'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Sister Location', value: 'Miami'},
                            {label: 'Component Type', value: 'Transistor'},
                            {label: 'Type', value: 'NPN'},
                            {label: 'Quantity', value: '1000'},
                            {label: 'ERP Code', value: 'T-2N2222'},
                            {label: 'Component Cost', value: '15'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                ],
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "MicroATMega",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                    {
                        best_quote: {
                            CarrierName: "Estes Express Lines (LTL Standard Transit)",
                            CustomerPrice: {
                                NetPrice: 163.04,
                                FreightCharge: 118.64,
                                margin: 35,
                                margin_on_freight_cost: 250.83,
                                lead_time: "2 - 4 days"
                            },
                        },
                        all_quotes: [
                            {
                                CarrierName: "Estes Express Lines (LTL Standard Transit)",
                                CustomerPrice: {
                                    NetPrice: 163.04,
                                    FreightCharge: 118.64,
                                    margin: 35,
                                    margin_on_freight_cost: 250.83,
                                    lead_time: "2 - 4 days"
                                },
                            },
                            {
                                CarrierName: "Estes Express Lines (Guaranteed LTL Standard Transit: 5PM)",
                                CustomerPrice: {
                                    NetPrice: 213.04,
                                    FreightCharge: 168.97,
                                    margin: 35,
                                    margin_on_freight_cost: 327.75,
                                    lead_time: "2 - 4 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3562 South TK Ave.",
                                    Address2: "",
                                    City: "Boise",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "ID",
                                    Zipcode: "83705",
                                    LocationName: "Interstate Advanced Materials Boise"
                                },
                                CompanyName: "Interstate Advanced Materials Boise",
                            },
                            Consignee: {
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                                CompanyName: "Wise Sales",
                            },
                            Products: [
                                {
                                    ProductCode: "HDPBE  SH00187x020000x0096000",
                                    warehouse: "C1",
                                    Description: "CapCeramic10",
                                    Length: "96.000",
                                    Width: "20.000",
                                    Height: "0.187",
                                    Quantity: "20",
                                    UnitOfMeasure: "EA",
                                    PackageType: 29,
                                    Class: 3,
                                }
                            ],

                        },
                    },

                ],
                customerResponse: `
                       Hello Mateo Maldonado,<br><br>
                        Thank you for your inquiry regarding the electronic components.<br><br>
                        Please be assured that I am working on securing the components that are currently unavailable:<br>
                            - Capacitors (Type: Ceramic, Quantity: 5000 units)<br>
                            - Resistors (Type: 1/4W Carbon Film, Value: 220 Ohm, Quantity: 5000 units)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component Type</th>
                                            <th style='border: 1px solid black;'>Type</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Microcontrollers</td>
                                            <td style='border: 1px solid black;'>ATmega328P</td>
                                            <td style='border: 1px solid black;'>1000 units</td>
                                            <td style='border: 1px solid black;'>$2</td>
                                            <td style='border: 1px solid black;'>$2,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>
                                        <tr>
                                            <td style='border: 1px solid black;'>Capacitors</td>
                                            <td style='border: 1px solid black;'>Ceramic (10uF)</td>
                                            <td style='border: 1px solid black;'>5000 units</td>
                                            <td style='border: 1px solid black;'>$0.50</td>
                                            <td style='border: 1px solid black;'>$1,000</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Electronics Components</td>
                                            <td style='border: 1px solid black;'>$300</td>
                                            <td style='border: 1px solid black;'>1 - 3 days</td>
                                            <td style='border: 1px solid black;'>San Francisco</td>
                                            <td style='border: 1px solid black;'>FedEx</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $3,500<br>
                        Taxes: $280<br>
                        Total Freight Price: $300<br>
                        Total Order Price: $4,080<br>
                        Shipping Location: 2375 Sierra Ct, Palo Alto, CA 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,
                materials: ["Resistors, Transistors, Capacitors, Microcontrollers"],
                quotePrice: "$15124",
                automationStatus: "Sent"

            },
            {
                rfq: 'RFQ015684675',
                industry: 'Electronic Components',
                id: '6',
                sender: 'Mateo M',
                senderEmail: 'mateo.m@wise-sales.com',
                recipients: ['Ryan Alu'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Electronic Components',
                content: `Hi Ryan<br>
                 I hope this email finds you well. I am reaching out on behalf of WESCO to request a quote for various electronic components. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Components' services.<br><br>
                 Components Required:<br><br>
                 Resistors<br><br>
                 Type: 1/4W Carbon Film<br>
                 Value: 220 Ohm<br>
                 Quantity: 5000 units<br><br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1216 or via email.<br><br>
                 Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br><br>
                 Warm regards,<br>
                Mateo Maldonado,<br>               
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`,

                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-04-23',
                company: 'WESCO',

                tableData: [
                    {
                        stockStatus: 'InStock',
                        data: [
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Value', value: '200 Ohm'},
                            {label: 'Quantity', value: '5000 units'},
                            {label: 'Unit of Measure', value: 'Piece'}, // Sheet

                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Miami'},
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Quantity', value: '0'},
                            {label: 'ERP Code', value: 'ResistCarbon200'},
                            {label: 'Component Cost', value: '10'},
                            {label: 'Margin', value: '20'},
                            {label: 'Price', value: '3000'},
                        ]
                    },
                ],

                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "MicroATMega",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                    {
                        best_quote: {
                            CarrierName: "Estes Express Lines (LTL Standard Transit)",
                            CustomerPrice: {
                                NetPrice: 163.04,
                                FreightCharge: 118.64,
                                margin: 35,
                                margin_on_freight_cost: 250.83,
                                lead_time: "2 - 4 days"
                            },
                        },
                        all_quotes: [
                            {
                                CarrierName: "Estes Express Lines (LTL Standard Transit)",
                                CustomerPrice: {
                                    NetPrice: 163.04,
                                    FreightCharge: 118.64,
                                    margin: 35,
                                    margin_on_freight_cost: 250.83,
                                    lead_time: "2 - 4 days"
                                },
                            },
                            {
                                CarrierName: "Estes Express Lines (Guaranteed LTL Standard Transit: 5PM)",
                                CustomerPrice: {
                                    NetPrice: 213.04,
                                    FreightCharge: 168.97,
                                    margin: 35,
                                    margin_on_freight_cost: 327.75,
                                    lead_time: "2 - 4 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3562 South TK Ave.",
                                    Address2: "",
                                    City: "Boise",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "ID",
                                    Zipcode: "83705",
                                    LocationName: "Interstate Advanced Materials Boise"
                                },
                                CompanyName: "Interstate Advanced Materials Boise",
                            },
                            Consignee: {
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                                CompanyName: "Wise Sales",
                            },
                            Products: [
                                {
                                    ProductCode: "HDPBE  SH00187x020000x0096000",
                                    warehouse: "C1",
                                    Description: "CapCeramic10",
                                    Length: "96.000",
                                    Width: "20.000",
                                    Height: "0.187",
                                    Quantity: "20",
                                    UnitOfMeasure: "EA",
                                    PackageType: 29,
                                    Class: 3,
                                }
                            ],

                        },
                    },

                ],

                customerResponse: `
                        Hello Ryan,<br><br>

                        I hope you’re doing well. I’m reaching out regarding the Led Displays and Diodes you recently ordered. Could you please confirm the shipping address for this order?<br><br>

                        Thank you!<br><br>

                        Best,<br>
                        Santiago Campo<br>
                        Wise Sales
                        `,


                vendors: ['Mouser Electronics', 'Arrow', 'DigiKey'],
                vendorSubject: ['Subject": "Request for Quote on Resistors,'],
                vendorResponse:
                    `I hope this email finds you well.<br><br>
                 My name is Ryan, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>

                        Component: Resistors<br>
                        - Component Type:  1/4W Carbon Film<br>
                        - Value:200 Ohm<br>
                        - Quantity:5000 Units<br><br>

                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>

                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>

                        Looking forward to doing business with you.<br><br>

                        Best regards,<br>
                        Ryan
                        `,
                receivedQuotes: [
                    {
                        vendorName: 'Mouser Electronics',
                        response: `Subject: RFQ-04/22/2024<br><br>

                        Dear Ryan,<br><br>

                        Thank you for reaching out to Mouser for your needs. Here is our quote based on your requirements:<br><br>

                        Material 1: Resistors<br>
                        Type: 1/4W Carbon Film<br>
                        Value:200 Ohm<br>
                        Quantity:5000 Units<br>
                        Price: $5000<br><br>

                        We look forward to your response and are eager to do business with you.<br><br>

                        Best regards,<br><br>

                        John<br>
                        Mouser Electronics `
                    },
                ],

                receivedQuotesTable: {
                    id:"1024",
                    data:
                        [
                            {label: 'Component Type', value: 'Resistors'},
                            {label: 'Type', value: '1/4W Carbon Film'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Quantity', value: '5000'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '5000'},
                            {label: 'Lead Time', value: '2 days'},
                            {label: 'Freight Cost', value: '20'},
                        ]
                },
                customerReviewResponse: `
                       Hello Mateo,<br><br>hope you are doing well.<br><br>
                        We have received details of the material from the vendor. Here are the detailed quotes:<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Component</th>
                                            <th style='border: 1px solid black;'>Type</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Freight Cost</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Resistors</td>
                                            <td style='border: 1px solid black;'>1/4W Carbon Film</td>
                                            <td style='border: 1px solid black;'>5000</td>
                                            <td style='border: 1px solid black;'>5000</td>
                                            <td style='border: 1px solid black;'>5020</td>
                                            <td style='border: 1px solid black;'>$20</td>                                          
                                            <td style='border: 1px solid black;'>2 days</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                           
                        </div>
                        <br>                   
                        Total Order Price: $5020<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                       `,

                missingQuotes: [
                    {Material: "Resistors", Quantity: "5000 units"}
                ],
                materials: ["Resistors"],
                quotePrice: "12456",
                automationStatus: "Forwarded"
            },
        ]
    },
    {
        image: '/images/metals.jpg',
        title: 'Plastics',
        id: '2',

        emails: [
            {
                rfq: 'RFQ01458812',
                industry: 'Plastics',
                id: '0',
                sender: 'Santiago C',
                senderEmail: 'santiago.c@wise-sales.com',
                recipients: ['John Doe'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Ryan<br>
                I hope this email finds you well. I am reaching out on behalf of Interstate Advance Materials to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Green HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                         
                                <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Black HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                    
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Santiago Campo,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Interstate Advance Materials',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "HDP52CA4F6C",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                    {
                        best_quote: {
                            CarrierName: "Estes Express Lines (LTL Standard Transit)",
                            CustomerPrice: {
                                NetPrice: 163.04,
                                FreightCharge: 118.64,
                                margin: 35,
                                margin_on_freight_cost: 250.83,
                                lead_time: "2 - 4 days"
                            },
                        },
                        all_quotes: [
                            {
                                CarrierName: "Estes Express Lines (LTL Standard Transit)",
                                CustomerPrice: {
                                    NetPrice: 163.04,
                                    FreightCharge: 118.64,
                                    margin: 35,
                                    margin_on_freight_cost: 250.83,
                                    lead_time: "2 - 4 days"
                                },
                            },
                            {
                                CarrierName: "Estes Express Lines (Guaranteed LTL Standard Transit: 5PM)",
                                CustomerPrice: {
                                    NetPrice: 213.04,
                                    FreightCharge: 168.97,
                                    margin: 35,
                                    margin_on_freight_cost: 327.75,
                                    lead_time: "2 - 4 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3562 South TK Ave.",
                                    Address2: "",
                                    City: "Boise",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "ID",
                                    Zipcode: "83705",
                                    LocationName: "Interstate Advanced Materials Boise"
                                },
                                CompanyName: "Interstate Advanced Materials Boise",
                            },
                            Consignee: {
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                                CompanyName: "Wise Sales",
                            },
                            Products: [
                                {
                                    ProductCode: "HDPBE  SH00187x020000x0096000",
                                    warehouse: "C1",
                                    Description: "HDP52CA4F6C",
                                    Length: "96.000",
                                    Width: "20.000",
                                    Height: "0.187",
                                    Quantity: "20",
                                    UnitOfMeasure: "EA",
                                    PackageType: 29,
                                    Class: 3,
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'123',
                        data: [
                            {label: 'Material Type', value: 'HDPE'},
                            {label: 'Specification', value: 'HDP64D8708C'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '2.0inch'},
                            {label: 'Length', value: '25.625 inch'}, // Not applicable for sheets
                            {label: 'Width', value: '3.5 inch'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Green'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '2.0 x 3.5 x 5.5'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Quantity Unit', value: 'Inches'},
                            {label: 'ERP Code', value: 'HDP52CA4F6C'},
                            {label: 'Material Cost', value: '80'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: ' 200'},
                        ]
                    },
                    {
                        stockStatus: 'PartialStock',
                        id:'13648',
                        data: [
                            {label: 'Material Type', value: 'HDPE'},
                            {label: 'Specification', value: 'HDP52CA4F6C'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '2.0inch'},
                            {label: 'Length', value: '3.5 inch'}, // Not applicable for sheets
                            {label: 'Width', value: '5.5 inch'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Black'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '2.0 x 3.5 x 5.5'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Quantity Unit', value: 'Inches'},
                            {label: 'ERP Code', value: 'HDP52CA4F632C'},
                            {label: 'Material Cost', value: '90'},
                            {label: 'Margin', value: '22'},
                            {label: 'Price', value: '220'},
                        ]
                    },

                ],
                customerResponse: `
                       Hello Santiago,<br><br>
                        Thank you for your ongoing support.<br><br>
                      
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>HDP52CA4F6C</td>
                                            <td style='border: 1px solid black;'>2.0 x 3.5 x 5.5</td>
                                            <td style='border: 1px solid black;'>2 </td>
                                            <td style='border: 1px solid black;'>Green</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$200</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>HDP52CA4F6C</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $200<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $3,900<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                vendors: ['Dow Chemical', 'Exxonmobil'],
                vendorSubject: ['Subject": "Request for Quote on HDPE Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is Ryan, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: HDP545635C<br>
                        - Material Type: HDPE<br>
                        - Specification: Black<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2.5inch<br>
                        - Length: 3.0 inch<br>
                        - Width: 5.5 inch<br>                  
                        - Quantity: 2<br>
                                             
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,
                materials: ["HDPE"],
                quotePrice: "$102394",
                automationStatus: "Sent"
            },
            {
                rfq: 'RFQ019314978563236',
                industry: 'Plastics',
                id: '1',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Alice Johnson'],
                cc: ['John Doe'],
                subject: 'Adjustment Request for Metal Sheet Dimensions',
                content: `Hello Santiago,<br>

                I hope this email finds you well. I am reaching out on behalf of Nexeo Plastics to request a quote for various plastics supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
            
                Here are the specifics:<br><br>
            
                - 1 sheet 1/4" 2x6 Polycarbonate<br><br>

                - 2 sheet 1/4" 4x8 Uhmw<br><br>
               
            
                Please let me know if you need any additional information or if there are any adjustments required.<br><br>
            
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                
               `,
                date: '2024-03-16',
                destination: '',
                company: 'Nexeo Plastics',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge:null ,
                                GrossCharge: null,
                                Markup: null,
                                margin: null,
                                margin_on_freight_cost: null,
                                lead_time: ""
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin:null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                            {
                                CarrierName: "",
                                CustomerPrice: {
                                    NetPrice: null,
                                    FreightCharge: null,
                                    margin: null,
                                    margin_on_freight_cost: null,
                                    lead_time: ""
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },
                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '',
                                totalCuttingCost: '',
                            }
                        ],
                        stockStatus: 'InStock',
                        id: '145862',
                        data: [
                            {label: 'Material Type', value: 'UHMW'},
                            {label: 'Specification', value: ''},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'Sheet'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '1/4 inch'},
                            {label: 'Length', value: '4 feet'}, // Not applicable for sheets
                            {label: 'Width', value: '8 feet'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'natural'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.250 x 48 x 96'},
                            {label: 'Quantity', value: '4'},
                            {label: 'Quantity Unit', value: 'EA'},
                            {label: 'ERP Code', value: 'UHMNV SH00250x048000x0096000'},
                            {label: 'Material Cost', value: '126'},
                            {label: 'Margin', value: '41'},
                            {label: 'Price', value: ' 213.56'},
                        ]
                    },
                    {
                        stockStatus: 'InStock',
                        id: '14586245',
                        data: [
                            {label: 'Material Type', value: 'polycarbonate'},
                            {label: 'Specification', value: ''},
                            {label: 'Quantity', value: '1'},
                            {label: 'Unit of Measure', value: 'Sheet'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '1/4 inch'},
                            {label: 'Length', value: '2 feet'}, // Not applicable for sheets
                            {label: 'Width', value: '6 feet'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Clear'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.220 x 48.000 x 96.000'},
                            {label: 'Quantity', value: '4'},
                            {label: 'Quantity Unit', value: 'EA'},
                            {label: 'ERP Code', value: 'POLCEF SH00220x048000x0096000'},
                            {label: 'Material Cost', value: '198'},
                            {label: 'Margin', value: '36'},
                            {label: 'Price', value: ' 310'},
                        ]
                    },
                ],


                customerResponse: `
                        Hello Ryan,<br><br>

                        I hope you’re doing well. I’m reaching out regarding the Polycarbonate and UHMW you recently ordered. Could you please confirm the shipping address for this order?<br><br>
                        
                        Thank you!<br><br>
                        
                        Best,<br>
                        Santiago Campo<br>
                        Wise Sales
                        `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock.\n\n` +
                    `As a reminder, the following materials are still currently unavailable:\n\n` +
                    `- Bronze\n` +
                    `- Zinc\n\n` +
                    `We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time.\n\n` +
                    `Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.\n\n` +
                    `Best regards,`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Bronze and Zinc materials might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,


                vendors: [''],
                vendorSubject: ['Subject": "Request for Quote on Zinc and Bronze Material,'],
                vendorResponse: ``,

                receivedQuotes: [
                    {
                        vendorName: 'MCAM',
                        response:
                            'Subject: RFQ 04/22/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for considering MCAM for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4600\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2500\n' +
                            '\n' +
                            'Lead Time: Zinc - 2 weeks, Bronze - 1 day\n' +
                            'Minimum prepaid freight policy: $1000\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'John\n' +
                            'MCAM'
                    },
                    {
                        vendorName: 'KING PLASTICS',
                        response:
                            'Subject: RFQ-04/15/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for reaching out to KING PLASTICS. Here is our quotation for Zinc and Bronze materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4550\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2600\n' +
                            '\n' +
                            'Lead Time: Zinc - 3 weeks, Bronze - 2 days\n' +
                            'Minimum prepaid freight policy: $1100\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Warm regards,\n' +
                            'Emma\n' +
                            'KING PLASTICS'
                    },
                    {
                        vendorName: 'ENSINGER',
                        response:
                            'Subject: RFQ-04/08/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for your inquiry. Here is our quote for the requested materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4700\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2550\n' +
                            '\n' +
                            'Lead Time: Zinc - 2-4 weeks, Bronze - 1-2 days\n' +
                            'Minimum prepaid freight policy: $1050\n' +
                            '\n' +
                            'We hope to establish a successful business relationship with you.\n' +
                            '\n' +
                            'Kind regards,\n' +
                            'Sophia\n' +
                            'ENSINGER'
                    }
                ],
                missingQuotes: [
                    {Material: "Bronze", Quantity: "180 Tubes"},
                    {Material: "Zinc", Quantity: "350 Sheets"}
                ],
                receivedQuotesCustomer:
                    `Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n\n` +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["UHMW, Polycarbonate"],
                quotePrice: "$16658",
                automationStatus: "Clarification Pending"
            },
            {
                rfq: 'RFQ0193487985241',
                industry: 'Plastics',
                id: '2',
                sender: 'Ryan Alu',
                senderEmail: 'ryan.a@wise-sales.com',
                recipients: ['Santiago Campo'],
                cc: ['John Doe'],
                subject: 'Request for Quote',
                content: `Hello Santiago,<br>

                I hope this email finds you well. I am reaching out on behalf of Nexeo Plastics to request a quote for various plastics supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
            
                Here are the specifics:<br><br>
            
                - 1 sheet 1/4" 2x6 Polycarbonate<br><br>

                - 2 sheet 1/4" 4x8 Uhmw<br><br>
               
            
                Please let me know if you need any additional information or if there are any adjustments required.<br><br>
            
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                
               `,

                date: '2024-03-16',
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                company: 'Nexeo Plastics',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "2375 Sierra Ct",
                                    Address2: null,
                                    City: "Palo Alto",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "94303",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "UHMW",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },


                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '',
                                totalCuttingCost: '',
                            }
                        ],
                        stockStatus: 'InStock',
                        id: '145862',
                        data: [
                            {label: 'Material Type', value: 'UHMW'},
                            {label: 'Specification', value: ''},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'Sheet'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '1/4 inch'},
                            {label: 'Length', value: '4 feet'}, // Not applicable for sheets
                            {label: 'Width', value: '8 feet'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'natural'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '0.250 x 48 x 96'},
                            {label: 'Quantity', value: '4'},
                            {label: 'Quantity Unit', value: 'EA'},
                            {label: 'ERP Code', value: 'UHMNV SH00250x048000x0096000'},
                            {label: 'Material Cost', value: '126'},
                            {label: 'Margin', value: '41'},
                            {label: 'Price', value: ' 213.56'},
                        ]
                    },
                ],

                customerResponse: `
                       Hello Santiago,<br><br>
                        Thank you for your ongoing support.<br><br>
                      
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>UHMW</td>
                                            <td style='border: 1px solid black;'>0.250 x 48 x 96</td>
                                            <td style='border: 1px solid black;'>4</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$213</td>
                                            <td style='border: 1px solid black;'>$1000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>UHMW</td>
                                            <td style='border: 1px solid black;'>$200</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $1000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $200<br>
                        Total Order Price: $4,400<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,
                clarificationReceived: `Hello Santiago,<br><br>

                Sorry about it, the address would be 2375 Sierra Ct Palo Alto, CA, 94303   <br><br>
                            
           
                I appreciate your assistance with this order. Thank you very much!<br><br>
            
                Best regards,<br><br>
            
                Ryan Alu<br>
                Wise Sales<br>
               `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock.\n\n` +
                    `As a reminder, the following materials are still currently unavailable:\n\n` +
                    `- Bronze\n` +
                    `- Zinc\n\n` +
                    `We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time.\n\n` +
                    `Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.\n\n` +
                    `Best regards,`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Bronze and Zinc materials might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,


                vendors: [''],
                vendorSubject: ['Subject": "Request for Quote on Zinc and Bronze Material,'],
                vendorResponse: ``,

                receivedQuotes: [
                    {
                        vendorName: 'MCAM',
                        response:
                            'Subject: RFQ 04/22/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for considering MCAM for your material needs. Here is our quote based on your requirements:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4600\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2500\n' +
                            '\n' +
                            'Lead Time: Zinc - 2 weeks, Bronze - 1 day\n' +
                            'Minimum prepaid freight policy: $1000\n' +
                            '\n' +
                            'We look forward to your response and are eager to do business with you.\n' +
                            '\n' +
                            'Best regards,\n' +
                            'John\n' +
                            'MCAM'
                    },
                    {
                        vendorName: 'KING PLASTICS',
                        response:
                            'Subject: RFQ-04/15/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for reaching out to KING PLASTICS. Here is our quotation for Zinc and Bronze materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4550\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2600\n' +
                            '\n' +
                            'Lead Time: Zinc - 3 weeks, Bronze - 2 days\n' +
                            'Minimum prepaid freight policy: $1100\n' +
                            '\n' +
                            'We are excited about the possibility of working together.\n' +
                            '\n' +
                            'Warm regards,\n' +
                            'Emma\n' +
                            'KING PLASTICS'
                    },
                    {
                        vendorName: 'ENSINGER',
                        response:
                            'Subject: RFQ-04/08/2024' +
                            '\n\n' +
                            'Dear Alicia,\n' +
                            '\n' +
                            'Thank you for your inquiry. Here is our quote for the requested materials:\n' +
                            '\n' +
                            'Material 1: Zinc\n' +
                            'Grade or Alloy: Zn 99.995%\n' +
                            'Shape/Form: Sheet\n' +
                            'Thickness: 2mm\n' +
                            'Quantity: 350 sheets\n' +
                            'Price: $4700\n' +
                            '\n' +
                            'Material 2: Bronze\n' +
                            'Grade or Alloy: C93200\n' +
                            'Shape/Form: Tube\n' +
                            'Diameter: 40mm\n' +
                            'Length: 3 meters\n' +
                            'Quantity: 180 tubes\n' +
                            'Price: $2550\n' +
                            '\n' +
                            'Lead Time: Zinc - 2-4 weeks, Bronze - 1-2 days\n' +
                            'Minimum prepaid freight policy: $1050\n' +
                            '\n' +
                            'We hope to establish a successful business relationship with you.\n' +
                            '\n' +
                            'Kind regards,\n' +
                            'Sophia\n' +
                            'ENSINGER'
                    }
                ],
                missingQuotes: [
                    {Material: "Bronze", Quantity: "180 Tubes"},
                    {Material: "Zinc", Quantity: "350 Sheets"}
                ],
                receivedQuotesCustomer:
                    `Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n\n` +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["UHMW"],
                quotePrice: "$1000",
                automationStatus: "Clarification Received"
            },
            {
                rfq: 'RFQ0144581325',
                industry: 'Plastics',
                id: '3',
                sender: 'Santiago C',
                senderEmail: 'santiago.c@wise-sales.com',
                recipients: ['John Doe'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Ryan<br>
                I hope this email finds you well. I am reaching out on behalf of Interstate Advance Materials to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Green HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                         
                                <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Black HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                    
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Santiago Campo,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Interstate Advance Materials',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                            {
                                CarrierName: "XPO Logistics Freight, Inc. - Guaranteed",
                                CustomerPrice: {
                                    NetPrice: 255.03,
                                    FreightCharge: 133.11,
                                    margin: 35,
                                    margin_on_freight_cost: 392.35,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "HDPE",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },
                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: false,
                                overallyield: '0',
                                totalCuttingCost: '0',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'123',
                        data: [
                            {label: 'Material Type', value: 'HDPE'},
                            {label: 'Specification', value: 'HDP64D8708C'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '2.0inch'},
                            {label: 'Length', value: '25.625 inch'}, // Not applicable for sheets
                            {label: 'Width', value: '3.5 inch'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Green'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '2.0 x 3.5 x 5.5'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Quantity Unit', value: 'Inches'},
                            {label: 'ERP Code', value: 'HDP52CA4F6C'},
                            {label: 'Material Cost', value: '80'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: ' 200'},
                        ]
                    },
                    {
                        stockStatus: 'OutOfStock',
                        id:'13648',
                        data: [
                            {label: 'Material Type', value: 'HDPE'},
                            {label: 'Specification', value: 'HDP52CA4F6C'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '2.0inch'},
                            {label: 'Length', value: '3.5 inch'}, // Not applicable for sheets
                            {label: 'Width', value: '5.5 inch'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Black'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '2.0 x 3.5 x 5.5'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Quantity Unit', value: 'Inches'},
                            {label: 'ERP Code', value: 'HDP52CA4F632C'},
                            {label: 'Material Cost', value: '90'},
                            {label: 'Margin', value: '22'},
                            {label: 'Price', value: '220'},
                        ]
                    },

                ],
                customerResponse: `
                       Hello Santiago,<br><br>
                        Thank you for your ongoing support.<br><br>
                      
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>HDP52CA4F6C</td>
                                            <td style='border: 1px solid black;'>2.0 x 3.5 x 5.5</td>
                                            <td style='border: 1px solid black;'>2 </td>
                                            <td style='border: 1px solid black;'>Green</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$200</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>HDP52CA4F6C</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $200<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $3,900<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                vendors: ['Dow Chemical', 'Exxonmobil'],
                vendorSubject: ['Subject": "Request for Quote on HDPE Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is Ryan, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: HDP545635C<br>
                        - Material Type: HDPE<br>
                        - Specification: Black<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2.5inch<br>
                        - Length: 3.0 inch<br>
                        - Width: 5.5 inch<br>                  
                        - Quantity: 2<br>
                                             
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,


                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock<br><br>
                    As a reminder, the following materials are still currently unavailable:<br><br>
                    - Black HDPE<br><br>
                    We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time<br><br>
                    Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.<br><br>
                    Best regards`,

                materials: ["HDPE"],
                quotePrice: "$102394",
                automationStatus: "Customer Response Pending"
            },
            {
                rfq: 'RFQ0193145762',
                industry: 'Plastics',
                id: '4',
                sender: 'Juan A',
                senderEmail: 'juan.a@wise-sales.com',
                recipients: ['Santiago C'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Santiago<br>
                I hope this email finds you well. I am reaching out on behalf of Interstate Advance Materials to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Green HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                         
                                <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Black HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                    
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Juan A,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Interstate Advance Materials',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        stockStatus: 'OutOfStock',
                        id:'13648',
                        data: [
                            {label: 'Material Type', value: 'HDPE'},
                            {label: 'Specification', value: 'HDP52CA4F6C'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '2.0inch'},
                            {label: 'Length', value: '3.5 inch'}, // Not applicable for sheets
                            {label: 'Width', value: '5.5 inch'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Black'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '2.0 x 3.5 x 5.5'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Quantity Unit', value: 'Inches'},
                            {label: 'ERP Code', value: 'HDP52CA4F632C'},
                            {label: 'Material Cost', value: '90'},
                            {label: 'Margin', value: '22'},
                            {label: 'Price', value: '220'},
                        ]
                    },

                ],

                customerResponse: `
                       Hello Ryan,<br><br>
                        Thank you for your ongoing support.<br><br>
                        Please be assured that I am making arrangements to secure the materials that are currently unavailable:<br>
                            - Stainless Steel (Grade 304, Bar, 25mm diameter, 3 meters length)<br>
                            - Brass (Grade 360, Rod, 20mm diameter, 2 meters length)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock<br><br>
                    As a reminder, the following materials are still currently unavailable:<br><br>
                    - Brass<br><br>
                    -Stainless Steel<br><br>
                    We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time<br><br>
                    Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.<br><br>
                    Best regards`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Brass material might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,

                receivedQuotes: [
                    {
                        vendorName: 'Dow',
                        response: `Subject: RFQ-04/22/2024<br><br>

                        Dear Juan,<br><br>

                        Thank you for reaching out to Dow for your material needs. Here is our quote based on your requirements:<br><br>

                        Material 1: HDPE<br>
                        Specification: Black<br>
                        Shape/Form: Sheet<br>
                        Dimensions: 2.0 x 3.5 x 5.5<br>
                        Quantity: 35 sheets<br>
                        Price: $500<br><br>

                        We look forward to your response and are eager to do business with you.<br><br>

                        Best regards,<br><br>

                        John<br>
                        Alro `
                    },

                ],

                receivedQuotesTable: {
                    id:"1024",
                    data:
                        [
                            {label: 'Material Type', value: 'HDPE'},
                            {label: 'Dimension', value: '2.0 x 3.5 x 5.5'},
                            {label: 'Material Cost', value: '$500'},
                            {label: 'Quantity', value: '0'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '$700'},
                            {label: 'Lead Time', value: '2 days'},
                            {label: 'Freight Cost', value: '20'},
                        ]
                },

                customerReviewResponse: `
                       Hello Ryan,<br><br>hope you are doing well.<br><br>
                        We have received details of the material from the vendor. Here are the detailed quotes:<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Specification</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Freight Cost</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>HDPE</td>
                                            <td style='border: 1px solid black;'>2.0 x 3.5 x 5.5</td>
                                            <td style='border: 1px solid black;'>35</td>
                                            <td style='border: 1px solid black;'>Black</td>
                                            <td style='border: 1px solid black;'>Sheet</td>
                                            <td style='border: 1px solid black;'>$15</td>
                                            <td style='border: 1px solid black;'>$525</td>
                                            <td style='border: 1px solid black;'>$20</td>
                                            <td style='border: 1px solid black;'>2 days</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                        <br>                   
                        Total Order Price: $545<br>
                        Shipping Location: 2375 Sierra Ct Palo Alto, CA, 94303<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,


                missingQuotes: [
                    {Material: "Brass", Quantity: "350 sheets"},
                ],
                receivedQuotesCustomer:
                    'Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n' +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["HDPE"],
                quotePrice: "$1124",
                automationStatus: "Received Quotes"
            },
            {
                rfq: 'RFQ045981',
                industry: 'Plastics',
                id: '5',
                sender: 'Sabrina F',
                senderEmail: 'Sabrina F@wise-sales.com',
                recipients: ['Santiago C'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Metal Supplies',
                content: `Hi Santiago<br>
                I hope this email finds you well. I am reaching out on behalf of Interstate Advance Materials to request a quote for various metal supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Materials Required:<br>
                        <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Green HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                         
                                <br>
                            Material ID: HDP52CA4F6C<br>
                            Description: 2 of Black HDPE<br>
                            Shape: Sheetbr>
                            Size: 2.0 x 3.5 x 5.5 (inches)<br>
                            Material Cost:<br>
                            Lead Time:<br>
                            Freight Cost:<br>
                    
                        We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Juan A,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 94303`
                ,
                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Interstate Advance Materials',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        stockStatus: 'OutOfStock',
                        id:'13648',
                        data: [
                            {label: 'Material Type', value: 'HDPE'},
                            {label: 'Specification', value: 'HDP52CA4F6C'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'SQFT'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '2.0inch'},
                            {label: 'Length', value: '3.5 inch'}, // Not applicable for sheets
                            {label: 'Width', value: '5.5 inch'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Black'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '2.0 x 3.5 x 5.5'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Quantity Unit', value: 'Inches'},
                            {label: 'ERP Code', value: 'HDP52CA4F632C'},
                            {label: 'Material Cost', value: '90'},
                            {label: 'Margin', value: '22'},
                            {label: 'Price', value: '220'},
                        ]
                    },

                ],

                customerResponse: `
                       Hello Ryan,<br><br>
                        Thank you for your ongoing support.<br><br>
                        Please be assured that I am making arrangements to secure the materials that are currently unavailable:<br>
                            - Stainless Steel (Grade 304, Bar, 25mm diameter, 3 meters length)<br>
                            - Brass (Grade 360, Rod, 20mm diameter, 2 meters length)<br><br>
                        Here's the quote you requested.<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Availability</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>4mm</td>
                                            <td style='border: 1px solid black;'>500 sheets</td>
                                            <td style='border: 1px solid black;'>Natural</td>
                                            <td style='border: 1px solid black;'>In-Stock</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$40,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style='margin: 20px 0 0 0;'>Freight:</p>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Materials</th>
                                            <th style='border: 1px solid black;'>Freight Price</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>
                                            <th style='border: 1px solid black;'>Source</th>
                                            <th style='border: 1px solid black;'>Freight Carrier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Aluminum 6061-T6 Sheet</td>
                                            <td style='border: 1px solid black;'>$500</td>
                                            <td style='border: 1px solid black;'>1 - 2 days</td>
                                            <td style='border: 1px solid black;'>Austin</td>
                                            <td style='border: 1px solid black;'>XPO Logistics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        Total Material Price: $40,000<br>
                        Taxes: $3,200<br>
                        Total Freight Price: $500<br>
                        Total Order Price: $43,700<br>
                        Shipping Location: 18096 Idalyn Drive, Los Gatos, CA 95033<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,

                customerFollowUp:
                    `We wanted to follow up regarding your recent order and provide an update on the materials that were previously out of stock<br><br>
                    As a reminder, the following materials are still currently unavailable:<br><br>
                    - Brass<br><br>
                    -Stainless Steel<br><br>
                    We are continuing our efforts to replenish these items and will notify you immediately once they are back in stock. Your understanding and patience are greatly appreciated during this time<br><br>
                    Please don't hesitate to reach out if you have any further questions or need assistance. We're here to help you.<br><br>
                    Best regards`,

                customerFollowupResponse:
                    `Thank you for your previous message. I wanted to follow up on the status of the out-of-stock materials.\n\n` +
                    `Could you please provide an update on when the Brass material might become available?\n\n` +
                    `By the way, the quoted material looked great. I appreciate the effort you've put into it.\n\n` +
                    `I appreciate your assistance and look forward to hearing from you soon.\n\n` +
                    `Best regards,`,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,

                receivedQuotes: [
                    {
                        vendorName: 'Alro',
                        response: `Subject: RFQ-04/22/2024<br><br>

                        Dear John,<br><br>

                        Thank you for reaching out to MCAM for your material needs. Here is our quote based on your requirements:<br><br>

                        Material 1: Brass<br>
                        Grade or Alloy: Zn 99.995%<br>
                        Shape/Form: Sheet<br>
                        Thickness: 2mm<br>
                        Quantity: 350 sheets<br>
                        Price: $5000<br><br>

                        We look forward to your response and are eager to do business with you.<br><br>

                        Best regards,<br><br>

                        John<br>
                        Alro `
                    },

                ],

                receivedQuotesTable: {
                    id:"1024",
                    data:
                        [
                            {label: 'Material Type', value: 'Brass'},
                            {label: 'Dimension', value: '0.02 x 2'},
                            {label: 'Material Cost', value: '85'},
                            {label: 'Quantity', value: '0'},
                            {label: 'Margin', value: '24'},
                            {label: 'Price', value: '210'},
                            {label: 'Lead Time', value: '2 days'},
                            {label: 'Freight Cost', value: '20'},
                        ]
                },

                customerReviewResponse: `
                       Hello Ryan,<br><br>hope you are doing well.<br><br>
                        We have received details of the material from the vendor. Here are the detailed quotes:<br><br>
                        <div id='disable-edit'>
                            <div style='overflow-x:auto;'>
                                <table style='border-collapse: collapse; width: 100%;'>
                                    <thead>
                                        <tr>
                                            <th style='border: 1px solid black;'>Material</th>
                                            <th style='border: 1px solid black;'>Dimensions</th>
                                            <th style='border: 1px solid black;'>Quantity</th>
                                            <th style='border: 1px solid black;'>Color</th>
                                            <th style='border: 1px solid black;'>Specification</th>
                                            <th style='border: 1px solid black;'>Unit Price</th>
                                            <th style='border: 1px solid black;'>Total Price</th>
                                            <th style='border: 1px solid black;'>Freight Cost</th>
                                            <th style='border: 1px solid black;'>Lead Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style='border: 1px solid black;'>Copper</td>
                                            <td style='border: 1px solid black;'>0.02 x 2</td>
                                            <td style='border: 1px solid black;'>150</td>
                                            <td style='border: 1px solid black;'></td>
                                            <td style='border: 1px solid black;'>Rod</td>
                                            <td style='border: 1px solid black;'>$80</td>
                                            <td style='border: 1px solid black;'>$200</td>
                                            <td style='border: 1px solid black;'>$20</td>
                                            <td style='border: 1px solid black;'>2 days</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                        <br>                   
                        Total Order Price: $220<br>
                        Shipping Location: 18096 Idalyn Drive, Los Gatos, CA 95033<br><br>
                        Do not hesitate to contact us if you need more assistance.<br>
                        `,


                missingQuotes: [
                    {
                        vendorName: "Dow",
                        Material: "HDPE",
                        Quantity: "2 sheets",
                    },
                    {
                        vendorName: "Exxon Mobil",
                        Material: "HDPE",
                        Quantity: "2 sheets",
                    },
                ],

                vendorFollowUp:`Hi, <br><br>

                I hope this email finds you well. <br><br>
                This is a follow-up regarding the quote we provided to you recently. <br><br>
                We wanted to confirm whether you have had a chance to review the quote and if you have any further questions or would like to proceed with the order.<br><br> 
                Your prompt response would be greatly appreciated.`,

                receivedQuotesCustomer:
                    'Thank you for choosing our services. We appreciate your trust in us and strive to meet your expectations.\n' +
                    `If you have any further questions or need assistance, feel free to contact us. We're here to help.\n\n` +
                    'Please find below a detailed breakdown of your order costs:' +
                    '\n' +
                    `Best regards,`,
                materials: ["HDPE"],
                quotePrice: "$2453",
                automationStatus: "Vendor Quote Pending"
            },
            {
                rfq: 'RFQ01931245125',
                industry: 'Plastics',
                id: '6',
                sender: 'Santiago C',
                senderEmail: 'santiago.c@wise-sales.com',
                recipients: ['John Doe'],
                cc: ['Alice Johnson'],
                subject: 'Quote Needed for Acrylic Supplies',
                content: `Hi Ryan<br>
                I hope this email finds you well. I am reaching out on behalf of Hanwool to request a quote for various plastics supplies. We are keen to establish a reliable supplier relationship and have heard excellent reviews about Wise-Sales' services.<br>
                    <br>
                    Please quote shipped to 4x24x48 Acrylic Cast Sheet Paper-Masked <br>
                    
                    
                    We aim to finalize our supplier selection by [specific date, e.g., "the end of this month"]. Your prompt response would greatly assist us in this decision-making process.<br>
                    <br>
                 If you have any questions or require further clarification on our requirements, please do not hesitate to contact me at +1 (420)-225-1215 or via email.<br>
                <br>
                Thank you for your attention to this matter. We look forward to receiving your competitive quote.<br>
                                <br>
                Santiago Campo,<br>
                Warm regards,<br>
                2375 Sierra Ct<br>
                Palo Alto, CA, 95033`,

                destination: '2375 Sierra Ct Palo Alto, CA, 94303',
                date: '2024-03-15',
                company: 'Hanwool',
                shipments: [
                    {
                        best_quote: {
                            CarrierName: "XPO Logistics",
                            CustomerPrice: {
                                NetPrice: 176.28,
                                FreightCharge: 134.31,
                                GrossCharge: 901.81,
                                Markup: 2.92,
                                margin: 35,
                                margin_on_freight_cost: 271.2,
                                lead_time: "1 - 2 days"
                            },
                        },

                        all_quotes: [
                            {
                                CarrierName: "XPO Logistics",
                                CustomerPrice: {
                                    NetPrice: 176.28,
                                    FreightCharge: 134.31,
                                    margin: 35,
                                    margin_on_freight_cost: 271.2,
                                    lead_time: "1 - 2 days"
                                },
                            },
                        ],

                        payload: {
                            Shipper: {
                                AddressInfo: {
                                    Address1: "3714 Bluestein Dr. Suite 790",
                                    Address2: "",
                                    City: "Austin",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "TX",
                                    Zipcode: "78721",
                                    LocationName: "Interstate Advanced Materials Austin"
                                },
                                CompanyName: "Interstate Advanced Materials Austin",
                                LocationName: "Interstate Advanced Materials Austin",

                            },
                            Consignee: {
                                ContactInfo: {
                                    FirstName: "Ryan",
                                    LastName: "Kimichick",
                                    ContactName: "Ryan Kimichick",
                                    Email: "santiago.c@interstateAM.com"
                                },
                                AddressInfo: {
                                    Address1: "18096 Idalyn Drive",
                                    Address2: null,
                                    City: "Los Gatos",
                                    CountryName: "United States",
                                    CountryCode: "US",
                                    State: "CA",
                                    Zipcode: "95033",
                                    LocationName: "Wise Sales"
                                },
                            },
                            Products: [
                                {
                                    ProductCode: "POLCE  SH00118x048000x0096000",
                                    warehouse: "D1",
                                    Description: "CLEAR POLYCARB SHEET",
                                    Length: "96.000",
                                    Width: "48.000",
                                    Height: "0.118",
                                    Quantity: "10",
                                    UnitOfMeasure: "EA",
                                }
                            ],

                        },
                    },

                ],

                tableData: [
                    {
                        cutting: [
                            {
                                statusCutting: true,
                                overallyield: '90',
                                totalCuttingCost: '80',
                            }
                        ],
                        stockStatus: 'InStock',
                        id:'123',
                        data: [
                            {label: 'Material Type', value: 'Acrylic'},
                            {label: 'Specification', value: 'ACR7688708C'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Unit of Measure', value: 'Inches'},
                            {label: 'Shape/Form', value: 'Sheet'},
                            {label: 'Thickness', value: '4inch'},
                            {label: 'Length', value: '24 inch'}, // Not applicable for sheets
                            {label: 'Width', value: '48 inch'}, // Not applicable for sheets
                            {label: 'Color/Finish', value: 'Clear'},
                            // Add more rows as needed
                        ],
                        decisionsTable: [
                            {label: 'Warehouse', value: 'Austin'},
                            {label: 'Dimension', value: '4.0 x 24 x 48'},
                            {label: 'Quantity', value: '2'},
                            {label: 'Quantity Unit', value: 'Inches'},
                            {label: 'ERP Code', value: 'ACR7688708C'},
                            {label: 'Material Cost', value: '80'},
                            {label: 'Margin', value: '25'},
                            {label: 'Price', value: ' 200'},
                        ]
                    },

                ],
                customerResponse: ` Hello John,<br><br>

                I hope you’re doing well. I’m reaching out regarding the Acrylic sheet I recently ordered. Could you please confirm the shipping address for this order?<br><br>

                Thank you!<br><br>

                Best,<br>
                Santiago Campo<br>
                Wise Sales
                `,

                vendors: ['Kloeckner Metals', 'Alro', 'Metal Supermarkets'],
                vendorSubject: ['Subject": "Request for Quote on Brass Material,'],
                vendorResponse: `
                        I hope this email finds you well. My name is John, and I'm reaching out to request a quote for the following materials we’re interested in procuring:<br><br>
                        
                        Material 1: Brass<br>
                        - Material Type: Brass<br>
                        - Grade or Alloy: Zn 99.995%<br>
                        - Shape/Form: Sheet<br>
                        - Thickness: 2mm<br>
                        - Quantity: 350 sheets<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Material 2: Stainless Steel<br>
                        - Material Type: Stainless Steel<br>
                        - Grade or Alloy: 304<br>
                        - Shape/Form: Bar<br>
                        - Diameter: 25mm<br>
                        - Thickness: N/A<br>
                        - Length: 3 meters<br>
                        - Quantity: 200 bars<br>
                        - Unit of Measure: pcs<br><br>
                        
                        Additional Information Required:<br>
                        - Lead Time:<br>
                        - Minimum prepaid freight policy:<br>
                        - Minimum Order Quantity (MOQ):<br>
                        - Price brackets:<br><br>
                        
                        We would greatly appreciate your prompt response to this request. Your information will assist us in making our procurement decisions.<br><br>
                        
                        Looking forward to doing business with you.<br><br>
                        
                        Best regards,<br>
                        Ryan
                        `,
                materials: ["Acrylic"],
                quotePrice: "$10125645",
                automationStatus: "Forwarded"
            },
        ]
    },
    // Add more industries and senderEmail data as needed
];

export default queueData;
