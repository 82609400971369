import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import CustomerDetails from "../../Customer/CustomerDetails";
import FreightDetails from "../../Freight/FreightDetails";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import VendorDetails from "../../Vendors/VendorDetails";
import AddVendorPopup from "../../Popups/AddVendorPopup";
import MaterialDetails from "../../Materials/MaterialDetails";
import MaterialsDecisions from "../../Materials/MaterialsDecisions";
import CustomerEmailPopup from "../../Popups/CustomerEmailPopup";
import VendorEmailPopup from "../../Popups/VendorEmailPopup";
import MaterialsDecisionsAutomated from "../../Materials/MaterialsDecisionsAutomated";
import RFQPopup from "../../Popups/RFQPopup";
import queueData from "../../DataContent/QueueData";
import CustomerEmail from '../../Customer/CustomerEmail';
import VendorEmails from '../../Vendors/VendorEmails';
import RFQEmail from "../../RFQ/RFQEmail";
import RegeneratePopup from "../../Popups/RegeneratePopup";
import CustomerDetailsQueue from "../../Customer/CustomerDetailsQueue";
import SuccessPopup from "../../Popups/SuccessPopup";
import CustomerFollowUpPopup from '../../Popups/CustomerFollowUpPopup';
import VendorReceivedQuotes from '../../Vendors/VendorReceivedQuotes';
import VendorPendingDetails from '../../Vendors/VendorPendingDetails';
import VendorEmailPending from '../../Vendors/VendorEmailPending';

const QueuePendingQuotes: React.FC = () => {

    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const [emailData, setEmailData] = useState<any[]>([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [successPopupOpen, successSetPopupOpen] = useState(false);


    const handleSend = () => {
        successSetPopupOpen(true); // Open the popup when "Send" is clicked
    };

    const handleConfirmSend = () => {
        // Keep the popup open for 3 seconds before navigating
        setTimeout(() => {
            successSetPopupOpen(false);
            navigate(`/industry/${id}/queue`); // Navigate to the queue
        }, 2000); // 3 seconds delay
    };
    const handleOpenClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        successSetPopupOpen(false);
    };

    // Use useParams to get the id and rfq from the URL
    const {id, rfq} = useParams<{ id: string; rfq: string }>();
    useEffect(() => {
        // Find the industry data matching the specific id
        const industry: any = queueData.find((item: any) => item.id === id);

        // Use the `any` type to filter emails based on the RFQ
        const filteredEmails = industry ? industry.emails.filter((email: any) => email.rfq === rfq) : [];

        // Set the filtered emails in the state
        setEmailData(filteredEmails);
    }, [id, rfq]);

    // Extract the tableData from the filteredEmails for the MaterialDetails
    const tableData = emailData.map((email: any) => email.tableData).flat(); // Flatten if there are multiple emails
    const shipmentData = emailData.map((email: any) => email.shipments).flat(); // Flatten if there are multiple emails


    const handleBackClick = () => {
        navigate(`/industry/${id}/queue`);
    };


    return (
        <div className={` col-span-3 w-full h-full flex flex-col items-center gap-1 overflow-y-auto  px-1 `}>
            <div className="flex flex-row items-center gap-2 py-2 w-full">
                {emailData.length > 0 && (
                    <div className="mt-1 mb-1 w-full pl-2 font-bold flex-[9] text-green-600">
                        {/* Display the automationStatus from emailData */}
                        {emailData.map((email: any, index: number) => (
                            <div key={index}>
                                {email.automationStatus ? (
                                    <span>{email.automationStatus}: Select a vendor quote to generate a new one for the customer</span>
                                ) : (
                                    <span>No Queue Status</span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <button
                    className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                    onClick={handleBackClick}
                >
                    Back
                </button>
                <button
                    className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                    onClick={handleSend}
                >
                    Vendor Follow Up
                </button>
                {/* SuccessPopup dialog */}
                <SuccessPopup
                    open={successPopupOpen}
                    onClose={handleClosePopup}
                    onConfirm={handleConfirmSend}
                />

            </div>

            <div className="flex-full flex flex-col justify-start items-center w-full">
                <div className="w-full flex flex-col justify-center items-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full"> {/* Change to grid-cols-3 for three columns */}
                        {/* First Column */}
                        <div className="flex flex-col h-auto w-full">
                            {/* Customer Details */}
                            <div className="flex flex-col h-auto overflow-y-auto border border-gray-200 rounded-md w-full mb-2"> {/* Added mb-2 for spacing */}
                                <CustomerDetails customerData={emailData} />
                            </div>

                            {/* Material Details */}
                            <div className="flex flex-col h-auto overflow-y-auto border border-gray-200 rounded-md w-full">
                                <MaterialDetails materials={tableData} />
                            </div>
                        </div>

                        {/* Second Column */}
                        <div className="flex flex-col h-full w-full ">
                            <div className="flex flex-col gap-2 overflow-y-auto border border-gray-200 rounded-md w-full">
                                <VendorPendingDetails
                                    emails={emailData}
                                />
                            </div>

                            <VendorEmailPending emails={emailData}/>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueuePendingQuotes;
