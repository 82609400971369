import React from 'react';


const ContactUs: React.FC = () => {
    return (
        <div className="p-6 bg-white rounded-lg h-full shadow-md mx-auto">
            <div className="md:flex md:justify-between space-y-4 md:space-y-0">
                <div className="md:w-1/2 space-y-4">
                    <div>
                        <h2 className="text-sm font-medium text-gray-600 font-francois">Company Name</h2>
                        <p className="text-base font-thin tracking-wide font-biryani">Wise Sales</p>
                    </div>
                    <div>
                        <h2 className="text-sm font-medium text-gray-600 font-francois">Address</h2>
                        <p className="text-base font-thin tracking-wide font-biryani">15619 Blackburn Ave, Norwalk, CA 90650</p>
                    </div>
                </div>
                <div className="md:w-1/2 space-y-4"><div>
                    <h2 className="text-sm font-medium text-gray-600 font-francois">Contact</h2>
                    <p className="text-base font-thin tracking-wide font-biryani">+1 1454542589</p>
                </div>
                    <div>
                        <h2 className="text-sm font-medium text-gray-600 font-francois">Email Id</h2>
                        <p className="text-base font-thin tracking-wide font-biryani">it.support@wise-sales.com</p>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <div className="w-full rounded-md flex justify-center items-center my-1 w-min">
                    <button id="sendMail" className="text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]">
                        <span className="overflow-hidden truncate">Send us a mail</span>
                    </button>
                </div>
            </div>
        </div>
    );
};


export default ContactUs;
