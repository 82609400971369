import React, {useState, ChangeEvent, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Box,
    IconButton,
    Paper,
    Select,
    MenuItem,
    SelectChangeEvent,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import EditableTableCell from "../Tables/EditableTableCell";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import '../Stylesheets/EditableTable.css'

interface FreightDetailsProps {
    shipments: any;
}

const FreightDetails: React.FC<FreightDetailsProps> = ({shipments}) => {
    const [selectedCarrier, setSelectedCarrier] = useState<string>('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [willCall, setWillCall] = useState(false); // New state for "Will Call" checkbox

    const itemsPerPage = 1;
    const totalItems = shipments.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    useEffect(() => {
        // Check if shipments is available and has the best_quote
        if (shipments.length > 0 && shipments[0]?.best_quote?.CarrierName) {
            setSelectedCarrier(shipments[0].best_quote.CarrierName);
        }
    }, [shipments]); // Only runs when shipments change

    
    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(prevPage => prevPage - 1);
            handlePageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(prevPage => prevPage + 1);
            handlePageChange(currentPage + 1);
        }
    };

    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => setIsEditing(false);
    const handleSave = () => setIsEditing(false);

    const handlePageChange = (index: number) => {
        if (index >= 0 && index < shipments.length) {
            setCurrentIndex(index);
            setSelectedCarrier(shipments[index]?.best_quote?.CarrierName || '');
        }
    };

    const handleChangeCarrier = (event: SelectChangeEvent<string>) => {
        const newCarrier = event.target.value as string;
        setSelectedCarrier(newCarrier);

        // Update the selected carrier's details
        const updatedShipments = [...shipments];
        updatedShipments[currentIndex] = {
            ...updatedShipments[currentIndex],
            best_quote: {
                ...updatedShipments[currentIndex].best_quote,
                CarrierName: newCarrier,
                // You may want to also update other fields related to the new carrier if necessary
            },
            all_quotes: updatedShipments[currentIndex].all_quotes.map((quote: { CarrierName: string; }) =>
                quote.CarrierName === newCarrier
                    ? {...quote, CarrierName: newCarrier}
                    : quote
            ),
        };

        // Example of updating state with new shipments data
        // setShipments(updatedShipments);
    };


    const getCarrierOptions = () => {
        const currentShipment = shipments[currentIndex];
        const carriers = new Set<string>();
        currentShipment?.all_quotes?.forEach((quote: { CarrierName: string; }) => {
            if (quote.CarrierName) {
                carriers.add(quote.CarrierName);
            }
        });
        return Array.from(carriers);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // This function now just allows input changes without updating any state
        // For now, it does nothing with the input values
    };


    // Get current shipment data
    const data = shipments[currentIndex];

    // Get values for selected carrier
    const selectedQuote = (data?.all_quotes || []).find((quote: { CarrierName: string }) => quote.CarrierName === selectedCarrier);

    const baseFreight = selectedQuote?.CustomerPrice?.FreightCharge || '';
    const leadTime = selectedQuote?.CustomerPrice?.lead_time || '';
    const marginOnFreight = selectedQuote?.CustomerPrice?.margin || '';
    const freightCost = selectedQuote?.CustomerPrice?.margin_on_freight_cost || '';

    return (

        <div className="w-full">
            <div
                className={`w-full h-auto border flex-col justify-start items-start flex overflow-y-auto rounded-md bg-white-50`}>
                <div
                    className="self-stretch h-full border flex-col justify-start items-start gap-1 flex cursor-pointer pt-1 pb-2 px-3 rounded-md border-gray-300 bg-white">

                    <div className="flex flex-row h-auto justify-start items-center w-[100%] mb-2">
                        <div className="flex flex-row w-[90%] ">
                            <div className="text-black text-sm font-black tracking-normal font-orbitall outline-none">
                                <p className="text-[#3A3541DE] text-sm font-francois font-medium tracking-wide break-words">Freight
                                    Details</p>
                            </div>
                        </div>
                    </div>

                            <Box sx={{display: 'flex', alignItems: 'center', width: '100%', padding: 0}}>
                                <IconButton onClick={handlePrevious} sx={{margin: 1}} disabled={currentPage === 0}>
                                    <ArrowBackIcon sx={{fontSize: '20px'}}/>
                                </IconButton>
                                <Box sx={{ flex: 1, textAlign: 'center' }}>
                                    {(!data?.payload?.Shipper?.AddressInfo?.Address1 && !data?.payload?.Consignee?.AddressInfo?.Address1) ? (
                                        <Typography variant="body1" sx={{ color: 'red' }}>
                                            Freight information unavailable
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">
                                            {data?.payload?.Products?.[0]?.Description || ''}
                                        </Typography>
                                    )}
                                </Box>
                                <IconButton onClick={handleNext} sx={{margin: 1}}
                                            disabled={currentPage === totalPages - 1}>
                                    <ArrowForwardIcon sx={{fontSize: '20px'}}/>
                                </IconButton>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <IconButton onClick={isEditing ? handleCancel : handleEdit} color="primary">
                                        {isEditing ? <CloseIcon/> : <EditIcon/>}
                                    </IconButton>
                                    {isEditing && (
                                        <IconButton onClick={handleSave} color="primary">
                                            <CheckIcon/>
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                            <table className="min-w-full divide-y divide-gray-200 h-[80%]">
                                {/* Source */}
                                <tr className="h-auto">
                                    <td className="px-1 w-[50%] py-2  bg-white-50 border border-gray-300">
                                        <p className="text-xs text-gray-700 text-left font-normal font-orbitron tracking-wiall break-all">
                                            Source
                                        </p>
                                    </td>
                                    <td className="px-1 w-[50%] border border-gray-300 rounded-tr-md">
                                        <div className={`flex max-h-[120px] overflow-auto flex-col p-1 px-0 ${!data?.payload?.Shipper?.AddressInfo?.Address1 ? 'border border-red-500' : ''}`}>
                                            <EditableTableCell
                                                name="source"
                                                value={!data?.payload?.Shipper?.AddressInfo?.Address1 ? '' : `${data?.payload?.Shipper?.AddressInfo?.Address1} ${data?.payload?.Shipper?.AddressInfo?.Address2 || ''}, ${data?.payload?.Shipper?.AddressInfo?.City || ''}, ${data?.payload?.Shipper?.AddressInfo?.State || ''} ${data?.payload?.Shipper?.AddressInfo?.Zipcode || ''}, ${data?.payload?.Shipper?.AddressInfo?.CountryName || ''}`}
                                                onChange={handleChange}
                                            />
                                        </div>


                                    </td>
                                </tr>
                                {/* Destination */}
                                <tr className="h-auto">
                                    <td className="px-1 w-[50%] py-2  bg-white-50 border border-gray-300">
                                        <p className="text-xs text-gray-700 text-left font-normal font-orbitron tracking-wiall break-all">
                                            Destination
                                        </p>
                                    </td>
                                    <td className="px-1 w-[50%] border border-gray-300 rounded-tr-md">
                                        <div className={`flex max-h-[120px] overflow-auto flex-col p-1 px-0 ${!data?.payload?.Consignee?.AddressInfo?.Address1 ? 'border border-red-500' : ''}`}>
                                            <EditableTableCell
                                                name="destination"
                                                value={!data?.payload?.Consignee?.AddressInfo?.Address1 ? '' : `${data?.payload?.Consignee?.AddressInfo?.Address1} ${data?.payload?.Consignee?.AddressInfo?.Address2 || ''}, ${data?.payload?.Consignee?.AddressInfo?.City || ''}, ${data?.payload?.Consignee?.AddressInfo?.State || ''} ${data?.payload?.Consignee?.AddressInfo?.Zipcode || ''}, ${data?.payload?.Consignee?.AddressInfo?.CountryName || ''}`}
                                                onChange={handleChange}
                                            />
                                        </div>

                                    </td>
                                </tr>

                                {/* Carrier */}
                                <tr className="h-auto">
                                    <td className="px-1 w-[50%] py-2  bg-white-50 border border-gray-300">
                                        <p className="text-xs text-gray-700 text-left font-normal font-orbitron tracking-wiall break-all">
                                            Carrier
                                        </p>
                                    </td>
                                    <td className="px-1 w-[50%] border border-gray-300 rounded-tr-md">
                                        <div className="flex max-h-[120px] overflow-auto flex-col p-1 px-0">
                                            <Select
                                                name="carrier"
                                                value={selectedCarrier}
                                                onChange={handleChangeCarrier}
                                                size="small" // Match the size of the TextField

                                                sx={{
                                                    width: '200px', // Set a fixed width for the Select component

                                                    '& .MuiSelect-select': {
                                                        fontSize: '12px', // Set the font size of the selected value
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        fontSize: '12px', // Ensure the font size applies to the Select input
                                                        '& .MuiSelect-icon': {
                                                            top: 'calc(50% - 12px)', // Adjust the icon position if needed
                                                        },
                                                    },
                                                }}
                                            >
                                                {getCarrierOptions().map((carrier, index) => (
                                                    <MenuItem key={index} value={carrier}>
                                                        {carrier}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </td>
                                </tr>
                                {/* Lead Time */}
                                <tr className="h-auto">
                                    <td className="px-1 w-[50%] py-2  bg-white-50 border border-gray-300">
                                        <p className="text-xs text-gray-700 text-left font-normal font-orbitron tracking-wiall break-all">
                                            Lead Time
                                        </p>
                                    </td>
                                    <td className="px-1 w-[50%] border border-gray-300 rounded-tr-md">
                                        <div className="flex max-h-[120px] overflow-auto flex-col p-1 px-0">
                                            <EditableTableCell
                                                name="lead_time"
                                                value={leadTime}
                                                onChange={handleChange}
                                                isEditing={isEditing}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {/* Base Freight */}

                                <tr className="h-auto">
                                    <td className="px-1 w-[50%] py-2  bg-white-50 border border-gray-300">
                                        <p className="text-xs text-gray-700 text-left font-normal font-orbitron tracking-wiall break-all">
                                            Base Freight
                                        </p>
                                    </td>
                                    <td className="pr-1 w-[50%] border border-gray-300 rounded-tr-md">
                                        <div className="flex max-h-[120px] overflow-auto flex-col p-1 px-0">
                                            <EditableTableCell
                                                name="base_freight"
                                                value={baseFreight}
                                                onChange={handleChange}
                                                isEditing={isEditing}
                                            />
                                        </div>
                                    </td>
                                </tr>

                                {/* Margin on Freight */}
                                <tr className="h-auto">
                                    <td className="px-1 w-[50%] py-2  bg-white-50 border border-gray-300">
                                        <p className="text-xs text-gray-700 text-left font-normal font-orbitron tracking-wiall break-all">
                                            Margin On Freight
                                        </p>
                                    </td>
                                    <td className="pr-1 w-[50%] border border-gray-300 rounded-tr-md">
                                        <div className="flex max-h-[120px] overflow-auto flex-col p-1 px-0">
                                            <EditableTableCell
                                                name="margin_on_freight"
                                                value={marginOnFreight}
                                                onChange={handleChange}
                                                isEditing={isEditing}
                                            />
                                        </div>
                                    </td>
                                </tr>

                                {/* Freight Cost */}
                                <tr className="h-auto">
                                    <td className="px-1 w-[50%] py-2  bg-white-50 border border-gray-300">
                                        <p className="text-xs text-gray-700 text-left font-normal font-orbitron tracking-wiall break-all">
                                            Freight Cost
                                        </p>
                                    </td>
                                    <td className="pr-1 w-[50%] border border-gray-300 rounded-tr-md">
                                        <div className="flex max-h-[120px] overflow-auto flex-col p-1 px-0">
                                            <EditableTableCell
                                                name="freight_cost"
                                                value={freightCost}
                                                onChange={handleChange}
                                                isEditing={isEditing}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <Box sx={{
                                marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center'
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={willCall}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setWillCall(e.target.checked)}
                                        />
                                    }
                                    label="Will Call"
                                />
                                <Typography variant="body2">
                                    Page {currentPage + 1} of {totalPages}
                                </Typography>
                            </Box>
                </div>
            </div>
        </div>
    );
};

export default FreightDetails;
