import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

interface CustomerEmailProps {
    emails: any; // Use 'any' type for emails, can be adjusted to match your data structure
}

const CustomerReceivedReview: React.FC<CustomerEmailProps> = ({ emails }) => {

    return (
        <div className=" w-full flex flex-col justify-start rounded-lg items-center bg-white ">
            {emails.map((email: any, index: number) => (
                <div
                    key={index}
                    className="flex flex-col justify-start items-center bg-white w-full mb-2"
                >
                    <p className={`flex justify-between w-full rounded-t-lg bg-white border border-gray-200 py-1 px-1 font-francois`}>Customer Clarification Received</p>

                    <div className="w-full p-4 overflow-y-auto">
                        {/* Body Content */}
                        <div className="w-full">
                            <p
                                className="text-sm"
                                dangerouslySetInnerHTML={{ __html: email.customerReviewResponse }}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CustomerReceivedReview;

