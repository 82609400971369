import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import queueData from '../DataContent/QueueData'; // Adjust this path as needed

const QueueListItems: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // Extract the industry id from the URL parameters

    const handleRowClick = (row: any) => {
        // Check the automationStatus of the row and navigate accordingly
        if (row.automationStatus === 'Sent') {
            navigate(`/industry/${id}/queue/${row.rfq}/sent`);
        } else if (row.automationStatus === 'Clarification Pending') {
            navigate(`/industry/${id}/queue/${row.rfq}/clarificationPending`);
        } else if (row.automationStatus === 'Clarification Received') {
            navigate(`/industry/${id}/queue/${row.rfq}/clarificationReceived`);
        } else if (row.automationStatus === 'Customer Response Pending') {
            navigate(`/industry/${id}/queue/${row.rfq}/customerResponsePending`);
        } else if (row.automationStatus === 'Received Quotes') {
            navigate(`/industry/${id}/queue/${row.rfq}/receivedQuotes`);
        } else if (row.automationStatus === 'Vendor Quote Pending') {
            navigate(`/industry/${id}/queue/${row.rfq}/vendorPending`);
        } else if (row.automationStatus === 'Forwarded') {
            navigate(`/industry/${id}/queue/${row.rfq}/forwarded`);
        }else {
            navigate(`/industry/${id}/queue/${row.rfq}`);
        }
    };

    // Find the industry data matching the specific id
    const industry = queueData.find(item => item.id === id); // Find the industry by id

    // If industry is found, extract the emails; otherwise, set data to an empty array
    const data: any[] = industry ? industry.emails : [];

    // Define a function to determine the text color based on automationStatus
    const getStatusColor = (status: string) => {
        if (status === 'Vendor Quote Pending' ||
            status === 'Customer Response Pending' ||
            status === 'Clarification Pending') {
            return 'text-orange-500'; // Tailwind class for orange text
        }
        else if(status ==='Clarification Received'|| status ==='Received Quotes' || status ==='Sent')
        {
            return 'text-green-500'
        }
        else if(status ==='Forwarded')
        {
            return 'text-blue-950'
        }
        return ''; // No specific class for other statuses
    };

    return (
        <div className="overflow-x-auto inline-block min-w-full border rounded-md">
            <table className="min-w-full text-sm text-left text-gray-500">
                <thead className="text-sm text-gray-500 uppercase bg-white-50">
                <tr>
                    <th className="px-4 text-xs py-2 font-francois">RFQ</th>
                    <th className="px-4 text-xs py-2 font-francois">Customer Name</th>
                    <th className="px-4 text-xs py-2 font-francois">Status</th>
                    <th className="px-4 text-xs py-2 font-francois">Date</th>
                    <th className="px-4 text-xs py-2 font-francois">Materials</th>
                    <th className="px-4 text-xs py-2 font-francois">Quote Value</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row: any, index: number) => (
                    <tr
                        key={row.id} // Use row.id for a unique key
                        className="bg-white border-b hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleRowClick(row)}
                    >
                        <td className="py-2 px-4 border-b border-gray-300">{row.rfq}</td>
                        <td className="py-2 px-4 border-b border-gray-300">{row.company}</td>
                        <td className={`py-2 px-4 border-b border-gray-300 ${getStatusColor(row.automationStatus)}`}>
                            {row.automationStatus}
                        </td>
                        <td className="py-2 px-4 border-b border-gray-300">{row.date}</td>
                        <td className="py-2 px-4 border-b border-gray-300">{row.materials.join(', ')}</td>
                        <td className="py-2 px-4 border-b border-gray-300">{row.quotePrice}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className={`mt-auto p-4`}>
                <div className={`flex flex-col sm:flex-row justify-between items-center gap-4`}>
                    <div className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>
                        <button className={`bg-[#205B89] text-white px-4 py-2 rounded-md font-francois`}>
                            Refresh
                        </button>
                    </div>
                    <div className="flex justify-end mt-1 items-center font-biryani">
                        <p className="text-xs font-thin leading-none mr-2">No of Rows {data.length}</p>

                        {/* Left arrow button */}
                        <button className="rounded bg-white p-1 flex items-center justify-center">
                            &lt; {/* Left angle bracket */}
                        </button>
                        <span className="mx-2 text-sm">1 of 1</span>
                        {/* Right arrow button */}
                        <button className="rounded bg-white p-1 flex items-center justify-center">
                            &gt; {/* Right angle bracket */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueueListItems;
