import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

interface CustomerEmailProps {
    emails: any; // Use 'any' type for emails, can be adjusted to match your data structure
}

const RFQEmail: React.FC<CustomerEmailProps> = ({ emails }) => {
    return (
        <div className="w-full flex flex-col justify-start items-center bg-white mt-2">
            {emails.map((email: any, index: number) => (
                <div
                    key={index}
                    className="flex flex-col justify-start items-center bg-white w-full mb-2"
                >
                    <p className={`text-sm font-medium text-gray-700 bg-gray-100 rounded-t-md w-full py-2 px-3 font-francois`}>
                        {email.rfq}
                    </p>

                    <div className={`w-full p-2 overflow-y-auto h-auto`}> {/* Removed fixed height */}
                        {/* Subject Row */}
                        <div className="w-full flex items-center">
                            <p className="font-bold text-md mr-2">Subject:</p>
                            <p className="text-md">{email.subject}</p>
                        </div>

                        <div className="mb-4 w-full flex items-center">
                            <p className="font-bold text-md mr-2">CC:</p>
                            <p className="text-md">{email.cc}</p>
                        </div>

                        {/* Body Content */}
                        <div className="w-full">
                            <p
                                className="text-sm mt-2"
                                dangerouslySetInnerHTML={{ __html: email.content }}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RFQEmail;
