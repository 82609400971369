import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Box,
    Typography
} from '@mui/material';

interface RegeneratePopupProps {
    open: boolean;
    onClose: () => void;
}

const RegeneratePopup: React.FC<RegeneratePopupProps> = ({ open, onClose }) => {
    const [selectedTone, setSelectedTone] = useState<string | null>(null);

    const handleButtonClick = (tone: string) => {
        setSelectedTone(tone);
    };

    const handleSaveClick = () => {
        if (selectedTone) {
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ backgroundColor: '#264B6A', color: 'white' }}>
                Regenerate Response
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                    <Typography variant="h6">Select a tone for your response:</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '20px' }}>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: selectedTone === 'Formal' ? '#264B6A' : 'transparent',
                                color: selectedTone === 'Formal' ? 'white' : 'inherit'
                            }}
                            onClick={() => handleButtonClick('Formal')}
                        >
                            Formal
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: selectedTone === 'Casual' ? '#264B6A' : 'transparent',
                                color: selectedTone === 'Casual' ? 'white' : 'inherit'
                            }}
                            onClick={() => handleButtonClick('Casual')}
                        >
                            Casual
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: selectedTone === 'Friendly' ? '#264B6A' : 'transparent',
                                color: selectedTone === 'Friendly' ? 'white' : 'inherit'
                            }}
                            onClick={() => handleButtonClick('Friendly')}
                        >
                            Friendly
                        </Button>
                    </Box>
                    <Box sx={{ marginTop: '20px', textAlign: 'left', width: '100%' }}>
                        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                            <strong>Formal:</strong> Use this tone for professional communication, where you need to maintain a level of respect and formality. Ideal for emails to clients, supervisors, or in any business context.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                            <strong>Casual:</strong> A more relaxed tone that is suitable for internal communication or with colleagues. It maintains professionalism but is less strict, making it suitable for team updates or informal discussions.
                        </Typography>
                        <Typography variant="body1">
                            <strong>Friendly:</strong> This tone is warm and approachable, perfect for emails to close colleagues or when you want to build rapport. It’s less formal and can be used in situations where you want to create a personal connection.
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">Cancel</Button>
                <Button
                    onClick={handleSaveClick}
                    sx={{ backgroundColor: '#264B6A', color: 'white' }}
                    variant="contained"
                    disabled={!selectedTone}  // Disable if no tone is selected
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RegeneratePopup;

