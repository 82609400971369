import React from 'react';
import { TableCell, TextField } from '@mui/material';

interface EditableTableCellProps {
    name?: string;
    value: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isEditing?: boolean;
}

const EditableTableCell: React.FC<EditableTableCellProps> = ({ name, value, onChange, isEditing }) => {
    return (
            <TextField
                name={name}
                value={value}
                onChange={onChange}
                variant="outlined"
                size="small"

                InputProps={{
                    sx: {
                    whiteSpace: 'pre-line', // Ensure the text will respect line breaks
                        '& .MuiOutlinedInput-root': {
                            fontSize: '12px',
                        },
                        '& .MuiInputBase-input': {
                            color: 'black',
                            fontSize:'12px',

                            '&:disabled': {
                                WebkitTextFillColor: 'black',
                                fontSize:'12px'
                            },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: isEditing ? '1px solid #000' : 'none',

                        },
                    },
                }}
                InputLabelProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
                sx={{width:'100%'}}
                disabled={!isEditing}
            />
    );
};

export default EditableTableCell;
