import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    FormControlLabel,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import CustomerEmail from '../Customer/CustomerEmail';
import RegeneratePopup from './RegeneratePopup';
import RFQEmail from '../RFQ/RFQEmail';
import CloseIcon from "@mui/icons-material/Close";
import CustomerFollowUp from '../FollowUp/CustomerFollowUp';
import SuccessPopup from './SuccessPopup';
import {useNavigate, useParams} from "react-router-dom";


interface RFQPopupProps {
    open: boolean;
    onClose: () => void;
    customerData: any; // Replace `any` with the appropriate type for your emails if known

}

const CustomerFollowUpPopup: React.FC<RFQPopupProps> = ({ open, onClose, customerData }) => {
    const [successPopupOpen, successSetPopupOpen] = useState(false);
    const navigate = useNavigate();
    const {id, rfq} = useParams<{ id: string; rfq: string }>();


    const handleSend = () => {
        successSetPopupOpen(true); // Open the popup when "Send" is clicked
    };

    const handleClosePopup = () => {
        successSetPopupOpen(false);
    };

    const handleConfirmSend = () => {
        // Keep the popup open for 3 seconds before navigating
        setTimeout(() => {
            successSetPopupOpen(false);
            navigate(`/industry/${id}/queue`); // Navigate to the queue
        }, 2000); // 3 seconds delay
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <div className="bg-white rounded-lg w-full relative p-2"> {/* Dialog box styling */}
                {/* First Row: Close Icon in the top-right corner */}
                <div className="flex justify-end">
                    <IconButton onClick={onClose} className="text-gray-500">
                        <CloseIcon />
                    </IconButton>
                </div>
                {/* Second Row: RFQEmail component */}
                <div className="mt-4">
                    <CustomerFollowUp emails={customerData} />
                </div>
            </div>

            <div className={`w-full rounded-md flex justify-center items-center my-1 sm:w-auto text-xs`}>

                <button
                    className={`text-sm px-4 py-2 text-white font-semibold rounded-md font-francois bg-[#205B89]`}
                    onClick={handleSend}
                >
                    Send
                </button>

                {/* SuccessPopup dialog */}
                <SuccessPopup
                    open={successPopupOpen}
                    onClose={handleClosePopup}
                    onConfirm={handleConfirmSend}
                />
            </div>
        </Dialog>
    );
};

export default CustomerFollowUpPopup;
