const falconData = [
    {
        material: 'Steel',
        lastUpdated: '2024-08-10',
        details:{
            grade: 'A36',
            customerReference: 'CUST-001',
            units: 'kg',
            sawBladeKerf: '1.5mm',
            stackHeight: '50mm',
            hourlyRate: '$100/hr',
            trimCutRequired: true,
            allowHeadCuts: false,
            useDifficultRemnants: true,
        }

    },
    {
        material: 'Aluminum',
        lastUpdated: '2024-08-08',
        details: {

            grade: '6061-T6',
            customerReference: 'CUST-002',
            units: 'kg',
            sawBladeKerf: '2.0mm',
            stackHeight: '60mm',
            hourlyRate: '$120/hr',
            trimCutRequired: false,
            allowHeadCuts: true,
            useDifficultRemnants: false,
        }
    },
    {
        material: 'Copper',
        lastUpdated: '2024-08-07',
        details: {

            grade: 'C11000',
            customerReference: 'CUST-003',
            units: 'kg',
            sawBladeKerf: '1.8mm',
            stackHeight: '40mm',
            hourlyRate: '$130/hr',
            trimCutRequired: true,
            allowHeadCuts: true,
            useDifficultRemnants: true,
        }
    },
    {
        material: 'Stainless Steel',
        lastUpdated: '2024-08-09',
        details: {

            grade: '304',
            customerReference: 'CUST-004',
            units: 'kg',
            sawBladeKerf: '1.6mm',
            stackHeight: '45mm',
            hourlyRate: '$150/hr',
            trimCutRequired: false,
            allowHeadCuts: false,
            useDifficultRemnants: true,
        }
    },
    {
        material: 'Brass',
        lastUpdated: '2024-08-06',
        details: {

            grade: 'C26000',
            customerReference: 'CUST-005',
            units: 'kg',
            sawBladeKerf: '1.7mm',
            stackHeight: '55mm',
            hourlyRate: '$110/hr',
            trimCutRequired: true,
            allowHeadCuts: true,
            useDifficultRemnants: false,
        }
    },
    {
        material: 'Titanium',
        lastUpdated: '2024-08-11',
        details: {

            grade: 'Ti-6Al-4V',
            customerReference: 'CUST-006',
            units: 'kg',
            sawBladeKerf: '1.4mm',
            stackHeight: '30mm',
            hourlyRate: '$200/hr',
            trimCutRequired: true,
            allowHeadCuts: false,
            useDifficultRemnants: true,
        }
    },
    {
        material: 'Nickel',
        lastUpdated: '2024-08-12',
        details: {

            grade: 'Nickel 200',
            customerReference: 'CUST-007',
            units: 'kg',
            sawBladeKerf: '1.9mm',
            stackHeight: '35mm',
            hourlyRate: '$180/hr',
            trimCutRequired: false,
            allowHeadCuts: true,
            useDifficultRemnants: false,
        }
    },
    {
        material: 'Zinc',
        lastUpdated: '2024-08-05',
        details: {

            grade: 'Zamak 3',
            customerReference: 'CUST-008',
            units: 'kg',
            sawBladeKerf: '2.2mm',
            stackHeight: '65mm',
            hourlyRate: '$90/hr',
            trimCutRequired: true,
            allowHeadCuts: false,
            useDifficultRemnants: true,
        }
    },
    {
        material: 'Lead',
        lastUpdated: '2024-08-03',
        details: {

            grade: 'Pure Lead',
            customerReference: 'CUST-009',
            units: 'kg',
            sawBladeKerf: '2.0mm',
            stackHeight: '25mm',
            hourlyRate: '$80/hr',
            trimCutRequired: false,
            allowHeadCuts: true,
            useDifficultRemnants: true,
        }
    },
    {
        material: 'Bronze',
        lastUpdated: '2024-08-04',
        details: {

            grade: 'C51000',
            customerReference: 'CUST-010',
            units: 'kg',
            sawBladeKerf: '1.8mm',
            stackHeight: '50mm',
            hourlyRate: '$140/hr',
            trimCutRequired: true,
            allowHeadCuts: false,
            useDifficultRemnants: false,
        }
    },
];

export default falconData;
