import React, { useState } from 'react';
import { Box, Typography, Paper, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import RegeneratePopup from "../Popups/RegeneratePopup";
import AddVendorPopup from "../Popups/AddVendorPopup";

interface VendorEmailsProps {
    emails: any; // You can refine the type to match your data structure
}

const VendorEmails: React.FC<VendorEmailsProps> = ({ emails }) => {

    const [popupOpen, setPopupOpen] = useState(false);

    const handleOpenClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    // Check if emails is valid and contains at least one item
    const initialVendor = emails && emails.length > 0 && emails[0].vendors.length > 0 ? emails[0].vendors[0] : '';

    // State to track the currently selected vendor tab
    const [activeVendor, setActiveVendor] = useState<string>(initialVendor);

    // Handle vendor tab click
    const handleVendorClick = (vendor: string) => {
        setActiveVendor(vendor);
    };

    return (
        <div className="w-full flex flex-col justify-start items-center bg-white mt-2">
            {/* Ensure emails and emails[0].vendors exist before rendering the component */}
            {emails && emails.length > 0 ? (
                emails.map((email: any, index: number) => (
                    <div
                        key={index}
                        className="flex flex-col justify-start items-center bg-white w-full mb-2"
                    >
                        {/* Show only if vendorResponse is not empty */}
                        {email.vendorResponse ? (
                            <>
                                {/* "Vendors" Text */}
                                <p className="w-full rounded-t-lg bg-white border border-gray-200 py-1 px-1 font-francois">
                                    Vendors
                                </p>

                                {/* CC Input */}
                                <div className="flex flex-col w-full m-auto h-[6vh] overflow-auto mt-[10px] border bg-white-50 mb-[10px]">
                                    <div className="h-full w-full flex-row justify-start items-start flex p-2">
                                        <p className="text-xs text-gray-900 text-left font-francois tracking-wider">CC:</p>
                                        <div className="flex flex-wrap">
                                            <input
                                                className="text-xs text-gray-900 bg-transparent outline-none ml-1"
                                                placeholder="Add CC"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Tabs for vendors */}
                                {email.vendors && email.vendors.length > 0 ? (
                                    <div className="flex justify-start w-full">
                                        {email.vendors.map((vendor: string) => (
                                            <button
                                                key={vendor}
                                                onClick={() => handleVendorClick(vendor)}
                                                className={`py-2 px-4 border-b-2 ${
                                                    activeVendor === vendor ? 'border-black text-black' : 'border-transparent'
                                                } font-francois`}
                                            >
                                                {vendor}
                                            </button>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No vendors available.</p>
                                )}

                                {/* Scrollable Body Content */}
                                <div className="w-full p-2 overflow-y-auto border-b border-gray-300 max-h-[40vh]"> {/* Adjust max-height as needed */}
                                    <div className="w-full">
                                        <p className="text-sm" dangerouslySetInnerHTML={{ __html: email.vendorResponse }} />
                                    </div>
                                </div>

                                {/* Add Vendor Button (Fixed) */}
                                <div className="flex items-center justify-end w-full mt-2"> {/* Container to align the button */}
                                    <button
                                        className="bg-[#205B89] text-white px-4 py-2 rounded-md font-francois sticky bottom-0"
                                        onClick={handleOpenClick}
                                        style={{ zIndex: 10 }} // Ensure it's above scrollable content
                                    >
                                        Add Vendor
                                    </button>

                                    <AddVendorPopup
                                        open={popupOpen}
                                        onClose={handleClosePopup}
                                        materials={email.tableData}
                                    />
                                </div>
                            </>
                        ) : (
                            <p>No vendors needed.</p>
                        )}
                    </div>

                ))
            ) : (
                <p>No vendors needed</p>
            )}

        </div>
    );
};

export default VendorEmails;
