import React, {useState} from 'react';
import {
    Box,
    Tabs,
    Tab,
    Chip,
    Typography,
    Button,
    Grid,
    IconButton
} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import DecisionsTable from "../Tables/DecisionsTable";
import ErpPopup from '../Popups/ErpPopup';

interface MaterialDecisionsProps {
    materials: any; // Accept any type for materials
}

type TabStatus = 'InStock' | 'PartialStock' | 'OutOfStock' | 'InStockAlternativeLocations';


const MaterialsDecisionsAutomated: React.FC<MaterialDecisionsProps> = ({materials}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedTab, setSelectedTab] = useState<TabStatus | ''>(''); // Initialize to empty string
    const itemsPerPage = 1; // Adjust this as needed
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBox, setSelectedBox] = useState(null);


    // Tab colors based on status
    const tabColors: Record<TabStatus, string> = {
        InStock: 'bg-[#A3E59E]',
        PartialStock: 'bg-[#FAD467]',
        OutOfStock: 'bg-[#F18C83]',
        InStockAlternativeLocations: 'bg-[#E59ED1]',
    };

    const handleClearFilter = (event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent tab click event
        setSelectedTab('');
        setCurrentPage(0); // Reset to the first page
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    // Function to filter materials based on the selected tab
    const filterMaterialsByStatus = (status: TabStatus | '') => {
        console.log(status);
        // Return all materials if no tab is selected (empty string)
        if (status === '') {
            return materials; // Return all materials if no tab is selected
        }
        return materials.filter((material: { stockStatus: string }) => material.stockStatus === status);
    };


    // Get the materials for the selected tab
    const displayedMaterials = filterMaterialsByStatus(selectedTab);
    const totalPages = Math.ceil(displayedMaterials.length / itemsPerPage); // Calculate total pages based on filtered materials

    const currentMaterial = displayedMaterials[currentPage]; // Get the current material based on currentPage


    const handleChangeTab = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, status: TabStatus) => {
        setSelectedTab(status); // Set selectedTab to the status directly
        setCurrentPage(0); // Reset to the first page on tab change
    };


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Handle box selection


    return (
        <div className="">
            {/* Tabs Column */}
            <div className="w-full overflow-auto">
                <div className="w-full mb-3 mt-2 overflow-x-auto scroll-m-0 scroll-p-0">
                    <div className="flex justify-between items-center my-2 gap-x-5 min-w-max"> {/* Enable horizontal scrolling */}
                        {(['InStock', 'PartialStock', 'OutOfStock', 'InStockAlternativeLocations'] as TabStatus[]).map((status) => {
                            const productCount = filterMaterialsByStatus(status).length; // Count filtered products

                            return (
                                <div
                                    key={status}
                                    onClick={(event) => handleChangeTab(event, status)} // Pass both event and status
                                    className="flex flex-col items-center cursor-pointer transition duration-200 ease-in-out hover:scale-105 min-w-max"
                                >
                                    {/* Conditional rendering: show close icon if selected, otherwise show product count */}
                                    {selectedTab === status ? (
                                        <IconButton onClick={handleClearFilter} className="ml-2 text-gray-700">
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        <span
                                            className={`text-white text-xs bg-[#264B6A] rounded-full px-2 py-1 mb-1 ${productCount > 0 ? '' : 'invisible'}`}>
                                            {productCount > 0 ? productCount : 0}
                                        </span>
                                    )}

                                    {/* Second row for the colored circle and status */}
                                    <div className="flex items-center">
                                        <div className={`rounded-full w-4 h-4 ${tabColors[status]} mr-2`} />
                                        <span className="text-sm text-gray-700 font-semibold">{status}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>


                </div>
            </div>

            {/* Content Area */}
            <div className="flex justify-between w-full h-auto">
                <div className="flex justify-between w-full mx-auto">
                    {currentMaterial ? (
                        <DecisionsTable
                            key={currentMaterial.id} // Use material id as the unique key
                            material={currentMaterial}
                        />
                    ) : (
                        <div>No materials available for this status.</div>
                    )}

                </div>
            </div>

            {/* Footer with search button and page number */}
            <div className="flex flex-row items-center mt-2 w-full">
                <button
                    onClick={handleOpenDialog}
                    className="m-2 px-4 py-2 bg-[#264B6A] text-white rounded-md hover:bg-[#1E3A55] transition duration-200 ease-in-out"
                >
                    ERP Search
                </button>

                <button
                    onClick={handlePrevious}
                    className={`m-2 p-2 rounded-full ${currentPage === 0 ? 'text-gray-400 cursor-not-allowed' : 'text-gray-700'} transition duration-200 ease-in-out`}
                    disabled={currentPage === 0}
                >
                    <ArrowBackIcon className="text-2xl" />
                </button>

                <span className="text-sm mx-2">
                        Page {currentPage + 1} of {totalPages}
                    </span>

                <button
                    onClick={handleNext}
                    className={`m-2 p-2 rounded-full ${currentPage >= totalPages - 1 ? 'text-gray-400 cursor-not-allowed' : 'text-gray-700'} transition duration-200 ease-in-out`}
                    disabled={currentPage >= totalPages - 1}
                >
                    <ArrowForwardIcon className="text-2xl" />
                </button>

                {/* Render the ErpPopup dialog */}
                <ErpPopup
                    open={openDialog}
                    onClose={handleCloseDialog}
                    selectedMaterial={selectedBox !== null ? displayedMaterials[selectedBox] : undefined}
                />
            </div>

        </div>
    );
};

export default MaterialsDecisionsAutomated;
