import React, { useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import WiseSales from "./HomeScreen/WiseSales";

import IndustryDetail from "./IndustryDetail";
import VideoBackgroundComponent from "./VideoBackground";
import {ThemeProvider,createTheme} from "@mui/material";
import AutomationList from "./Automation/AutomationList";
import AutomationDetails from "./Automation/AutomationDetails/AutomationDetails";
import ERPSearch from "./ERPSearch/ERPSearch";
import Dashboard from "./Dashboard/Dashboard";
import Delegate from './Delegate/Delegate';
import DelegateFirst from "./Delegate/DelegateFirst";
import DelegateSecond from './Delegate/DelegateSecond';
import DelegateThird from './Delegate/DelegateThird';
import QueueList from './Queue/QueueList';
import QueueSent from './Queue/QueueDetails/QueueSent';
import ContactUs from './ContactUs/ContactUs';
import QueueClarificationPending from './Queue/QueueDetails/QueueClarificationPending';
import QueueClarificationReceived from './Queue/QueueDetails/QueueClarificationReceived';
import QueueFirstStep from './Queue/QueueSteps/QueueFirstStep';
import QueueSecondStep from './Queue/QueueSteps/QueueSecondStep';
import QueueThirdStep from './Queue/QueueSteps/QueueThirdStep';
import QueueCustomerPending from './Queue/QueueDetails/QueueCustomerPending';
import QueueReceivedQuotes from './Queue/QueueDetails/QueueReceivedQuotes';
import ReceivedQuotesReview from './Queue/ReceivedQuotesReview';
import QueueVendorPending from './Queue/QueueDetails/QueueVendorPending';
import Settings from './Settings/Settings';
import Forwarded from './Queue/QueueDetails/Forwarded';

// Create a theme instance
const theme = createTheme({
    palette: {
        primary: {
            main: '#356F9F', // Example primary color
        },
        secondary: {
            main: '#080808', // Example secondary color
        },

    },
});


const App: React.FC = () => {
  return (
      <ThemeProvider theme={theme}>
          <Router>
              <Routes>
                  <Route path="/" element={<WiseSales />} />
                  <Route path="/industry/:id" element={<IndustryDetail />}>
                      <Route index element={<IndustryDetail />} /> {/* This will render when you access /industry/:id */}
                      <Route path="automation" element={<AutomationList />} /> {/* This is the sub-route */}
                      <Route path="automation/:rfq" element={<AutomationDetails />} /> {/* New route for detailed RFQ view */}
                      <Route path="erpSearch" element={<ERPSearch />} /> {/* This is the sub-route */}
                      <Route path="dashboard" element={<Dashboard />} /> {/* This is the sub-route */}
                      <Route path="delegate" element={<Delegate />} /> {/* This is the sub-route */}
                      <Route path="delegate/:rfq" element={<DelegateFirst />} /> {/* New route for detailed RFQ view */}
                      <Route path="delegate/:rfq/decisions" element={<DelegateSecond />} /> {/* New route for detailed RFQ view */}
                      <Route path="delegate/:rfq/emails" element={<DelegateThird />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue" element={<QueueList />} /> {/* This is the sub-route */}
                      <Route path="settings" element={<Settings />} /> {/* This is the sub-route */}
                      <Route path="contactUs" element={<ContactUs />} /> {/* This is the sub-route */}
                      <Route path="queue/:rfq/sent" element={<QueueSent />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/clarificationPending" element={<QueueClarificationPending />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/clarificationReceived" element={<QueueClarificationReceived />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/customerResponsePending" element={<QueueCustomerPending />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/clarificationReceived/first" element={<QueueFirstStep />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/clarificationReceived/second" element={<QueueSecondStep />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/clarificationReceived/third" element={<QueueThirdStep />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/receivedQuotes" element={<QueueReceivedQuotes />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/receivedQuotes/review" element={<ReceivedQuotesReview />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/vendorPending" element={<QueueVendorPending />} /> {/* New route for detailed RFQ view */}
                      <Route path="queue/:rfq/forwarded" element={<Forwarded />} /> {/* New route for detailed RFQ view */}




                  </Route>
              </Routes>
          </Router>
      </ThemeProvider>

  );
}

export default App;
