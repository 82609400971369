import React, {useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {
    Typography,
    Box,
    Drawer,
} from '@mui/material';
import industryData from './DataContent/industryData'; // Assuming you have a file containing industry data
import SettingsIcon from '@mui/icons-material/Settings';
import {Link, Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import logo from './Images/LogoWiseSalesBlue.png';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AppBarAvatar from './AppBarAvatar';
import UpdateIcon from '@mui/icons-material/Update';
import UpdatesPopup from './Popups/UpdatesPopup';



const IndustryDetail: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const industry = industryData.find(industry => industry.id === id);
    const [tutorialEnabled, setTutorialEnabled] = useState(false); // State for tutorial switch
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('Dashboard'); // Default tab

    const [updatesPopup, setUpdatePopup]=useState(false);

    const handleOpenUpdates = () => setUpdatePopup(true);
    const handleCloseUpdates = () => setUpdatePopup(false);

    const handleDrawerOpen = () => setDrawerOpen(true);
    const handleDrawerClose = () => setDrawerOpen(false);


    return (
        <div className={'flex h-screen'}>

            <Drawer
                variant='permanent'
                open={drawerOpen}
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
                anchor='left'
                className={`transition-all duration-300`}
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerOpen ? "15rem" : "4rem", // Adjust width based on drawerOpen
                        overflowX: 'hidden',
                        background: '#fff',
                        color: '#000',
                    },
                }}
            >

                <nav className="flex flex-col items-center mt-5">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '40px', // Adjust margin as needed
                            width: '100%',
                            justifyContent: 'center', // Space-between when open, center when closed
                            padding: '0 16px', // Optional: Add padding to prevent items from touching edges
                        }}
                    >
                        {/* Logo */}
                        <Link to="/">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{
                                    maxWidth: drawerOpen ? '70px' : '50px',
                                    transition: 'max-width 0.5s ease',
                                }}
                            />
                        </Link>
                        {/* Wise Sales Text */}
                        {drawerOpen && (
                            <Typography
                                variant="h6"
                                sx={{
                                    display: 'block',
                                    marginLeft: '10px'
                                }}
                            >
                                WISE SALES
                            </Typography>
                        )}
                    </Box>

                    <div className="m-2 w-full"> {/* Increased margin */}
                        <Link to={`/industry/${id}/dashboard`} className="w-full">
                            <button
                                className={`flex justify-start p-5 text-[#205B89] bg-transparent 
                                    hover:bg-[#205B89] hover:text-white
                                    rounded transition duration-200 w-full`}
                                onClick={() => setActiveTab('Dashboard')} // Set active tab
                            >
                                <DashboardIcon
                                    sx={{color: 'inherit', mr: 2}}/> {/* Use 'inherit' to take the text color */}
                                {drawerOpen && "Dashboard"}
                            </button>

                        </Link>
                    </div>
                    <div className="m-2 w-full"> {/* Increased margin */}
                        <Link to={`/industry/${id}/automation`} className="w-full">
                            <button
                                className={`flex justify-start p-5 text-[#205B89] bg-transparent 
                                    hover:bg-[#205B89] hover:text-white
                                    rounded transition duration-200 w-full`}
                                onClick={() => setActiveTab('Automated Quotes')}
                            >
                                <AutoModeIcon sx={{color: 'inherit', mr: 2}}/>
                                {drawerOpen && "Queue"}
                            </button>
                        </Link>
                    </div>
                    <div className="m-2 w-full"> {/* Increased margin */}
                        <Link to={`/industry/${id}/queue`} className="w-full">
                            <button
                                className={`flex justify-start p-5 text-[#205B89] bg-transparent 
                                    hover:bg-[#205B89] hover:text-white
                                    rounded transition duration-200 w-full`}
                                onClick={() => setActiveTab('Queue')}
                            >
                                <FormatListBulletedIcon sx={{color: 'inherit', mr: 2}}/>
                                {drawerOpen && "In Process"}
                            </button>
                        </Link>
                    </div>
                    <div className="m-2 w-full"> {/* Increased margin */}
                        <Link to={`/industry/${id}/delegate`} className="w-full">
                            <button
                                className={`flex justify-start p-5 text-[#205B89] bg-transparent 
                                    hover:bg-[#205B89] hover:text-white
                                    rounded transition duration-200 w-full`}
                                onClick={() => setActiveTab('Delegate')} // Set active tab

                            >
                                <RequestQuoteOutlinedIcon sx={{color: 'inherit', mr: 2}}/>
                                {drawerOpen && "Inbox"}
                            </button>
                        </Link>
                    </div>
                    <div className="m-2 w-full"> {/* Increased margin */}
                        <Link to={`/industry/${id}/erpSearch`} className="w-full">
                            <button
                                className={`flex justify-start p-5 text-[#205B89] bg-transparent 
                                    hover:bg-[#205B89] hover:text-white
                                    rounded transition duration-200 w-full`}
                                onClick={() => setActiveTab('ERP Search')} // Set active tab

                            >
                                <SearchOutlinedIcon sx={{color: 'inherit', mr: 2}}/>
                                {drawerOpen && "ERP Search"}
                            </button>
                        </Link>
                    </div>
                </nav>
            </Drawer>

            {/* Main Content */}
            <div
                className={`flex flex-col flex-1 relative overflow-auto transition-margin duration-300 bg-gray-100 ${
                    drawerOpen ? 'ml-60' : 'ml-16' // Adjust margin based on drawerOpen
                }`}
            >
                <div className="flex flex-row justify-between items-center px-4 pt-2">
                    <div className="flex items-center justify-between text-lg font-francois font-semibold">
                        {/* The name of the current tab */}
                            {activeTab} {/* This will display the active tab name */}
                    </div>

                    <div className="flex-grow"/>
                    {/* Takes up remaining space */}
                    <div className="flex items-center">

                        {/* Update Icon */}
                        <UpdateIcon
                            className="cursor-pointer text-gray-600 hover:text-gray-800 mr-4"
                            onClick={handleOpenUpdates}
                        />

                        {/* Updates Popup */}
                        <UpdatesPopup open={updatesPopup} onClose={handleCloseUpdates} />

                        {/* Settings Icon */}
                        <Link
                            to={`/industry/${id}/settings`}
                            className="mr-4"
                            onClick={() => setActiveTab("Settings")} // Assuming "Settings" is the third tab (index 2)
                        >
                            <SettingsIcon className="cursor-pointer" />
                        </Link>


                        {/* Avatar */}
                        <AppBarAvatar setActiveTab={setActiveTab} /> {/* Pass setActiveTab as a prop */}
                    </div>
                </div>

                <div className="flex-1 pt-2 pb-1 h-max-[95vh] px-4">
                    <Outlet/> {/* For rendering nested routes */}
                </div>
            </div>


        </div>

    );
};

export default IndustryDetail;
