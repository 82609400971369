import React, {useState} from 'react';
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material';
import RFQPopup from "../Popups/RFQPopup";

interface CustomerDetailsProps {

    customerData: any;

}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({customerData}) => {

    const [isRFQPopupOpen, setIsRFQPopupOpen] = useState(false);

    // Function to open the RFQ popup
    const handleOpenRFQPopup = () => {
        setIsRFQPopupOpen(true);
    };

    // Function to close the RFQ popup
    const handleCloseRFQPopup = () => {
        setIsRFQPopupOpen(false);
    };


    return (

        <div className="w-full flex flex-col justify-start items-center">
            <div className="w-full overflow-hidden border rounded-md bg-white shadow-sm">
                <div className="w-full h-10 bg-white px-4 py-2 flex justify-between items-center">
                    <div>
                        <p className="text-[#3A3541DE] text-sm  font-francois font-medium tracking-wide">
                            Customer Details
                        </p>
                    </div>
                </div>
                {customerData.map((email: any, index: number) => (
                    <div key={index} className={`w-full h-auto px-2 py-2 pt-0`}>

                        <table className="w-full h-auto">
                            {/* Name */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">Name</td>
                                <td className="px-4 py-2 h-full border text-gray-900 text-sm font-normal break-words">
                                    {email.sender || "N/A"}
                                </td>
                            </tr>
                            {/* Email */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">
                                    Email
                                </td>
                                <td className="px-4 py-2 h-full border text-gray-900 text-sm font-normal break-words">
                                    {email.senderEmail || "N/A"}
                                </td>
                            </tr>

                            {/* CC */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">CC</td>
                                <td className="px-4 py-2 h-full border text-gray-900 text-sm font-normal break-words">
                                    {email.cc || "N/A"}
                                </td>
                            </tr>

                            {/* Company */}
                            <tr className="border-b h-12 border border-gray-300">
                                <td className="px-4 py-2 h-full bg-gray-50 border text-gray-700 text-sm font-medium tracking-wide">
                                    Company
                                </td>
                                <td className="px-4 py-2 h-full text-gray-900 text-sm font-normal capitalize break-words">
                                    {email.company || "N/A"}
                                </td>
                            </tr>
                        </table>
                        <button
                            onClick={handleOpenRFQPopup}  // Add this click handler
                            className="bg-[#264B6A] text-white px-4 py-1 rounded m-2"
                        >
                            RFQ Email
                        </button>

                        {/* RFQPopup component, pass in the open state and onClose handler */}
                        <RFQPopup
                            open={isRFQPopupOpen}
                            onClose={handleCloseRFQPopup}
                            customerData={customerData} // Pass the customer data to the popup
                        />
                    </div>

                ))}


            </div>
        </div>
    );
};

export default CustomerDetails;
