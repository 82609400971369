import React, {useState} from "react";
import industryData from "../DataContent/industryData";
import {useNavigate, useParams} from "react-router-dom";

const Delegate: React.FC = () => {
    const {id} = useParams<{ id: string }>(); // Extract the industry id from the URL parameters

    // Find the industry data matching the specific id
    const industry = industryData.find(item => item.id === id); // Find the industry by id
    // If industry is found, extract the emails; otherwise, set data to an empty array
    const data: any[] = industry ? industry.emails : [];

    // State to track the selected email
    const [selectedEmail, setSelectedEmail] = useState<any>(null);

    // Function to handle clicking on an email
    const handleEmailClick = (email: any) => {
        setSelectedEmail(email); // Set the selected email in state
    };

    // Function to provide default email details if selectedEmail is null
    const determineEmailDetails = () => selectedEmail || {};

    const navigate = useNavigate();

    const handleDelegate = () => {
        // Assuming 'selectedEmail' is available and contains the 'rfq' value
        const rfq = determineEmailDetails()?.rfq;
        if (rfq) {
            // Navigate to the delegate page with the RFQ value
            navigate(`/industry/${id}/delegate/${rfq}`);
        } else {
            console.error('RFQ not found');
        }
    };
    const emailDetails = determineEmailDetails();


    return (
        <div className={`container mx-auto px-4 lg:grid lg:grid-cols-4 lg:gap-4`}>
            <div className={`lg:col-span-1 overflow-auto max-h-[90vh] mb-4 lg:mb-0`}>
                <div className={`w-full animate__animated animate__fadeIn bg-white-500 false`}>
                    <div className={`sticky top-0 z-10 bg-white p-2 animate__animated animate__fadeInDown`}>
                        <div
                            className={`flex items-center flex-grow space-x-2 bg-white-50 p-[2px] mb-2 rounded-md border px-2 mx-2 animate__animated animate__fadeInDown relative`}>
                            <input type="text" className="bg-transparent outline-none w-full font-biryani h-[26px]"
                                   placeholder="Search" value=""/>
                        </div>
                    </div>
                    <div className={`infinite-scroll-component__outerdiv`}>
                        <div className={`infinite-scroll-component`}>
                            <ul className={`divide-y divide-gray-200 animate__animated animate__fadeInUp`}>
                                {data.map((email, index) => (
                                    <li
                                        key={index}
                                        className={`p-3 cursor-pointer hover:bg-gray-100 bg-gray-200`}
                                        onClick={() => handleEmailClick(email)} // Handle email click
                                    >
                                        <div className={`flex items-center justify-between`}>
                                            <div>
                                                <span
                                                    className="font-medium font-francois text-[12px]">{email.subject}</span>
                                                {" - "}
                                                <span className="text-gray-600 text-xs">{email.sender}</span>
                                            </div>
                                            <div className="text-gray-400 text-xs">
                                                {new Date(email.date).toLocaleDateString()} {new Date(email.date).toLocaleTimeString()}
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-600"
                                           dangerouslySetInnerHTML={{ __html: email.content.slice(0, 100) + (email.content.length > 100 ? '...' : '') }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Email Details (Right Panel) */}
            <div className={`lg:col-span-3 w-full h-full flex flex-col items-center gap-4 overflow-y-auto px-1`}>
                <div className={`w-full flex flex-col justify-start items-center`}>
                    <div className={`w-full h-auto border flex-col justify-start items-start flex rounded-md mt-3`}>
                        <table className="min-w-full divide-y divide-gray-200">
                            {/* Name */}
                            <tr className="h-auto">
                                <td className="px-1 py-2 bg-white border border-gray-300">
                                    <p className="text-sm text-gray-900 text-left font-semibold font-francois tracking-wider break-words whitespace-nowrap">
                                        Name
                                    </p>
                                </td>
                                <td className="px-1 py-1 whitespace-nowrap border rounded-br-md">
                                    <p className="text-xs text-gray-900 font-biryani font-thin break-all text-wrap">
                                        {determineEmailDetails().sender || ""}
                                    </p>
                                </td>
                            </tr>
                            {/* Email */}
                            <tr className="h-auto">
                                <td className="px-1 py-2 bg-white border border-gray-300">
                                    <p className="text-sm text-gray-900 text-left font-semibold font-francois tracking-wider break-words whitespace-nowrap">
                                        Email
                                    </p>
                                </td>
                                <td className="px-1 py-1 whitespace-nowrap border rounded-br-md">
                                    <p className="text-xs text-gray-900 font-biryani font-thin break-all text-wrap">
                                        {determineEmailDetails().senderEmail || ""}
                                    </p>
                                </td>
                            </tr>
                            {/* CC */}
                            <tr className="h-auto">
                                <td className="px-1 py-2 bg-white border rounded-tl-md border-gray-300">
                                    <p className="text-sm text-gray-900 text-left font-semibold font-francois tracking-wider break-words whitespace-nowrap">
                                        CC
                                    </p>
                                </td>
                                <td className="px-1 py-1 whitespace-nowrap border rounded-br-md">
                                    {determineEmailDetails().cc?.length > 0
                                        ? determineEmailDetails().cc.map((item: string) => (
                                            <p key={item} className="text-xs text-gray-900 break-all text-wrap">
                                                {item}
                                            </p>
                                        ))
                                        : ""}
                                </td>
                            </tr>
                            {/* Company */}
                            <tr className="h-auto">
                                <td className="px-1 py-2 bg-white border border-gray-300">
                                    <p className="text-sm text-gray-900 text-left font-semibold font-francois tracking-wider break-words whitespace-nowrap">
                                        Company
                                    </p>
                                </td>
                                <td className="px-1 py-1 whitespace-normal border rounded-br-md">
                                    <p className="text-xs text-gray-900 font-biryani font-thin break-all text-wrap">
                                        {determineEmailDetails().company || ""}
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div
                        className={`w-full flex flex-col justify-start items-center h-[60vh] bg-white mt-2`}>
                        <div
                            className={`w-full p-2 border rounded-md h-full overflow-y-auto`}>
                            <div className="text-xs font-thin tracking-wide break-words leading-5 overflow-auto">
                                {/* Display the subject if it exists */}
                                {determineEmailDetails().subject && (
                                    <>
                                        <span className="font-bold text-gray-700">Subject: </span>
                                        {determineEmailDetails().subject}
                                        <br/>
                                        <br/>
                                    </>
                                )}

                                {/* Display the content if it exists */}
                                {determineEmailDetails().content && (
                                    <>
                                        <p
                                            className="text-sm mt-2"
                                            dangerouslySetInnerHTML={{ __html: determineEmailDetails().content}}
                                        />

                                        <br/>
                                    </>

                                )}
                            </div>
                            {/* Delegate Button */}

                        </div>

                    </div>
                    <div className="flex justify-center mt-4 w-full">
                        <button
                            className="bg-[#205B89] text-white px-4 py-1 rounded m-2 font-semibold hover:bg-blue-600 transition-all w-full font-francois"
                            onClick={handleDelegate}
                        >
                            Delegate
                        </button>
                    </div>

                </div>
            </div>
        </div>

    );

}

export default Delegate;